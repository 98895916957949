import DataGrid from '../../../components/DataGrid';
import ErrorDisplay from '../../../components/ErrorDisplay';
import Loading from './Loading';
import { OverviewTableProps } from '../../../types/DataGrid';
import ReactTooltip from 'react-tooltip';
import SideButtons from './SideButtons';
import { debounce } from 'lodash';
import { useDashboard } from '../../../context/dashboardContext';
import { useEffect } from 'react';
import { useGetCustomHeaders } from '../../../features/table-settings';
import { useTable } from '../../../features/overview-table/hooks/use-table';

function OverviewTable({ hooks }: OverviewTableProps) {
  const {
    brandCode,
    hotelSettings,
    options,
    setSortDir,
    setSortKey,
    setTableSettings,
    sortDir,
    sortKey,
    targetDate,
    userId,
  } = useDashboard();
  const {
    dateSegment,
    endDate,
    monthOffset,
    snapshotDate,
    startDate,
    yearsCompare,
  } = options;

  const callback = debounce(() => ReactTooltip.rebuild(), 500, {
    leading: false,
    trailing: true,
  });

  const {
    bestrev,
    currentRate,
    dailyNotes,
    events,
    forecasts,
    groupsDaily,
    liveData,
    rmData,
    rateShops,
    rmRecs,
    rooms,
  } = hooks;

  const { headers: customHeaders } = useGetCustomHeaders({
    brandCode: String(brandCode),
    userId: String(userId),
  });

  useEffect(() => {
    callback();
  });

  useEffect(() => {
    if (brandCode && brandCode !== '') {
      // Any time the brand code changes we need to remove any rate and room type columns (since
      // they are hotel specific).
      setTableSettings({
        brandCode,
        type: 'RESET_HOTEL_COLS',
        key: 'reset',
        payload: { year: yearsCompare },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandCode]);

  useEffect(() => {
    const rateData = rateShops.data;
    if (rateData && rateData.compSet) {
      setTableSettings({
        brandCode,

        type: 'TOGGLE',
        key: 'rates',
        hotelCols: rateData.compSet,
        payload: { visible: hotelSettings?.rates.visible, year: yearsCompare },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rateShops.data, hotelSettings?.rates.visible]);

  useEffect(() => {
    const roomData = rooms.data;
    if (roomData && roomData.roomList) {
      setTableSettings({
        brandCode,

        type: 'TOGGLE',
        key: 'rooms',
        hotelCols: roomData.roomList,
        payload: { visible: hotelSettings?.rooms.visible, year: yearsCompare },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rooms.data, hotelSettings?.rooms.visible]);

  useEffect(() => {
    setTableSettings({
      brandCode,

      type: 'YEAR_CHANGE',
      key: 'var',
      payload: { year: yearsCompare },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yearsCompare]);

  useEffect(() => {
    if (hotelSettings?.tableCols) {
      ReactTooltip.rebuild();
    }
  }, [hotelSettings?.tableCols]);

  const tableData = {
    ...rmData.data,
    ...bestrev.data,
    ...currentRate.data,
    ...dailyNotes.data,
    ...events.data,
    ...groupsDaily.data,
    ...liveData.data,
    ...rmRecs.data,
    ...(hotelSettings?.forecasts.visible && { ...forecasts.data }),
    ...(hotelSettings?.rates.visible && { ...rateShops.data }),
    ...(hotelSettings?.rooms.visible && { ...rooms.data }),
  };

  const { rows, headers } = useTable({
    customHeaders,
    data: tableData,
    dateSegment,
    endDate,
    hotelSettings,
    startDate,
  });

  useEffect(() => {
    if (headers && headers.length > 0 && !hotelSettings?.colLockKey) {
      const defaultColLockKey =
        headers[4]?.dataKey || headers[0]?.dataKey || '';

      setTableSettings({
        brandCode,
        type: 'COL_LOCK',
        key: 'colLockKey',
        payload: { colLockKey: defaultColLockKey },
      });
    }
  }, [headers, hotelSettings, setTableSettings, brandCode]);

  const colLockKey =
    hotelSettings?.colLockKey ||
    headers[4]?.dataKey ||
    headers[0]?.dataKey ||
    '';

  return (
    <div className='w-full mt-2'>
      {!brandCode || rmData.networkStatus < 3 ? (
        <Loading hooks={hooks} />
      ) : (
        <div>
          <ErrorDisplay error={rmData.error} />
          <DataGrid
            brandCode={brandCode}
            colLockKey={colLockKey}
            colToggles={{}}
            dateSegment={dateSegment}
            headers={headers}
            monthOffset={monthOffset}
            rows={rows}
            setSortKey={setSortKey}
            setSortDir={setSortDir}
            snapshotDate={snapshotDate}
            sortDir={sortDir}
            sortKey={sortKey}
            targetDate={targetDate}
            startDate={startDate}
          />
          <SideButtons headers={headers} hooks={hooks} />
        </div>
      )}
    </div>
  );
}

export default OverviewTable;
