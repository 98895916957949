import clsx from 'clsx';
import numbro from 'numbro';

type OccupancyCellProps = {
  value?: number | string | null;
  style?: Array<string>;
};

type SoldCellProps = {
  value?: number | string | null;
  style?: string[];
};

type CurrencyCellProps = {
  value?: number | string | null;
  metric: string;
  style?: string[];
};

export function CurrencyCell({ value, metric, style }: CurrencyCellProps) {
  let cellValue;
  if (
    value === '' ||
    isNaN(value as number) ||
    value === null ||
    value === undefined ||
    value === 0
  ) {
    cellValue = '';
  } else {
    switch (metric) {
      case 'revpar':
      case 'adr':
        cellValue = numbro(value).formatCurrency({
          mantissa: 2,
          thousandSeparated: true,
          negative: 'parenthesis',
        });
        break;
      case 'revenue':
        cellValue = numbro(value).formatCurrency({
          mantissa: 0,
          thousandSeparated: true,
          negative: 'parenthesis',
        });
        break;
      default:
        cellValue = value;
    }
  }
  return (
    <td
      className={clsx(
        'text-center',
        value && Number(value) < 0 && 'text-red-500',
        style && [...style]
      )}
    >
      {cellValue}
    </td>
  );
}

export const OccupancyCell = ({ value, style }: OccupancyCellProps) => {
  let cellValue;
  if (
    value === '' ||
    isNaN(value as number) ||
    value === null ||
    value === undefined ||
    value === 0
  ) {
    cellValue = '';
  } else {
    cellValue = numbro(value).format({
      output: 'percent',
      mantissa: 1,
    });
  }
  return (
    <td
      className={clsx(
        'text-center',
        value && Number(value) < 0 && 'text-red-500',
        style && [...style]
      )}
      style={{ textAlign: 'center' }}
    >
      {cellValue}
    </td>
  );
};

export function SoldCell({ value, style }: SoldCellProps) {
  return typeof value === 'string' || value === 0 ? (
    <td></td>
  ) : (
    <td
      className={clsx(
        value && value < 0 && 'text-red-500',
        'text-center',
        style && [...style]
      )}
    >
      {numbro(value).format({
        thousandSeparated: true,
        negative: 'parenthesis',
      })}
    </td>
  );
}
