export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AccountNumber: any;
  BigInt: any;
  BrandCode: any;
  Byte: any;
  CountryCode: any;
  Cuid: any;
  Currency: any;
  DID: any;
  Date: any;
  DateTime: any;
  DateTimeISO: any;
  DeweyDecimal: any;
  Duration: any;
  EmailAddress: any;
  GUID: any;
  HSL: any;
  HSLA: any;
  HexColorCode: any;
  Hexadecimal: any;
  IBAN: any;
  IP: any;
  IPCPatent: any;
  IPv4: any;
  IPv6: any;
  ISBN: any;
  ISO8601Duration: any;
  JSON: any;
  JSONObject: any;
  JWT: any;
  LCCSubclass: any;
  Latitude: any;
  LocalDate: any;
  LocalDateTime: any;
  LocalEndTime: any;
  LocalTime: any;
  Locale: any;
  Long: any;
  Longitude: any;
  MAC: any;
  NegativeFloat: any;
  NegativeInt: any;
  NonEmptyString: any;
  NonNegativeFloat: any;
  NonNegativeInt: any;
  NonPositiveFloat: any;
  NonPositiveInt: any;
  NoteScalar: any;
  ObjectID: any;
  PhoneNumber: any;
  Port: any;
  PositiveFloat: any;
  PositiveInt: any;
  PostalCode: any;
  RGB: any;
  RGBA: any;
  RoutingNumber: any;
  SafeInt: any;
  SemVer: any;
  Time: any;
  TimeZone: any;
  Timestamp: any;
  URL: any;
  USCurrency: any;
  UUID: any;
  UnsignedFloat: any;
  UnsignedInt: any;
  UtcOffset: any;
  Void: any;
};

export type ActionItem = {
  __typename?: 'ActionItem';
  assignedToId?: Maybe<Scalars['String']>;
  brandCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<ActionItemStatus>;
  statusUpdated?: Maybe<Scalars['String']>;
};

export enum ActionItemStatus {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  NotStarted = 'NOT_STARTED'
}

export type AgendaItem = {
  __typename?: 'AgendaItem';
  brandCode?: Maybe<Scalars['String']>;
  completed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type AgendaItemNote = {
  __typename?: 'AgendaItemNote';
  itemId?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};

export type AgendaItemNoteInput = {
  itemId?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
};

export type BestrevData = {
  __typename?: 'BestrevData';
  brand_code?: Maybe<Scalars['BrandCode']>;
  capacity_transient?: Maybe<Scalars['Int']>;
  current_rate?: Maybe<Scalars['Float']>;
  /** Day of week of stay date. 0 (Sunday) to 6 (Saturday). */
  date_day?: Maybe<Scalars['String']>;
  /** Day of month for stay date */
  date_dom?: Maybe<Scalars['String']>;
  /** Month of stay date. */
  date_month?: Maybe<Scalars['String']>;
  /** Quarter of stay date */
  date_quarter?: Maybe<Scalars['String']>;
  /** Week of stay date */
  date_week?: Maybe<Scalars['String']>;
  /** Year and day of week, of stay date */
  date_ydw?: Maybe<Scalars['String']>;
  /** Year of stay date */
  date_year?: Maybe<Scalars['String']>;
  /** Year of stay date one year into the future */
  date_year_ny?: Maybe<Scalars['String']>;
  /** Year and month, of stay date */
  date_ym?: Maybe<Scalars['String']>;
  /** Year and month, of stay date one year into the future */
  date_ym_ny?: Maybe<Scalars['String']>;
  /** Year and quarter, of stay date */
  date_yq?: Maybe<Scalars['String']>;
  /** Year and quarter, of stay date one year into the future */
  date_yq_ny?: Maybe<Scalars['String']>;
  /** Year and week, of stay date */
  date_yw?: Maybe<Scalars['String']>;
  /** Year and week, of stay date one year into the future */
  date_yw_ny?: Maybe<Scalars['String']>;
  fcst_occ_pct?: Maybe<Scalars['Float']>;
  /** @deprecated Use 'fcst_occ_pct' instead */
  fcst_occupancy_pct?: Maybe<Scalars['Float']>;
  fcst_rev?: Maybe<Scalars['Float']>;
  /** @deprecated Use 'fcst_rev' instead */
  fcstrev?: Maybe<Scalars['Float']>;
  last_updated_date?: Maybe<Scalars['String']>;
  market_rate?: Maybe<Scalars['Float']>;
  prev_rec_rate?: Maybe<Scalars['Float']>;
  /** @deprecated Use 'brand_code' instead */
  property_id?: Maybe<Scalars['BrandCode']>;
  rate_to_upload?: Maybe<Scalars['Float']>;
  rate_variance?: Maybe<Scalars['Float']>;
  /** @deprecated Use 'recommended_rate' instead */
  rec_rate?: Maybe<Scalars['Float']>;
  rec_status?: Maybe<Scalars['String']>;
  recommended_rate?: Maybe<Scalars['Float']>;
  sellout_ind?: Maybe<Scalars['Int']>;
  snapshot_date?: Maybe<Scalars['String']>;
  stay_date?: Maybe<Scalars['String']>;
  /** Same date one year into the future */
  stay_date_ny?: Maybe<Scalars['Date']>;
  total_capacity?: Maybe<Scalars['Int']>;
  total_fcst?: Maybe<Scalars['Int']>;
  transient_cap?: Maybe<Scalars['Int']>;
  unc_dmd_bwrate?: Maybe<Scalars['Int']>;
};


export type BestrevDataDate_Year_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


export type BestrevDataDate_Ym_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


export type BestrevDataDate_Yq_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


export type BestrevDataDate_Yw_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


export type BestrevDataStay_Date_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};

export type Block = {
  __typename?: 'Block';
  depth?: Maybe<Scalars['Int']>;
  entityRanges?: Maybe<Array<Maybe<EntityRange>>>;
  inlineStyleRanges?: Maybe<Array<Maybe<InlineStyleRange>>>;
  key?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type BlockInput = {
  depth?: InputMaybe<Scalars['Int']>;
  entityRanges?: InputMaybe<Array<InputMaybe<EntityRangeInput>>>;
  inlineStyleRanges?: InputMaybe<Array<InputMaybe<InlineStyleRangeInput>>>;
  key?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export enum BrandDescriptor {
  Aden = 'ADEN',
  Best = 'BEST',
  Bwsc = 'BWSC',
  Exre = 'EXRE',
  Glo = 'GLO',
  Plus = 'PLUS',
  Pmcl = 'PMCL',
  Prmr = 'PRMR',
  Sadi = 'SADI',
  Sses = 'SSES',
  Ssh = 'SSH',
  Sspl = 'SSPL',
  Sssc = 'SSSC',
  Sues = 'SUES',
  Suh = 'SUH',
  Supl = 'SUPL',
  Susc = 'SUSC',
  Vib = 'VIB',
  Whcc = 'WHCC',
  Whdi = 'WHDI',
  Whel = 'WHEL',
  Whlx = 'WHLX'
}

export type CalcForecasts = {
  __typename?: 'CalcForecasts';
  bestrev?: Maybe<Forecast>;
};

export type ColumnDetails = {
  __typename?: 'ColumnDetails';
  columnSize?: Maybe<Scalars['Int']>;
  dataSource?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  renderer?: Maybe<Scalars['String']>;
  tooltip?: Maybe<Scalars['String']>;
};

export type ColumnSettings = {
  __typename?: 'ColumnSettings';
  columnSize?: Maybe<Scalars['Int']>;
  header?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
};

export type CompSet = {
  __typename?: 'CompSet';
  /** List of competitor IDs */
  competitor_ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The competitor set ID */
  id?: Maybe<Scalars['String']>;
  /** The competitor set name */
  name?: Maybe<Scalars['String']>;
};

export type CompSetInput = {
  /** List of competitor IDs */
  competitor_ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** The competitor set ID */
  id?: InputMaybe<Scalars['String']>;
  /** The competitor set name */
  name?: InputMaybe<Scalars['String']>;
};

export type Competitor = {
  __typename?: 'Competitor';
  /** The competitor ID */
  id?: Maybe<Scalars['String']>;
  /** The competitor name */
  name?: Maybe<Scalars['String']>;
  /** The number of stars */
  stars?: Maybe<Scalars['Int']>;
};

export type CompetitorInput = {
  /** The competitor ID */
  id?: InputMaybe<Scalars['String']>;
  /** The competitor name */
  name?: InputMaybe<Scalars['String']>;
  /** The number of stars */
  stars?: InputMaybe<Scalars['Int']>;
};

export enum Country {
  Albania = 'ALBANIA',
  Algeria = 'ALGERIA',
  AntiguaAndBarbuda = 'ANTIGUA_AND_BARBUDA',
  Argentina = 'ARGENTINA',
  Armenia = 'ARMENIA',
  Aruba = 'ARUBA',
  Australia = 'AUSTRALIA',
  Austria = 'AUSTRIA',
  Bahamas = 'BAHAMAS',
  Bahrain = 'BAHRAIN',
  Bangladesh = 'BANGLADESH',
  Belgium = 'BELGIUM',
  Belize = 'BELIZE',
  Benin = 'BENIN',
  Bolivia = 'BOLIVIA',
  Botswana = 'BOTSWANA',
  Brazil = 'BRAZIL',
  Bulgaria = 'BULGARIA',
  Cambodia = 'CAMBODIA',
  Cameroon = 'CAMEROON',
  Canada = 'CANADA',
  CaymanIslands = 'CAYMAN_ISLANDS',
  Chile = 'CHILE',
  China = 'CHINA',
  Colombia = 'COLOMBIA',
  CookIslands = 'COOK_ISLANDS',
  CostaRica = 'COSTA_RICA',
  Croatia = 'CROATIA',
  Curacao = 'CURACAO',
  Cyprus = 'CYPRUS',
  CzechRepublic = 'CZECH_REPUBLIC',
  Denmark = 'DENMARK',
  DominicanRepublic = 'DOMINICAN_REPUBLIC',
  Ecuador = 'ECUADOR',
  Egypt = 'EGYPT',
  ElSalvador = 'EL_SALVADOR',
  Estonia = 'ESTONIA',
  Ethiopia = 'ETHIOPIA',
  Fiji = 'FIJI',
  FijiIslands = 'FIJI_ISLANDS',
  Finland = 'FINLAND',
  France = 'FRANCE',
  FrenchGuiana = 'FRENCH_GUIANA',
  Georgia = 'GEORGIA',
  Germany = 'GERMANY',
  Ghana = 'GHANA',
  GreatBritain = 'GREAT_BRITAIN',
  GreatBritainUk = 'GREAT_BRITAIN_UK',
  Greece = 'GREECE',
  Greenland = 'GREENLAND',
  Grenada = 'GRENADA',
  Guadeloupe = 'GUADELOUPE',
  Guam = 'GUAM',
  Guatemala = 'GUATEMALA',
  Haiti = 'HAITI',
  Honduras = 'HONDURAS',
  Hungary = 'HUNGARY',
  Iceland = 'ICELAND',
  India = 'INDIA',
  Indonesia = 'INDONESIA',
  Iraq = 'IRAQ',
  Ireland = 'IRELAND',
  Israel = 'ISRAEL',
  Italy = 'ITALY',
  Japan = 'JAPAN',
  Jordan = 'JORDAN',
  Kazakhstan = 'KAZAKHSTAN',
  Kenya = 'KENYA',
  Kosovo = 'KOSOVO',
  Kuwait = 'KUWAIT',
  Laos = 'LAOS',
  Latvia = 'LATVIA',
  Lebanon = 'LEBANON',
  Lithuania = 'LITHUANIA',
  Luxembourg = 'LUXEMBOURG',
  Macedonia = 'MACEDONIA',
  Malaysia = 'MALAYSIA',
  Maldives = 'MALDIVES',
  Malta = 'MALTA',
  Martinique = 'MARTINIQUE',
  Mauritius = 'MAURITIUS',
  Mexico = 'MEXICO',
  Moldova = 'MOLDOVA',
  Mongolia = 'MONGOLIA',
  Morocco = 'MOROCCO',
  Myanmar = 'MYANMAR',
  Nepal = 'NEPAL',
  Netherlands = 'NETHERLANDS',
  NetherlandsAntilles = 'NETHERLANDS_ANTILLES',
  NewCaledonia = 'NEW_CALEDONIA',
  NewZealand = 'NEW_ZEALAND',
  Nicaragua = 'NICARAGUA',
  Nigeria = 'NIGERIA',
  NorthernMarianaIslands = 'NORTHERN_MARIANA_ISLANDS',
  Norway = 'NORWAY',
  Oman = 'OMAN',
  Pakistan = 'PAKISTAN',
  Panama = 'PANAMA',
  Paraguay = 'PARAGUAY',
  Peru = 'PERU',
  Philippines = 'PHILIPPINES',
  Poland = 'POLAND',
  Portugal = 'PORTUGAL',
  PuertoRico = 'PUERTO_RICO',
  Qatar = 'QATAR',
  RepublicOfKorea = 'REPUBLIC_OF_KOREA',
  RepublicOfMontenegro = 'REPUBLIC_OF_MONTENEGRO',
  ReunionFrench = 'REUNION_FRENCH',
  Romania = 'ROMANIA',
  Russia = 'RUSSIA',
  SaintLucia = 'SAINT_LUCIA',
  SanMarinoRepublic = 'SAN_MARINO_REPUBLIC',
  SaudiArabia = 'SAUDI_ARABIA',
  Serbia = 'SERBIA',
  SeychellesIsl = 'SEYCHELLES_ISL',
  Singapore = 'SINGAPORE',
  Slovakia = 'SLOVAKIA',
  Slovenia = 'SLOVENIA',
  SouthAfrica = 'SOUTH_AFRICA',
  Spain = 'SPAIN',
  SriLanka = 'SRI_LANKA',
  Suriname = 'SURINAME',
  Sweden = 'SWEDEN',
  Switzerland = 'SWITZERLAND',
  Taiwan = 'TAIWAN',
  Tanzania = 'TANZANIA',
  Thailand = 'THAILAND',
  Tonga = 'TONGA',
  TrinidadAndTobago = 'TRINIDAD_AND_TOBAGO',
  Tunisia = 'TUNISIA',
  Turkey = 'TURKEY',
  TurksAndCaicosIslands = 'TURKS_AND_CAICOS_ISLANDS',
  Uganda = 'UGANDA',
  Ukraine = 'UKRAINE',
  UnitedArabEmirates = 'UNITED_ARAB_EMIRATES',
  UnitedStates = 'UNITED_STATES',
  Uruguay = 'URUGUAY',
  Venezuela = 'VENEZUELA',
  Vietnam = 'VIETNAM',
  VietNam = 'VIET_NAM',
  VirginIslands = 'VIRGIN_ISLANDS',
  VirginIslandsUs = 'VIRGIN_ISLANDS_US',
  Yemen = 'YEMEN',
  Zambia = 'ZAMBIA',
  Zimbabwe = 'ZIMBABWE'
}

export type CpmResort = {
  __typename?: 'CpmResort';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  begin_date?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  descriptor?: Maybe<BrandDescriptor>;
  end_date?: Maybe<Scalars['String']>;
  international_domestic_ind?: Maybe<Scalars['String']>;
  latlong_x_position?: Maybe<Scalars['Float']>;
  latlong_y_position?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  resort_id?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
};

export type CreateEventInput = {
  /** The ID of the user who created this event. */
  created_by_id: Scalars['String'];
  /** Additional details about the event. */
  details?: InputMaybe<Scalars['String']>;
  /** The date the event ends. */
  end_date?: InputMaybe<Scalars['String']>;
  /** The ID of the hotel this event belongs to. */
  hotel_id: Scalars['Int'];
  /** The name of the event. */
  name: Scalars['String'];
  /** The date the event starts. */
  start_date: Scalars['String'];
};

export type CreateFeatureFlagInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  default_value?: InputMaybe<Scalars['Boolean']>;
  description: Scalars['String'];
  key: Scalars['String'];
};

export type CreateForecastInput = {
  /** The ID of the user who created this Forecast. */
  created_by_id: Scalars['String'];
  /** The forecasted ADR for the stay date. Option 1. */
  forecast_adr_1?: InputMaybe<Scalars['Float']>;
  /** The forecasted ADR for the stay date. Option 2. */
  forecast_adr_2?: InputMaybe<Scalars['Float']>;
  /** The forecasted sold for the stay date. Option 1. */
  forecast_sold_1?: InputMaybe<Scalars['Int']>;
  /** The forecasted sold for the stay date. Option 2. */
  forecast_sold_2?: InputMaybe<Scalars['Int']>;
  /** The ID of the hotel this Forecast belongs to. */
  hotel_id: Scalars['Int'];
  /** The arrival date for the Forecast */
  stay_date: Scalars['String'];
};

export type CreateHotelInput = {
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  brand_code: Scalars['String'];
  chain_code?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Country>;
  country_code?: InputMaybe<Scalars['String']>;
  descriptor?: InputMaybe<BrandDescriptor>;
  international_domestic_ind?: InputMaybe<Scalars['String']>;
  is_prm?: InputMaybe<Scalars['Boolean']>;
  latlong_x_position?: InputMaybe<Scalars['Longitude']>;
  latlong_y_position?: InputMaybe<Scalars['Latitude']>;
  name: Scalars['String'];
  rm_serv_code?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<State>;
  use_forecast?: InputMaybe<Scalars['Boolean']>;
  zip_code?: InputMaybe<Scalars['String']>;
};

export type CreateHotelPortfolioInput = {
  /** The ID of the hotel related to the portfolio. */
  hotel_id: Scalars['Int'];
  /** The ID of the portfolio related to the hotel. */
  portfolio_id: Scalars['String'];
};

export type CreateMonthlyBudgetInput = {
  /** The budgeted amount for the ADR */
  budget_adr?: InputMaybe<Scalars['Float']>;
  /** The budgeted amount for the revenue */
  budget_revenue?: InputMaybe<Scalars['Float']>;
  /** The budgeted amount for the sold */
  budget_sold?: InputMaybe<Scalars['Int']>;
  /** The ID of the user who created this Monthly Budget. */
  created_by_id: Scalars['String'];
  /** The ID of the hotel this Monthly Budget belongs to. */
  hotel_id: Scalars['Int'];
  /** The arrival date for the Monthly Budget */
  stay_date: Scalars['String'];
};

export type CreateNoteInput = {
  /** The content of the note. */
  content?: InputMaybe<Scalars['NoteScalar']>;
  /** The ID of the user who created this note. */
  created_by_id: Scalars['String'];
  /** The ID of the hotel this note belongs to. */
  hotel_id: Scalars['Int'];
  /** The stay date associated with this note. */
  stay_date?: InputMaybe<Scalars['String']>;
};

export type CreateOtaiSubscriptionInput = {
  /** Hotel's 5-digit brand ID number. Alias of resort_id */
  brand_code?: InputMaybe<Scalars['BrandCode']>;
  /** The name of the client */
  client_name?: InputMaybe<Scalars['String']>;
  /** List of competitor sets */
  comp_sets?: InputMaybe<Array<InputMaybe<CompSetInput>>>;
  /** List of competitors */
  competitors?: InputMaybe<Array<InputMaybe<CompetitorInput>>>;
  /** List of features */
  features?: InputMaybe<Array<InputMaybe<FeatureInput>>>;
  /** The OTAI hotel ID */
  otai_hotel_id?: InputMaybe<Scalars['String']>;
  /** The number of stars */
  stars?: InputMaybe<Scalars['Int']>;
  /** The OTAI subscription ID */
  sub_id?: InputMaybe<Scalars['String']>;
};

export type CreateRmRecInput = {
  /** The ID of the user who created this RM Rec. */
  created_by_id: Scalars['String'];
  /** The ID of the hotel this RM Rec belongs to. */
  hotel_id: Scalars['Int'];
  /** The notes that the RM suggests */
  notes?: InputMaybe<Scalars['String']>;
  /** The rate that the RM recommends */
  rate?: InputMaybe<Scalars['Float']>;
  /** The status of the RM recommendation */
  status?: InputMaybe<RmRecStatus>;
  /** The arrival date for the RM Rec */
  stay_date: Scalars['String'];
};

export type CreateRmReportInput = {
  /** The database ID of the user who created this RM Report. */
  created_by_id: Scalars['String'];
  /** The data file for this RM report. */
  data_file?: InputMaybe<Scalars['String']>;
  /** The database ID of the hotel this RM Report belongs to. */
  hotel_id: Scalars['Int'];
  /** The notes for this RM report. */
  notes?: InputMaybe<NoteContentInput>;
  /** The report date for this RM report. */
  report_date?: InputMaybe<Scalars['String']>;
  /** The snapshot date associated with this RM Report. */
  snapshot_date?: InputMaybe<Scalars['String']>;
};

export type CreateUpdatePortfolioInput = {
  /** The ID of the user this portfolio belongs to. */
  user_id: Scalars['String'];
};

export type CreateUserInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  okta_uid?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<UserRole>;
  time_zone?: InputMaybe<Scalars['String']>;
};

export type CurrentRateTableArgs = {
  brandCode: Scalars['String'];
  endDate?: InputMaybe<Scalars['String']>;
  snapshotDate: Scalars['String'];
  startDate?: InputMaybe<Scalars['String']>;
  useCache?: InputMaybe<Scalars['Boolean']>;
  yearsCompare?: InputMaybe<Scalars['Int']>;
};

export type DailyEventDetails = {
  __typename?: 'DailyEventDetails';
  /** The number of events that occur on this day. */
  count: Scalars['Int'];
  /** A list of the events that occur on this day. */
  events: Array<Maybe<Event>>;
};

export type DailyEvents = {
  __typename?: 'DailyEvents';
  /** Day of week of stay date. 0 (Sunday) to 6 (Saturday). */
  date_day?: Maybe<Scalars['String']>;
  /** Day of month for stay date */
  date_dom?: Maybe<Scalars['String']>;
  /** Month of stay date. */
  date_month?: Maybe<Scalars['String']>;
  /** Quarter of stay date */
  date_quarter?: Maybe<Scalars['String']>;
  /** Week of stay date */
  date_week?: Maybe<Scalars['String']>;
  /** Year and day of week, of stay date */
  date_ydw?: Maybe<Scalars['String']>;
  /** Year of stay date */
  date_year?: Maybe<Scalars['String']>;
  /** Year of stay date one year into the future */
  date_year_ny?: Maybe<Scalars['String']>;
  /** Year and month, of stay date */
  date_ym?: Maybe<Scalars['String']>;
  /** Year and month, of stay date one year into the future */
  date_ym_ny?: Maybe<Scalars['String']>;
  /** Year and quarter, of stay date */
  date_yq?: Maybe<Scalars['String']>;
  /** Year and quarter, of stay date one year into the future */
  date_yq_ny?: Maybe<Scalars['String']>;
  /** Year and week, of stay date */
  date_yw?: Maybe<Scalars['String']>;
  /** Year and week, of stay date one year into the future */
  date_yw_ny?: Maybe<Scalars['String']>;
  /** The events for the same date last year. */
  events_ly?: Maybe<DailyEventDetails>;
  /** The events for the stay date. */
  events_ty?: Maybe<DailyEventDetails>;
  /** The date the events are for. */
  stay_date: Scalars['String'];
  /** Same date one year into the future */
  stay_date_ny?: Maybe<Scalars['Date']>;
};


export type DailyEventsDate_Year_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


export type DailyEventsDate_Ym_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


export type DailyEventsDate_Yq_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


export type DailyEventsDate_Yw_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


export type DailyEventsStay_Date_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};

export type DailyGroupData = {
  __typename?: 'DailyGroupData';
  brand_code?: Maybe<Scalars['String']>;
  /** Day of week of stay date. 0 (Sunday) to 6 (Saturday). */
  date_day?: Maybe<Scalars['String']>;
  /** Day of month for stay date */
  date_dom?: Maybe<Scalars['String']>;
  /** Month of stay date. */
  date_month?: Maybe<Scalars['String']>;
  /** Quarter of stay date */
  date_quarter?: Maybe<Scalars['String']>;
  /** Week of stay date */
  date_week?: Maybe<Scalars['String']>;
  /** Year and day of week, of stay date */
  date_ydw?: Maybe<Scalars['String']>;
  /** Year of stay date */
  date_year?: Maybe<Scalars['String']>;
  /** Year of stay date one year into the future */
  date_year_ny?: Maybe<Scalars['String']>;
  /** Year and month, of stay date */
  date_ym?: Maybe<Scalars['String']>;
  /** Year and month, of stay date one year into the future */
  date_ym_ny?: Maybe<Scalars['String']>;
  /** Year and quarter, of stay date */
  date_yq?: Maybe<Scalars['String']>;
  /** Year and quarter, of stay date one year into the future */
  date_yq_ny?: Maybe<Scalars['String']>;
  /** Year and week, of stay date */
  date_yw?: Maybe<Scalars['String']>;
  /** Year and week, of stay date one year into the future */
  date_yw_ny?: Maybe<Scalars['String']>;
  details?: Maybe<Array<Maybe<Group>>>;
  stay_date?: Maybe<Scalars['String']>;
  /** Same date one year into the future */
  stay_date_ny?: Maybe<Scalars['Date']>;
};


export type DailyGroupDataDate_Year_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


export type DailyGroupDataDate_Ym_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


export type DailyGroupDataDate_Yq_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


export type DailyGroupDataDate_Yw_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


export type DailyGroupDataStay_Date_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};

export type DailyNotes = {
  __typename?: 'DailyNotes';
  /** Day of week of stay date. 0 (Sunday) to 6 (Saturday). */
  date_day?: Maybe<Scalars['String']>;
  /** Day of month for stay date */
  date_dom?: Maybe<Scalars['String']>;
  /** Month of stay date. */
  date_month?: Maybe<Scalars['String']>;
  /** Quarter of stay date */
  date_quarter?: Maybe<Scalars['String']>;
  /** Week of stay date */
  date_week?: Maybe<Scalars['String']>;
  /** Year and day of week, of stay date */
  date_ydw?: Maybe<Scalars['String']>;
  /** Year of stay date */
  date_year?: Maybe<Scalars['String']>;
  /** Year of stay date one year into the future */
  date_year_ny?: Maybe<Scalars['String']>;
  /** Year and month, of stay date */
  date_ym?: Maybe<Scalars['String']>;
  /** Year and month, of stay date one year into the future */
  date_ym_ny?: Maybe<Scalars['String']>;
  /** Year and quarter, of stay date */
  date_yq?: Maybe<Scalars['String']>;
  /** Year and quarter, of stay date one year into the future */
  date_yq_ny?: Maybe<Scalars['String']>;
  /** Year and week, of stay date */
  date_yw?: Maybe<Scalars['String']>;
  /** Year and week, of stay date one year into the future */
  date_yw_ny?: Maybe<Scalars['String']>;
  /** The note for the same date last year. */
  note_ly?: Maybe<Note>;
  /** The note for the stay date. */
  note_ty?: Maybe<Note>;
  /** The date the notes are for. */
  stay_date: Scalars['String'];
  /** Same date one year into the future */
  stay_date_ny?: Maybe<Scalars['Date']>;
};


export type DailyNotesDate_Year_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


export type DailyNotesDate_Ym_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


export type DailyNotesDate_Yq_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


export type DailyNotesDate_Yw_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


export type DailyNotesStay_Date_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};

export type DailyRates = {
  __typename?: 'DailyRates';
  /** Day of week of stay date. 0 (Sunday) to 6 (Saturday). */
  date_day?: Maybe<Scalars['String']>;
  /** Day of month for stay date */
  date_dom?: Maybe<Scalars['String']>;
  /** Month of stay date. */
  date_month?: Maybe<Scalars['String']>;
  /** Quarter of stay date */
  date_quarter?: Maybe<Scalars['String']>;
  /** Week of stay date */
  date_week?: Maybe<Scalars['String']>;
  /** Year and day of week, of stay date */
  date_ydw?: Maybe<Scalars['String']>;
  /** Year of stay date */
  date_year?: Maybe<Scalars['String']>;
  /** Year of stay date one year into the future */
  date_year_ny?: Maybe<Scalars['String']>;
  /** Year and month, of stay date */
  date_ym?: Maybe<Scalars['String']>;
  /** Year and month, of stay date one year into the future */
  date_ym_ny?: Maybe<Scalars['String']>;
  /** Year and quarter, of stay date */
  date_yq?: Maybe<Scalars['String']>;
  /** Year and quarter, of stay date one year into the future */
  date_yq_ny?: Maybe<Scalars['String']>;
  /** Year and week, of stay date */
  date_yw?: Maybe<Scalars['String']>;
  /** Year and week, of stay date one year into the future */
  date_yw_ny?: Maybe<Scalars['String']>;
  rates?: Maybe<Array<Maybe<Rate>>>;
  stay_date?: Maybe<Scalars['Date']>;
  /** Same date one year into the future */
  stay_date_ny?: Maybe<Scalars['Date']>;
};


export type DailyRatesDate_Year_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


export type DailyRatesDate_Ym_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


export type DailyRatesDate_Yq_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


export type DailyRatesDate_Yw_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


export type DailyRatesStay_Date_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};

export type DailyRoomRate = {
  __typename?: 'DailyRoomRate';
  brand_code?: Maybe<Scalars['BrandCode']>;
  current_rate?: Maybe<Scalars['Float']>;
  current_rate_ly?: Maybe<Scalars['Float']>;
  current_rate_ly_final?: Maybe<Scalars['Float']>;
  current_rate_ly_final_n?: Maybe<Scalars['Float']>;
  current_rate_ly_n?: Maybe<Scalars['Float']>;
  /** Day of week of stay date. 0 (Sunday) to 6 (Saturday). */
  date_day?: Maybe<Scalars['String']>;
  /** Day of month for stay date */
  date_dom?: Maybe<Scalars['String']>;
  /** Month of stay date. */
  date_month?: Maybe<Scalars['String']>;
  /** Quarter of stay date */
  date_quarter?: Maybe<Scalars['String']>;
  /** Week of stay date */
  date_week?: Maybe<Scalars['String']>;
  /** Year and day of week, of stay date */
  date_ydw?: Maybe<Scalars['String']>;
  /** Year of stay date */
  date_year?: Maybe<Scalars['String']>;
  /** Year of stay date one year into the future */
  date_year_ny?: Maybe<Scalars['String']>;
  /** Year and month, of stay date */
  date_ym?: Maybe<Scalars['String']>;
  /** Year and month, of stay date one year into the future */
  date_ym_ny?: Maybe<Scalars['String']>;
  /** Year and quarter, of stay date */
  date_yq?: Maybe<Scalars['String']>;
  /** Year and quarter, of stay date one year into the future */
  date_yq_ny?: Maybe<Scalars['String']>;
  /** Year and week, of stay date */
  date_yw?: Maybe<Scalars['String']>;
  /** Year and week, of stay date one year into the future */
  date_yw_ny?: Maybe<Scalars['String']>;
  snapshot_date?: Maybe<Scalars['DateTime']>;
  snapshot_date_ly?: Maybe<Scalars['DateTime']>;
  snapshot_date_ty?: Maybe<Scalars['DateTime']>;
  stay_date?: Maybe<Scalars['Date']>;
  stay_date_ly?: Maybe<Scalars['Date']>;
  /** Same date one year into the future */
  stay_date_ny?: Maybe<Scalars['Date']>;
  stay_date_ty?: Maybe<Scalars['Date']>;
};


export type DailyRoomRateDate_Year_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


export type DailyRoomRateDate_Ym_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


export type DailyRoomRateDate_Yq_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


export type DailyRoomRateDate_Yw_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


export type DailyRoomRateStay_Date_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};

export type DailyRoomTypes = {
  __typename?: 'DailyRoomTypes';
  /** Day of week of stay date. 0 (Sunday) to 6 (Saturday). */
  date_day?: Maybe<Scalars['String']>;
  /** Day of month for stay date */
  date_dom?: Maybe<Scalars['String']>;
  /** Month of stay date. */
  date_month?: Maybe<Scalars['String']>;
  /** Quarter of stay date */
  date_quarter?: Maybe<Scalars['String']>;
  /** Week of stay date */
  date_week?: Maybe<Scalars['String']>;
  /** Year and day of week, of stay date */
  date_ydw?: Maybe<Scalars['String']>;
  /** Year of stay date */
  date_year?: Maybe<Scalars['String']>;
  /** Year of stay date one year into the future */
  date_year_ny?: Maybe<Scalars['String']>;
  /** Year and month, of stay date */
  date_ym?: Maybe<Scalars['String']>;
  /** Year and month, of stay date one year into the future */
  date_ym_ny?: Maybe<Scalars['String']>;
  /** Year and quarter, of stay date */
  date_yq?: Maybe<Scalars['String']>;
  /** Year and quarter, of stay date one year into the future */
  date_yq_ny?: Maybe<Scalars['String']>;
  /** Year and week, of stay date */
  date_yw?: Maybe<Scalars['String']>;
  /** Year and week, of stay date one year into the future */
  date_yw_ny?: Maybe<Scalars['String']>;
  room_types?: Maybe<Array<Maybe<Room>>>;
  stay_date?: Maybe<Scalars['Date']>;
  /** Same date one year into the future */
  stay_date_ny?: Maybe<Scalars['Date']>;
};


export type DailyRoomTypesDate_Year_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


export type DailyRoomTypesDate_Ym_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


export type DailyRoomTypesDate_Yq_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


export type DailyRoomTypesDate_Yw_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


export type DailyRoomTypesStay_Date_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};

export type DetailTable = {
  __typename?: 'DetailTable';
  detail_table?: Maybe<HotelSettingList>;
};

export type EntityRange = {
  __typename?: 'EntityRange';
  key?: Maybe<Scalars['Int']>;
  length?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type EntityRangeInput = {
  key?: InputMaybe<Scalars['Int']>;
  length?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

/**
 * Events store special demand generating time periods at the hotel.
 * Examples are weddings, groups, regional holidays.
 */
export type Event = {
  __typename?: 'Event';
  /** When this record was created. */
  created_at?: Maybe<Scalars['String']>;
  /** Relationship to the user who created this event. */
  created_by?: Maybe<User>;
  /** The ID of the user who created this event. */
  created_by_id?: Maybe<Scalars['String']>;
  /** The stay dates this event spans. */
  days?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Additional details about the event. */
  details?: Maybe<Scalars['String']>;
  /** The date the event ends. */
  end_date?: Maybe<Scalars['Date']>;
  /** Relationship to the hotel this event belongs to. */
  hotel?: Maybe<Hotel>;
  /** The ID of the hotel this event belongs to. */
  hotel_id?: Maybe<Scalars['Int']>;
  /** The database ID of the event */
  id?: Maybe<Scalars['String']>;
  /** The name of the event. */
  name?: Maybe<Scalars['String']>;
  /** The date the event starts. */
  start_date?: Maybe<Scalars['Date']>;
  /** When this record was last updated. */
  updated_at?: Maybe<Scalars['String']>;
};

export type EventCount = {
  __typename?: 'EventCount';
  /** The number of events. */
  count?: Maybe<Scalars['Int']>;
};

export type Feature = {
  __typename?: 'Feature';
  /** The feature ID */
  id?: Maybe<Scalars['String']>;
  /** List of OTA Sources */
  otas?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** feature flag data */
export type FeatureFlag = {
  __typename?: 'FeatureFlag';
  active?: Maybe<Scalars['Boolean']>;
  computed_value?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['String']>;
  default_value?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
  users?: Maybe<Array<Maybe<FeatureFlagUser>>>;
};

export type FeatureFlagUser = {
  __typename?: 'FeatureFlagUser';
  active?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['String']>;
  flag_key?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  last_updated_by?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

export type FeatureInput = {
  /** The feature ID */
  id?: InputMaybe<Scalars['String']>;
  /** List of OTA Sources */
  otas?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type FindEventArgs = {
  createdById?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  hotelId: Scalars['Int'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['String']>;
};

export type FindHotelMonthlyBudgetArgs = {
  createdById?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  hotelId: Scalars['Int'];
  startDate?: InputMaybe<Scalars['String']>;
};

export type FindNoteArgs = {
  endDate?: InputMaybe<Scalars['String']>;
  hotelId?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['String']>;
};

export type FindNotesArgs = {
  /** The database ID of the user who created this note. */
  createdById?: InputMaybe<Scalars['String']>;
  /** The end date to filter Notes. */
  endDate?: InputMaybe<Scalars['String']>;
  /** The database ID of the hotel this note belongs to. */
  hotelId: Scalars['Int'];
  /** The start date to filter Notes. */
  startDate?: InputMaybe<Scalars['String']>;
};

export type FindRmRecArgs = {
  /** The database ID of the user who created this RM Rec. */
  createdById?: InputMaybe<Scalars['String']>;
  /** The end date to filter RM Recs. */
  endDate?: InputMaybe<Scalars['String']>;
  /** The visiblility of the RM Rec. Sets hidden to FALSE if not provided. */
  hidden?: InputMaybe<Scalars['Boolean']>;
  /** The database ID of the hotel this RM Rec belongs to. */
  hotelId: Scalars['Int'];
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  /** The start date to filter RM Recs. */
  startDate?: InputMaybe<Scalars['String']>;
  /** The status of the RM Rec. */
  status?: InputMaybe<RmRecStatus>;
};

export type FindRmReportsArgs = {
  /** The database ID of the user who created this RM Report. */
  createdById?: InputMaybe<Scalars['String']>;
  /** The database ID of the hotel this RM Report belongs to. */
  hotelId: Scalars['Int'];
  /** The report date for this RM report. */
  reportDate?: InputMaybe<Scalars['Date']>;
  /** The snapshot date associated with this RM Report. */
  snapshotDate?: InputMaybe<Scalars['Date']>;
};

/**
 * Forecast records store daily sold, adr, and revenue
 * that a Revenue Manager forecasts for a hotel.
 */
export type Forecast = {
  __typename?: 'Forecast';
  calcForecasts?: Maybe<CalcForecasts>;
  created_at?: Maybe<Scalars['String']>;
  created_by?: Maybe<User>;
  created_by_id?: Maybe<Scalars['String']>;
  /** Day of week of stay date. 0 (Sunday) to 6 (Saturday). */
  date_day?: Maybe<Scalars['String']>;
  /** Day of month for stay date */
  date_dom?: Maybe<Scalars['String']>;
  /** Month of stay date. */
  date_month?: Maybe<Scalars['String']>;
  /** Quarter of stay date */
  date_quarter?: Maybe<Scalars['String']>;
  /** Week of stay date */
  date_week?: Maybe<Scalars['String']>;
  /** Year and day of week, of stay date */
  date_ydw?: Maybe<Scalars['String']>;
  /** Year of stay date */
  date_year?: Maybe<Scalars['String']>;
  /** Year of stay date one year into the future */
  date_year_ny?: Maybe<Scalars['String']>;
  /** Year and month, of stay date */
  date_ym?: Maybe<Scalars['String']>;
  /** Year and month, of stay date one year into the future */
  date_ym_ny?: Maybe<Scalars['String']>;
  /** Year and quarter, of stay date */
  date_yq?: Maybe<Scalars['String']>;
  /** Year and quarter, of stay date one year into the future */
  date_yq_ny?: Maybe<Scalars['String']>;
  /** Year and week, of stay date */
  date_yw?: Maybe<Scalars['String']>;
  /** Year and week, of stay date one year into the future */
  date_yw_ny?: Maybe<Scalars['String']>;
  fcstType?: Maybe<Scalars['String']>;
  forecast_adr_1?: Maybe<Scalars['Float']>;
  forecast_adr_2?: Maybe<Scalars['Float']>;
  forecast_occ_1?: Maybe<Scalars['Float']>;
  forecast_occ_2?: Maybe<Scalars['Float']>;
  forecast_revenue_1?: Maybe<Scalars['Float']>;
  forecast_revenue_2?: Maybe<Scalars['Float']>;
  forecast_sold_1?: Maybe<Scalars['Int']>;
  forecast_sold_2?: Maybe<Scalars['Int']>;
  hotel?: Maybe<Hotel>;
  hotel_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  snapshot_date?: Maybe<Scalars['Date']>;
  stay_date?: Maybe<Scalars['Date']>;
  /** Same date one year into the future */
  stay_date_ny?: Maybe<Scalars['Date']>;
  updated_at?: Maybe<Scalars['String']>;
};


/**
 * Forecast records store daily sold, adr, and revenue
 * that a Revenue Manager forecasts for a hotel.
 */
export type ForecastDate_Year_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


/**
 * Forecast records store daily sold, adr, and revenue
 * that a Revenue Manager forecasts for a hotel.
 */
export type ForecastDate_Ym_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


/**
 * Forecast records store daily sold, adr, and revenue
 * that a Revenue Manager forecasts for a hotel.
 */
export type ForecastDate_Yq_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


/**
 * Forecast records store daily sold, adr, and revenue
 * that a Revenue Manager forecasts for a hotel.
 */
export type ForecastDate_Yw_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


/**
 * Forecast records store daily sold, adr, and revenue
 * that a Revenue Manager forecasts for a hotel.
 */
export type ForecastStay_Date_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};

export type ForecastLookup = {
  lookupColumn: ForecastLookupType;
  lookupValue: Scalars['String'];
};

export enum ForecastLookupType {
  CreatedById = 'CREATED_BY_ID',
  HotelId = 'HOTEL_ID',
  Id = 'ID'
}

export type GetHotelForecastsArgs = {
  endDate?: InputMaybe<Scalars['String']>;
  hotelId?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['String']>;
};

/** group data from Redshift */
export type Group = {
  __typename?: 'Group';
  brand_code?: Maybe<Scalars['String']>;
  /** Day of week of stay date. 0 (Sunday) to 6 (Saturday). */
  date_day?: Maybe<Scalars['String']>;
  /** Day of month for stay date */
  date_dom?: Maybe<Scalars['String']>;
  /** Month of stay date. */
  date_month?: Maybe<Scalars['String']>;
  /** Quarter of stay date */
  date_quarter?: Maybe<Scalars['String']>;
  /** Week of stay date */
  date_week?: Maybe<Scalars['String']>;
  /** Year and day of week, of stay date */
  date_ydw?: Maybe<Scalars['String']>;
  /** Year of stay date */
  date_year?: Maybe<Scalars['String']>;
  /** Year of stay date one year into the future */
  date_year_ny?: Maybe<Scalars['String']>;
  /** Year and month, of stay date */
  date_ym?: Maybe<Scalars['String']>;
  /** Year and month, of stay date one year into the future */
  date_ym_ny?: Maybe<Scalars['String']>;
  /** Year and quarter, of stay date */
  date_yq?: Maybe<Scalars['String']>;
  /** Year and quarter, of stay date one year into the future */
  date_yq_ny?: Maybe<Scalars['String']>;
  /** Year and week, of stay date */
  date_yw?: Maybe<Scalars['String']>;
  /** Year and week, of stay date one year into the future */
  date_yw_ny?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  group_rooms_committed?: Maybe<Scalars['Int']>;
  group_rooms_remaining?: Maybe<Scalars['Int']>;
  group_rooms_sold?: Maybe<Scalars['Int']>;
  is_past?: Maybe<Scalars['Boolean']>;
  lowest_group_rate?: Maybe<Scalars['Float']>;
  rate_code?: Maybe<Scalars['String']>;
  snapshot_date?: Maybe<Scalars['String']>;
  stay_date?: Maybe<Scalars['String']>;
  /** Same date one year into the future */
  stay_date_ny?: Maybe<Scalars['Date']>;
};


/** group data from Redshift */
export type GroupDate_Year_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


/** group data from Redshift */
export type GroupDate_Ym_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


/** group data from Redshift */
export type GroupDate_Yq_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


/** group data from Redshift */
export type GroupDate_Yw_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


/** group data from Redshift */
export type GroupStay_Date_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};

export type Hotel = {
  __typename?: 'Hotel';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  brand_code: Scalars['BrandCode'];
  chain_code?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Country>;
  country_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  descriptor?: Maybe<BrandDescriptor>;
  /** @deprecated Only used for legacy purposes. */
  hotel_id?: Maybe<Scalars['Int']>;
  international_domestic_ind?: Maybe<Scalars['String']>;
  is_prm?: Maybe<Scalars['Boolean']>;
  latlong_x_position?: Maybe<Scalars['Longitude']>;
  latlong_y_position?: Maybe<Scalars['Latitude']>;
  name?: Maybe<Scalars['String']>;
  rm_serv_code?: Maybe<Scalars['String']>;
  state?: Maybe<State>;
  updated_at?: Maybe<Scalars['DateTime']>;
  use_forecast?: Maybe<Scalars['Boolean']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** Hotel Portfolio records link hotels to portfolios. */
export type HotelPortfolio = {
  __typename?: 'HotelPortfolio';
  /** Record of the Hotel in this relationship. */
  hotel?: Maybe<Hotel>;
  /** The ID of the hotel in this relationship. */
  hotel_id: Scalars['Int'];
  /** Record of the Portfolio in this relationship. */
  portfolio?: Maybe<Portfolio>;
  /** The ID of the portfolio in this relationship. */
  portfolio_id: Scalars['String'];
};

export type HotelSettingList = {
  __typename?: 'HotelSettingList';
  hotels?: Maybe<Array<Maybe<HotelSettingObject>>>;
};

export type HotelSettingObject = {
  __typename?: 'HotelSettingObject';
  brandCode?: Maybe<Scalars['BrandCode']>;
  columns?: Maybe<Array<Maybe<ColumnSettings>>>;
};

export type InlineStyleRange = {
  __typename?: 'InlineStyleRange';
  length?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  style?: Maybe<Scalars['String']>;
};

export type InlineStyleRangeInput = {
  length?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  style?: InputMaybe<Scalars['String']>;
};

/** live (realtime) data compiled from various Redshift tables */
export type LiveData = {
  __typename?: 'LiveData';
  /** Hotel's 5-digit brand ID number. Alias of resort_id */
  brand_code?: Maybe<Scalars['BrandCode']>;
  /** Day of week of stay date. 0 (Sunday) to 6 (Saturday). */
  date_day?: Maybe<Scalars['String']>;
  /** Day of month for stay date */
  date_dom?: Maybe<Scalars['String']>;
  /** Month of stay date. */
  date_month?: Maybe<Scalars['String']>;
  /** Quarter of stay date */
  date_quarter?: Maybe<Scalars['String']>;
  /** Week of stay date */
  date_week?: Maybe<Scalars['String']>;
  /** Year and day of week, of stay date */
  date_ydw?: Maybe<Scalars['String']>;
  /** Year of stay date */
  date_year?: Maybe<Scalars['String']>;
  /** Year of stay date one year into the future */
  date_year_ny?: Maybe<Scalars['String']>;
  /** Year and month, of stay date */
  date_ym?: Maybe<Scalars['String']>;
  /** Year and month, of stay date one year into the future */
  date_ym_ny?: Maybe<Scalars['String']>;
  /** Year and quarter, of stay date */
  date_yq?: Maybe<Scalars['String']>;
  /** Year and quarter, of stay date one year into the future */
  date_yq_ny?: Maybe<Scalars['String']>;
  /** Year and week, of stay date */
  date_yw?: Maybe<Scalars['String']>;
  /** Year and week, of stay date one year into the future */
  date_yw_ny?: Maybe<Scalars['String']>;
  /** Delta group sold intraday */
  group_delta_intraday?: Maybe<Scalars['Int']>;
  /**
   * Hotel's 5-digit brand ID number
   * @deprecated Use 'brand_code' instead
   */
  resort_id?: Maybe<Scalars['BrandCode']>;
  /** Live group rooms committed */
  rt_grp_rooms_committed?: Maybe<Scalars['Int']>;
  /** Live rooms committed */
  rt_rooms_committed?: Maybe<Scalars['Int']>;
  /** Live transient rooms committed */
  rt_transient_rooms_committed?: Maybe<Scalars['Int']>;
  /** Snapshot date the data was taken */
  snapshot_date?: Maybe<Scalars['Date']>;
  /** Arrival date for data row */
  stay_date?: Maybe<Scalars['Date']>;
  /** Same date one year into the future */
  stay_date_ny?: Maybe<Scalars['Date']>;
  /** Delta transient rooms sold intraday */
  transient_delta_intraday?: Maybe<Scalars['Int']>;
};


/** live (realtime) data compiled from various Redshift tables */
export type LiveDataDate_Year_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


/** live (realtime) data compiled from various Redshift tables */
export type LiveDataDate_Ym_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


/** live (realtime) data compiled from various Redshift tables */
export type LiveDataDate_Yq_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


/** live (realtime) data compiled from various Redshift tables */
export type LiveDataDate_Yw_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


/** live (realtime) data compiled from various Redshift tables */
export type LiveDataStay_Date_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};

export enum MealType {
  AllInclusive = 'ALL_INCLUSIVE',
  Breakfast = 'BREAKFAST',
  FullBoard = 'FULL_BOARD',
  HalfBoard = 'HALF_BOARD',
  NoMeal = 'NO_MEAL',
  RoomOnly = 'ROOM_ONLY'
}

export type Meeting = {
  __typename?: 'Meeting';
  agendaItemNotes?: Maybe<Array<Maybe<AgendaItemNote>>>;
  agendaItems?: Maybe<Array<Maybe<Scalars['String']>>>;
  attendees?: Maybe<Array<Maybe<Scalars['String']>>>;
  brandCode?: Maybe<Scalars['String']>;
  bucket?: Maybe<Scalars['String']>;
  bucketKey?: Maybe<Scalars['String']>;
  category?: Maybe<MeetingCategory>;
  createdAt?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  meetingDate?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export enum MeetingCategory {
  Adhoc = 'ADHOC',
  Daily = 'DAILY',
  Weekly = 'WEEKLY'
}

export enum MeetingEmail {
  Adhoc = 'ADHOC',
  All = 'ALL',
  Daily = 'DAILY',
  None = 'NONE',
  Weekly = 'WEEKLY'
}

export type MeetingService = {
  __typename?: 'MeetingService';
  actionItems?: Maybe<Array<Maybe<ActionItem>>>;
  agendaItems?: Maybe<Array<Maybe<AgendaItem>>>;
  hotelTeams?: Maybe<Array<Maybe<TeamMember>>>;
};

export type MonthlyBudget = {
  __typename?: 'MonthlyBudget';
  /** The budgeted amount for the ADR */
  budget_adr?: Maybe<Scalars['Float']>;
  /** The budgeted amount for the revenue */
  budget_revenue?: Maybe<Scalars['Float']>;
  /** The budgeted amount for the sold */
  budget_sold?: Maybe<Scalars['Int']>;
  /** When this record was created. */
  created_at?: Maybe<Scalars['String']>;
  /** Relationship to the user who created this Monthly Budget. */
  created_by?: Maybe<User>;
  /** The ID of the user who created this Monthly Budget. */
  created_by_id?: Maybe<Scalars['String']>;
  /** Day of week of stay date. 0 (Sunday) to 6 (Saturday). */
  date_day?: Maybe<Scalars['String']>;
  /** Day of month for stay date */
  date_dom?: Maybe<Scalars['String']>;
  /** Month of stay date. */
  date_month?: Maybe<Scalars['String']>;
  /** Quarter of stay date */
  date_quarter?: Maybe<Scalars['String']>;
  /** Week of stay date */
  date_week?: Maybe<Scalars['String']>;
  /** Year and day of week, of stay date */
  date_ydw?: Maybe<Scalars['String']>;
  /** Year of stay date */
  date_year?: Maybe<Scalars['String']>;
  /** Year of stay date one year into the future */
  date_year_ny?: Maybe<Scalars['String']>;
  /** Year and month, of stay date */
  date_ym?: Maybe<Scalars['String']>;
  /** Year and month, of stay date one year into the future */
  date_ym_ny?: Maybe<Scalars['String']>;
  /** Year and quarter, of stay date */
  date_yq?: Maybe<Scalars['String']>;
  /** Year and quarter, of stay date one year into the future */
  date_yq_ny?: Maybe<Scalars['String']>;
  /** Year and week, of stay date */
  date_yw?: Maybe<Scalars['String']>;
  /** Year and week, of stay date one year into the future */
  date_yw_ny?: Maybe<Scalars['String']>;
  /** Relationship to the hotel this Monthly Budget belongs to. */
  hotel?: Maybe<Hotel>;
  /** The ID of the Hotel this Monthly Budget belongs to. */
  hotel_id: Scalars['Int'];
  /** The ID of the Monthly Budget */
  id: Scalars['String'];
  /** The month this Monthly Budget applies to. This is calculated from the stay_date, and not updated by the user. */
  month: Scalars['Int'];
  /** The first day of the month that the Monthly Budget applies to. */
  stay_date?: Maybe<Scalars['Date']>;
  /** Same date one year into the future */
  stay_date_ny?: Maybe<Scalars['Date']>;
  /** When this record was last updated. */
  updated_at?: Maybe<Scalars['String']>;
  /** The year this Monthly Budget applies to. This is calculated from the stay_date, and not updated by the user. */
  year: Scalars['Int'];
};


export type MonthlyBudgetDate_Year_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


export type MonthlyBudgetDate_Ym_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


export type MonthlyBudgetDate_Yq_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


export type MonthlyBudgetDate_Yw_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


export type MonthlyBudgetStay_Date_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addActionItem?: Maybe<ActionItem>;
  addAgenda?: Maybe<Meeting>;
  addAgendaItem?: Maybe<AgendaItem>;
  addAttendee?: Maybe<Meeting>;
  /** Add a hotel to a portfolio */
  addHotelToPortfolio?: Maybe<Portfolio>;
  addMeeting?: Maybe<Meeting>;
  addTeamMember?: Maybe<TeamMember>;
  /** Clear column for all RM Recs */
  clearColumnRmRecs?: Maybe<Array<Maybe<RmRec>>>;
  clearHotelCache?: Maybe<Scalars['Boolean']>;
  copyForecasts?: Maybe<Array<Maybe<Forecast>>>;
  /** Create a single event */
  createEvent?: Maybe<Event>;
  createFeatureFlag?: Maybe<FeatureFlag>;
  /** Create a single Forecast */
  createForecast?: Maybe<Forecast>;
  createHotel?: Maybe<Hotel>;
  createHotelAgenda?: Maybe<Array<Maybe<AgendaItem>>>;
  /** Create a single hotel portfolio */
  createHotelPortfolio?: Maybe<HotelPortfolio>;
  /** Create a single Monthly Budget */
  createMonthlyBudget?: Maybe<MonthlyBudget>;
  /** Create a single note */
  createNote?: Maybe<Note>;
  /** Create a single OTAI Subscription */
  createOtaiSubscription?: Maybe<OtaiSubscription>;
  /** Create a single portfolio */
  createPortfolio?: Maybe<Portfolio>;
  /** Create a single RM Rec */
  createRmRec?: Maybe<RmRec>;
  /** Create multiple RM Recs */
  createRmRecs?: Maybe<Array<Maybe<RmRec>>>;
  /** Create a single RM Report */
  createRmReport?: Maybe<Report>;
  /** Create a new user */
  createUser?: Maybe<User>;
  /** Delete an event from the database */
  deleteEvent?: Maybe<Scalars['Int']>;
  /** Delete multiple events from the database */
  deleteEvents?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Delete a Forecast from the database */
  deleteForecast?: Maybe<Scalars['Int']>;
  deleteHotel?: Maybe<Scalars['Int']>;
  /** Delete a hotel portfolio from the database */
  deleteHotelPortfolio?: Maybe<Scalars['Int']>;
  /** Delete a Monthly Budget from the database */
  deleteMonthlyBudget?: Maybe<Scalars['Int']>;
  /** Delete a note from the database */
  deleteNote?: Maybe<Scalars['Int']>;
  /** Delete an OTAI Subscription from the database */
  deleteOtaiSubscription?: Maybe<Scalars['Int']>;
  /** Delete a portfolio from the database */
  deletePortfolio?: Maybe<Scalars['Int']>;
  /** Delete an RM Rec from the database */
  deleteRmRec?: Maybe<Scalars['Int']>;
  /** Delete a RM Report from the database */
  deleteRmReport?: Maybe<Scalars['Int']>;
  emailMeeting?: Maybe<Scalars['String']>;
  logTracking?: Maybe<Scalars['Boolean']>;
  /** Request a rate upload */
  rateUpload?: Maybe<Array<Maybe<RmRec>>>;
  reactivateTeamMember?: Maybe<TeamMember>;
  removeActionItem?: Maybe<Scalars['Boolean']>;
  removeAgenda?: Maybe<Meeting>;
  removeAgendaItem?: Maybe<Scalars['Boolean']>;
  removeAttendee?: Maybe<Meeting>;
  /** Remove a hotel from a portfolio */
  removeHotelFromPortfolio?: Maybe<Portfolio>;
  removeMeeting?: Maybe<Scalars['Boolean']>;
  removeTeamMember?: Maybe<TeamMember>;
  resetForecasts?: Maybe<Array<Maybe<Forecast>>>;
  /** Reset RM recs */
  resetRmRecs?: Maybe<Array<Maybe<RmRec>>>;
  /** Create and/or Update multiple RM Recs */
  rmRecBulkUpsert?: Maybe<Array<Maybe<RmRec>>>;
  toggleFlagUser?: Maybe<FeatureFlagUser>;
  updateActionItem?: Maybe<ActionItem>;
  updateAgendaItem?: Maybe<AgendaItem>;
  updateAgendaItemNotes?: Maybe<Meeting>;
  /** Update an existing event */
  updateEvent?: Maybe<Event>;
  updateFeatureFlag?: Maybe<FeatureFlag>;
  /** Update an existing Forecast */
  updateForecast?: Maybe<Forecast>;
  updateHotel?: Maybe<Hotel>;
  updateMeeting?: Maybe<Meeting>;
  /** Update an existing Monthly Budget */
  updateMonthlyBudget?: Maybe<MonthlyBudget>;
  /** Update an existing note */
  updateNote?: Maybe<Note>;
  updateNotes?: Maybe<AgendaItem>;
  /** Update an OTAI Subscription */
  updateOtaiSubscription?: Maybe<OtaiSubscription>;
  /** Update an existing portfolio */
  updatePortfolio?: Maybe<Portfolio>;
  /** Update an existing RM Rec */
  updateRmRec?: Maybe<RmRec>;
  /** Update an existing RM Report */
  updateRmReport?: Maybe<Report>;
  updateTeamMember?: Maybe<TeamMember>;
  /** Update an existing user */
  updateUser?: Maybe<User>;
  updateUserOption?: Maybe<UserOption>;
};


export type MutationAddActionItemArgs = {
  assignedToId?: InputMaybe<Scalars['String']>;
  brandCode: Scalars['String'];
  createdById: Scalars['String'];
  dueDate?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ActionItemStatus>;
};


export type MutationAddAgendaArgs = {
  agendaId: Scalars['String'];
  meetingId: Scalars['String'];
};


export type MutationAddAgendaItemArgs = {
  brandCode: Scalars['String'];
  completed?: InputMaybe<Scalars['Boolean']>;
  createdById: Scalars['String'];
  name: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
};


export type MutationAddAttendeeArgs = {
  attendeeId: Scalars['String'];
  meetingId: Scalars['String'];
};


export type MutationAddHotelToPortfolioArgs = {
  brandCode: Scalars['String'];
  portfolioId: Scalars['String'];
};


export type MutationAddMeetingArgs = {
  agendaItemNotes?: InputMaybe<Array<InputMaybe<AgendaItemNoteInput>>>;
  agendaItems?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  attendees?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  brandCode: Scalars['String'];
  category?: InputMaybe<MeetingCategory>;
  createdById: Scalars['String'];
  meetingDate?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
};


export type MutationAddTeamMemberArgs = {
  active?: InputMaybe<TeamMemberActive>;
  brandCode: Scalars['String'];
  createdById: Scalars['String'];
  email: Scalars['String'];
  emailPref?: InputMaybe<MeetingEmail>;
  name: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};


export type MutationClearColumnRmRecsArgs = {
  column: Scalars['String'];
  filters: FindRmRecArgs;
};


export type MutationClearHotelCacheArgs = {
  brandCode: Scalars['String'];
};


export type MutationCopyForecastsArgs = {
  hotelId: Scalars['Int'];
  target: Scalars['String'];
};


export type MutationCreateEventArgs = {
  event: CreateEventInput;
};


export type MutationCreateFeatureFlagArgs = {
  input: CreateFeatureFlagInput;
};


export type MutationCreateForecastArgs = {
  forecast: CreateForecastInput;
};


export type MutationCreateHotelArgs = {
  hotel: CreateHotelInput;
};


export type MutationCreateHotelAgendaArgs = {
  brandCode: Scalars['String'];
  createdById: Scalars['String'];
};


export type MutationCreateHotelPortfolioArgs = {
  hotelPortfolio: CreateHotelPortfolioInput;
};


export type MutationCreateMonthlyBudgetArgs = {
  monthlyBudget: CreateMonthlyBudgetInput;
};


export type MutationCreateNoteArgs = {
  note: CreateNoteInput;
};


export type MutationCreateOtaiSubscriptionArgs = {
  otaiSubscription: CreateOtaiSubscriptionInput;
};


export type MutationCreatePortfolioArgs = {
  portfolio: CreateUpdatePortfolioInput;
};


export type MutationCreateRmRecArgs = {
  rmRec: CreateRmRecInput;
};


export type MutationCreateRmRecsArgs = {
  rmRecs: Array<InputMaybe<CreateRmRecInput>>;
};


export type MutationCreateRmReportArgs = {
  rmReport: CreateRmReportInput;
};


export type MutationCreateUserArgs = {
  user: CreateUserInput;
};


export type MutationDeleteEventArgs = {
  id: Scalars['String'];
};


export type MutationDeleteEventsArgs = {
  id: Array<Scalars['String']>;
};


export type MutationDeleteForecastArgs = {
  id: Scalars['String'];
};


export type MutationDeleteHotelArgs = {
  brandCode: Scalars['String'];
};


export type MutationDeleteHotelPortfolioArgs = {
  hotelId: Scalars['Int'];
  portfolioId: Scalars['String'];
};


export type MutationDeleteMonthlyBudgetArgs = {
  id: Scalars['String'];
};


export type MutationDeleteNoteArgs = {
  id: Scalars['String'];
};


export type MutationDeleteOtaiSubscriptionArgs = {
  id: Scalars['String'];
};


export type MutationDeletePortfolioArgs = {
  id: Scalars['String'];
};


export type MutationDeleteRmRecArgs = {
  id: Scalars['String'];
};


export type MutationDeleteRmReportArgs = {
  id: Scalars['String'];
};


export type MutationEmailMeetingArgs = {
  emailSubject: Scalars['String'];
  from: Scalars['String'];
  meetingId: Scalars['String'];
  messageBody: Scalars['String'];
  recipients: Array<InputMaybe<Scalars['String']>>;
  revenueManager: Scalars['String'];
};


export type MutationLogTrackingArgs = {
  tracking: TrackingInput;
};


export type MutationRateUploadArgs = {
  brandCode: Scalars['BrandCode'];
  inputs: Array<InputMaybe<UploadRatesInput>>;
  userEmail: Scalars['String'];
  userName: Scalars['String'];
};


export type MutationReactivateTeamMemberArgs = {
  id: Scalars['String'];
};


export type MutationRemoveActionItemArgs = {
  id: Scalars['String'];
};


export type MutationRemoveAgendaArgs = {
  agendaId: Scalars['String'];
  meetingId: Scalars['String'];
};


export type MutationRemoveAgendaItemArgs = {
  id: Scalars['String'];
};


export type MutationRemoveAttendeeArgs = {
  attendeeId: Scalars['String'];
  meetingId: Scalars['String'];
};


export type MutationRemoveHotelFromPortfolioArgs = {
  brandCode: Scalars['String'];
  portfolioId: Scalars['String'];
};


export type MutationRemoveMeetingArgs = {
  id: Scalars['String'];
};


export type MutationRemoveTeamMemberArgs = {
  id: Scalars['String'];
};


export type MutationResetForecastsArgs = {
  hotelId: Scalars['Int'];
  metric: Scalars['String'];
  target: Scalars['String'];
};


export type MutationResetRmRecsArgs = {
  filters: FindRmRecArgs;
};


export type MutationRmRecBulkUpsertArgs = {
  rmRecs: Array<InputMaybe<UpsertRmRecInput>>;
};


export type MutationToggleFlagUserArgs = {
  active: Scalars['Boolean'];
  flagKey: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationUpdateActionItemArgs = {
  assignedToId?: InputMaybe<Scalars['String']>;
  dueDate?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ActionItemStatus>;
};


export type MutationUpdateAgendaItemArgs = {
  completed?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateAgendaItemNotesArgs = {
  itemId?: InputMaybe<Scalars['String']>;
  meetingId?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateEventArgs = {
  event: UpdateEventInput;
  id: Scalars['String'];
};


export type MutationUpdateFeatureFlagArgs = {
  input: UpdateFeatureFlagInput;
};


export type MutationUpdateForecastArgs = {
  forecast: UpdateForecastInput;
  id: Scalars['String'];
};


export type MutationUpdateHotelArgs = {
  hotel: UpdateHotelInput;
};


export type MutationUpdateMeetingArgs = {
  agendaItemNotes?: InputMaybe<Array<InputMaybe<AgendaItemNoteInput>>>;
  agendaItems?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  attendees?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  category?: InputMaybe<MeetingCategory>;
  id: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateMonthlyBudgetArgs = {
  id: Scalars['String'];
  monthlyBudget: UpdateMonthlyBudgetInput;
};


export type MutationUpdateNoteArgs = {
  id: Scalars['String'];
  note: UpdateNoteInput;
};


export type MutationUpdateNotesArgs = {
  id: Scalars['String'];
  notes: Scalars['String'];
};


export type MutationUpdateOtaiSubscriptionArgs = {
  id: Scalars['String'];
  otaiSubscription: UpdateOtaiSubscriptionInput;
};


export type MutationUpdatePortfolioArgs = {
  id: Scalars['String'];
  portfolio: CreateUpdatePortfolioInput;
};


export type MutationUpdateRmRecArgs = {
  id: Scalars['String'];
  rmRec: UpdateRmRecInput;
};


export type MutationUpdateRmReportArgs = {
  id: Scalars['String'];
  rmReport: UpdateRmReportInput;
};


export type MutationUpdateTeamMemberArgs = {
  active?: InputMaybe<TeamMemberActive>;
  email?: InputMaybe<Scalars['String']>;
  emailPref?: InputMaybe<MeetingEmail>;
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateUserArgs = {
  lookup: UserLookup;
  user: UpdateUserInput;
};


export type MutationUpdateUserOptionArgs = {
  appNotes?: InputMaybe<Scalars['String']>;
  brandCode: Scalars['String'];
  headers?: InputMaybe<Scalars['String']>;
  overviewSettings?: InputMaybe<Scalars['String']>;
  shopParams?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
};

/** Notes store extra information about the hotel. */
export type Note = {
  __typename?: 'Note';
  /** The content of the note. */
  content?: Maybe<Scalars['NoteScalar']>;
  /** When this record was created. */
  created_at?: Maybe<Scalars['String']>;
  /** Relationship to the user who created this note. */
  created_by?: Maybe<User>;
  /** The ID of the user who created this note. */
  created_by_id?: Maybe<Scalars['String']>;
  /** Relationship to the hotel this note belongs to. */
  hotel?: Maybe<Hotel>;
  /** The ID of the hotel this note belongs to. */
  hotel_id?: Maybe<Scalars['Int']>;
  /** The database ID of the note */
  id?: Maybe<Scalars['String']>;
  /** The previous content of the note. */
  previous_content?: Maybe<Scalars['NoteScalar']>;
  /** The stay date associated with this note. */
  stay_date?: Maybe<Scalars['Date']>;
  /** When this record was last updated. */
  updated_at?: Maybe<Scalars['String']>;
};

/** Note Content store the content of a note. */
export type NoteContent = {
  __typename?: 'NoteContent';
  /** The content of the note. */
  blocks?: Maybe<Array<Maybe<Block>>>;
};

export type NoteContentInput = {
  /** The content of the note. */
  blocks?: InputMaybe<Array<BlockInput>>;
};

export type OtaiRate = {
  __typename?: 'OtaiRate';
  /** The arrival date of the rate */
  arrivalDate?: Maybe<Scalars['Date']>;
  /** Is this rate Best Flexible? */
  best_flex?: Maybe<Scalars['Boolean']>;
  /** Is this rate cancellable? */
  cancellable?: Maybe<Scalars['Boolean']>;
  /** An object containing rate change details */
  changes?: Maybe<Array<Maybe<RateChangesList>>>;
  /** Is city tax included in the rate? */
  cityTaxIncl?: Maybe<Scalars['Boolean']>;
  /** The rate of the client hotel */
  clientRate?: Maybe<Scalars['Float']>;
  /**
   * The internal ID of the competitor hotel, as provided by the rate shop vendor.
   * This is a copy of the hotelId field.
   */
  competitor_id?: Maybe<Scalars['String']>;
  /**
   * The name of the competitor hotel.
   * This is a copy of the hotelName field.
   */
  competitor_name?: Maybe<Scalars['String']>;
  /** The currency of the rate */
  currency?: Maybe<Scalars['String']>;
  /** The date and time the rate was extracted. Format: YYYY-MM-DDTHH:MM:SS */
  extractDateTime?: Maybe<Scalars['String']>;
  /** The internal ID of the hotel, as provided by the rate shop vendor */
  hotelId?: Maybe<Scalars['Int']>;
  /** The name of the hotel */
  hotelName?: Maybe<Scalars['String']>;
  /** The length of stay for the rate */
  los?: Maybe<Scalars['Int']>;
  /** The maximum number of persons for the rate */
  maxPersons?: Maybe<Scalars['Int']>;
  /** The meal type included for the rate */
  mealTypeIncluded?: Maybe<MealType>;
  /** An error message if the rate is not available */
  message?: Maybe<Scalars['String']>;
  /** Are other taxes included in the rate? */
  otherTaxesIncl?: Maybe<Scalars['Boolean']>;
  /** The shopped rate. This is a copy of the value field */
  rate?: Maybe<Scalars['Float']>;
  /** The name of the room */
  roomName?: Maybe<Scalars['String']>;
  /** The original currency that the rate was shopped in */
  shopCurrency?: Maybe<Scalars['String']>;
  /** The shopped rate */
  value?: Maybe<Scalars['Float']>;
  /** Is VAT included in the rate? */
  vatIncl?: Maybe<Scalars['Boolean']>;
};

export type OtaiSubLookup = {
  lookupColumn: OtaiSubLookupType;
  lookupValue: Scalars['String'];
};

export enum OtaiSubLookupType {
  BrandCode = 'BRAND_CODE',
  Id = 'ID',
  SubId = 'SUB_ID'
}

export type OtaiSubscription = {
  __typename?: 'OtaiSubscription';
  /** Hotel's 5-digit brand ID number. Alias of resort_id */
  brand_code: Scalars['BrandCode'];
  /** The name of the client */
  client_name?: Maybe<Scalars['String']>;
  /** List of competitor sets */
  comp_sets?: Maybe<Array<Maybe<CompSet>>>;
  /** List of competitors */
  competitors?: Maybe<Array<Maybe<Competitor>>>;
  /** When this record was created. */
  created_at?: Maybe<Scalars['String']>;
  /** List of features */
  features?: Maybe<Array<Maybe<Feature>>>;
  /** Relationship to the hotel this OTAI Subscription belongs to. */
  hotel?: Maybe<Hotel>;
  /** The ID of the OTAI Subscription record. */
  id: Scalars['String'];
  /** The OTAI hotel ID */
  otai_hotel_id?: Maybe<Scalars['Int']>;
  /** The number of stars */
  stars?: Maybe<Scalars['Int']>;
  /** The OTAI subscription ID */
  sub_id?: Maybe<Scalars['Int']>;
  /** When this record was last updated. */
  updated_at?: Maybe<Scalars['String']>;
};

/** Portfolios Link Hotels to Users. */
export type Portfolio = {
  __typename?: 'Portfolio';
  /** When this record was created. */
  created_at?: Maybe<Scalars['String']>;
  /** The Hotel Portfolio records related to this portfolio. */
  hotels?: Maybe<Array<Maybe<Hotel>>>;
  /** The database ID of the portfolio */
  id: Scalars['String'];
  /** When this record was last updated. */
  updated_at?: Maybe<Scalars['String']>;
  /** Relationship to the user this portfolio belongs to. */
  user?: Maybe<User>;
  /** The ID of the user this portfolio belongs to. */
  user_id: Scalars['String'];
};

export type PortfolioLookup = {
  lookupColumn: PortfolioLookupType;
  lookupValue: Scalars['String'];
};

export enum PortfolioLookupType {
  Id = 'ID',
  UserId = 'USER_ID'
}

export type Query = {
  __typename?: 'Query';
  /** Retrieve room data for a give brand code and snapshot date, including both past and present data */
  allRoomData?: Maybe<Array<Maybe<DailyRoomTypes>>>;
  bestrevData?: Maybe<Array<Maybe<BestrevData>>>;
  /** Get a hotels combined forecast that uses User, BestRev, and Trend values */
  combinedHotelForecast?: Maybe<Array<Maybe<Forecast>>>;
  /** Get the list of hotels included in the shopped rates */
  compSet?: Maybe<Array<Maybe<ShoppedHotel>>>;
  /** Returns the list of shopped hotels that are available for the hotel. */
  compSetOtai?: Maybe<Array<Maybe<ShoppedHotel>>>;
  computeFeatureFlags?: Maybe<Array<Maybe<FeatureFlag>>>;
  /** Count events for a hotel over a date range */
  countHotelEvents?: Maybe<EventCount>;
  currentRateTable?: Maybe<Array<Maybe<DailyRoomRate>>>;
  /** Find events for a hotel over a date range, broken down by stay date. */
  dailyEvents?: Maybe<Array<Maybe<DailyEvents>>>;
  /** Returns rates for a subscription, grouped by stay_date. */
  dailyOtaiRates?: Maybe<Array<Maybe<DailyRates>>>;
  /** Get all rate shops for a given brand code and snapshot date */
  dailyRates?: Maybe<Array<Maybe<DailyRates>>>;
  /** Get the lowest loaded flex rate for a hotel for a specific snapshot date */
  dailyRoomRates?: Maybe<Array<Maybe<DailyRoomRate>>>;
  /** Get the lowest loaded flex rate for a hotel for a snapshot date, same time last year */
  dailyRoomRatesLy?: Maybe<Array<Maybe<DailyRoomRate>>>;
  eventWidget?: Maybe<Array<Maybe<Event>>>;
  /** Get all events from the database */
  events?: Maybe<Array<Maybe<Event>>>;
  finalRoomData?: Maybe<Array<Maybe<Room>>>;
  findHotels?: Maybe<Array<Maybe<Hotel>>>;
  /** Get an OTAI Subscription by Brand Code, ID, or Subscription ID */
  findOtaiSubscriptionBy?: Maybe<OtaiSubscription>;
  fullDailyRoomRates?: Maybe<Array<Maybe<DailyRoomRate>>>;
  getByBrandCode?: Maybe<Hotel>;
  /** Find a single event by ID */
  getEvent?: Maybe<Event>;
  /** Find a single Forecast by ID */
  getForecast?: Maybe<Forecast>;
  getHotelActionItems?: Maybe<Array<Maybe<ActionItem>>>;
  getHotelAgenda?: Maybe<Array<Maybe<AgendaItem>>>;
  /** Get the forecasts for a hotel over a date range */
  getHotelForecasts?: Maybe<Array<Maybe<Forecast>>>;
  /** Get a log of all rate changes made, by brandCode */
  getHotelRateChangeLogs?: Maybe<Array<Maybe<RateChangeLog>>>;
  getHotelTeam?: Maybe<Array<Maybe<TeamMember>>>;
  getMeetingByDate?: Maybe<Meeting>;
  getMeetingById?: Maybe<Meeting>;
  getMeetings?: Maybe<Array<Maybe<Meeting>>>;
  /** Find a single Monthly Budget by ID */
  getMonthlyBudget?: Maybe<MonthlyBudget>;
  /** Find a single note by ID */
  getNote?: Maybe<Note>;
  /** Find a single OTAI Subscription by ID */
  getOtaiSubscription?: Maybe<OtaiSubscription>;
  /** Finda a portfolio by ID or by USER_ID */
  getPortfolio?: Maybe<Portfolio>;
  /** Get a CPM Resort by brand code */
  getResort?: Maybe<CpmResort>;
  /** Get all CPM Resorts */
  getResorts?: Maybe<Array<Maybe<CpmResort>>>;
  /** Find a single RM Rec by ID */
  getRmRec?: Maybe<RmRec>;
  /** Find a single RM Report by ID */
  getRmReport?: Maybe<Report>;
  /** Lookup a user by ID, email, or Okta UID */
  getUser?: Maybe<User>;
  getUserOption?: Maybe<UserOption>;
  /** Get daily group data for a given brand code */
  groupDaily?: Maybe<Array<Maybe<DailyGroupData>>>;
  /** Find events for a hotel over a date range */
  hotelEvents?: Maybe<Array<Maybe<Event>>>;
  /** Find Monthly Budgets for a hotel */
  hotelMonthlyBudgets?: Maybe<Array<Maybe<MonthlyBudget>>>;
  /** Find notes for a hotel */
  hotelNotes?: Maybe<Array<Maybe<DailyNotes>>>;
  /** Get all hotel portfolios from the database */
  hotelPortfolios?: Maybe<Array<Maybe<HotelPortfolio>>>;
  /** Find events for a hotel over a date range */
  hotelRmRecs?: Maybe<Array<Maybe<RmRec>>>;
  /** Find RM Reports for a hotel */
  hotelRmReports?: Maybe<Array<Maybe<Report>>>;
  hotels?: Maybe<Array<Maybe<Hotel>>>;
  listFeatureFlags?: Maybe<Array<Maybe<FeatureFlag>>>;
  /** Find forecasts for a hotel or user */
  listForecasts?: Maybe<Array<Maybe<Forecast>>>;
  /** Get all OTAI Subscriptions for a given ID, Brand Code, or Subscription ID */
  listOtaiSubscriptions?: Maybe<Array<Maybe<OtaiSubscription>>>;
  listRmHotels?: Maybe<Array<Maybe<Hotel>>>;
  /** Get all Live data for a given brand code */
  liveData?: Maybe<Array<Maybe<LiveData>>>;
  /** Returns rates for a subscription. Not grouped by stay_date. */
  lowestRates?: Maybe<Array<Maybe<OtaiRate>>>;
  meetingTools?: Maybe<MeetingService>;
  /** Get all Monthly Budgets from the database */
  monthlyBudgets?: Maybe<Array<Maybe<MonthlyBudget>>>;
  /** Get all notes from the database */
  notes?: Maybe<Array<Maybe<Note>>>;
  /** Get all OTAI Subscriptions from the database */
  otaiSubscriptions?: Maybe<Array<Maybe<OtaiSubscription>>>;
  overviewTable?: Maybe<Array<Maybe<TableOverview>>>;
  /** Get all portfolios from the database */
  portfolios?: Maybe<Array<Maybe<Portfolio>>>;
  /** Get all RM data for a given brand code and snapshot date */
  rmData?: Maybe<Array<Maybe<RmData>>>;
  /** Get all RM Recs from the database */
  rmRecs?: Maybe<Array<Maybe<RmRec>>>;
  /** Get all RM Reports from the database */
  rmReports?: Maybe<Array<Maybe<Report>>>;
  /** Retrieve room data for a give brand code and snapshot date */
  roomData?: Maybe<Array<Maybe<Room>>>;
  roomList?: Maybe<Array<Maybe<ColumnDetails>>>;
  shareUrl?: Maybe<Scalars['String']>;
  trackingByEmail?: Maybe<Array<Maybe<Tracking>>>;
  trackingByHotel?: Maybe<Array<Maybe<Tracking>>>;
  uploadUrl?: Maybe<Scalars['String']>;
  /** Find Monthly Budgets created by a user with the provided ID */
  userMonthlyBudgets?: Maybe<Array<Maybe<MonthlyBudget>>>;
  /** Get setting record for a given User ID */
  userSetting?: Maybe<Setting>;
  /** Get all users from the database */
  users?: Maybe<Array<Maybe<User>>>;
};


export type QueryAllRoomDataArgs = {
  brandCode?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  snapshotDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
  useCache?: InputMaybe<Scalars['Boolean']>;
};


export type QueryBestrevDataArgs = {
  brandCode: Scalars['String'];
  useCache?: InputMaybe<Scalars['Boolean']>;
};


export type QueryCombinedHotelForecastArgs = {
  endDate?: InputMaybe<Scalars['String']>;
  hotelId?: InputMaybe<Scalars['Int']>;
  snapshotDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};


export type QueryCompSetArgs = {
  brandCode?: InputMaybe<Scalars['String']>;
  snapshotDate?: InputMaybe<Scalars['String']>;
};


export type QueryCompSetOtaiArgs = {
  brandCode?: InputMaybe<Scalars['String']>;
};


export type QueryComputeFeatureFlagsArgs = {
  userId?: InputMaybe<Scalars['String']>;
};


export type QueryCountHotelEventsArgs = {
  filters: FindEventArgs;
};


export type QueryCurrentRateTableArgs = {
  args: CurrentRateTableArgs;
};


export type QueryDailyEventsArgs = {
  filters: FindEventArgs;
};


export type QueryDailyOtaiRatesArgs = {
  brandCode?: InputMaybe<Scalars['String']>;
  params: RateQueryParams;
};


export type QueryDailyRatesArgs = {
  brandCode?: InputMaybe<Scalars['String']>;
  snapshotDate?: InputMaybe<Scalars['String']>;
};


export type QueryDailyRoomRatesArgs = {
  brandCode?: InputMaybe<Scalars['String']>;
  days?: InputMaybe<Scalars['Int']>;
  endDate?: InputMaybe<Scalars['String']>;
  snapshotDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};


export type QueryDailyRoomRatesLyArgs = {
  brandCode?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  snapshotDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};


export type QueryEventWidgetArgs = {
  endDate?: InputMaybe<Scalars['String']>;
  hotelId?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['String']>;
};


export type QueryFinalRoomDataArgs = {
  brandCode?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};


export type QueryFindHotelsArgs = {
  searchTerm?: InputMaybe<Scalars['String']>;
};


export type QueryFindOtaiSubscriptionByArgs = {
  lookup: OtaiSubLookup;
};


export type QueryFullDailyRoomRatesArgs = {
  brandCode?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  snapshotDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
  yearsCompare?: InputMaybe<Scalars['Int']>;
};


export type QueryGetByBrandCodeArgs = {
  brandCode?: InputMaybe<Scalars['String']>;
};


export type QueryGetEventArgs = {
  id: Scalars['String'];
};


export type QueryGetForecastArgs = {
  id: Scalars['String'];
};


export type QueryGetHotelActionItemsArgs = {
  brandCode: Scalars['String'];
};


export type QueryGetHotelAgendaArgs = {
  brandCode: Scalars['String'];
  dueDate?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
};


export type QueryGetHotelForecastsArgs = {
  filters: GetHotelForecastsArgs;
};


export type QueryGetHotelRateChangeLogsArgs = {
  brandCode: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
};


export type QueryGetHotelTeamArgs = {
  active?: InputMaybe<TeamMemberActive>;
  brandCode: Scalars['String'];
};


export type QueryGetMeetingByDateArgs = {
  brandCode: Scalars['String'];
  meetingDate: Scalars['String'];
};


export type QueryGetMeetingByIdArgs = {
  meetingId?: InputMaybe<Scalars['String']>;
};


export type QueryGetMeetingsArgs = {
  brandCode?: InputMaybe<Scalars['String']>;
  meetingDate?: InputMaybe<Scalars['String']>;
};


export type QueryGetMonthlyBudgetArgs = {
  id: Scalars['String'];
};


export type QueryGetNoteArgs = {
  id: Scalars['String'];
};


export type QueryGetOtaiSubscriptionArgs = {
  id: Scalars['String'];
};


export type QueryGetPortfolioArgs = {
  lookup: PortfolioLookup;
};


export type QueryGetResortArgs = {
  brandCode: Scalars['BrandCode'];
};


export type QueryGetRmRecArgs = {
  id: Scalars['String'];
};


export type QueryGetRmReportArgs = {
  id: Scalars['String'];
};


export type QueryGetUserArgs = {
  lookup: UserLookup;
};


export type QueryGetUserOptionArgs = {
  brandCode: Scalars['String'];
  userId: Scalars['String'];
};


export type QueryGroupDailyArgs = {
  brandCode?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
  useCache?: InputMaybe<Scalars['Boolean']>;
};


export type QueryHotelEventsArgs = {
  filters: FindEventArgs;
};


export type QueryHotelMonthlyBudgetsArgs = {
  filters: FindHotelMonthlyBudgetArgs;
};


export type QueryHotelNotesArgs = {
  filters: FindNotesArgs;
};


export type QueryHotelRmRecsArgs = {
  filters: FindRmRecArgs;
};


export type QueryHotelRmReportsArgs = {
  filters: FindRmReportsArgs;
};


export type QueryHotelsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};


export type QueryListForecastsArgs = {
  lookup: ForecastLookup;
};


export type QueryListOtaiSubscriptionsArgs = {
  lookup: OtaiSubLookup;
};


export type QueryLiveDataArgs = {
  brandCode?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  snapshotDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};


export type QueryLowestRatesArgs = {
  params: RateQueryParams;
};


export type QueryMeetingToolsArgs = {
  brandCode?: InputMaybe<Scalars['String']>;
};


export type QueryOverviewTableArgs = {
  args: TableOverviewArgs;
};


export type QueryRmDataArgs = {
  args: RmDataArgs;
};


export type QueryRmReportsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};


export type QueryRoomDataArgs = {
  brandCode?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  snapshotDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};


export type QueryRoomListArgs = {
  brandCode?: InputMaybe<Scalars['String']>;
  snapshotDate?: InputMaybe<Scalars['String']>;
};


export type QueryShareUrlArgs = {
  bucket?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
};


export type QueryTrackingByEmailArgs = {
  brandCode?: InputMaybe<Scalars['String']>;
  dateAdded?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  userEmail?: InputMaybe<Scalars['String']>;
};


export type QueryTrackingByHotelArgs = {
  brandCode?: InputMaybe<Scalars['String']>;
  cursor?: InputMaybe<Scalars['String']>;
  dateAdded?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
};


export type QueryUploadUrlArgs = {
  bucket?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
};


export type QueryUserMonthlyBudgetsArgs = {
  createdById: Scalars['String'];
};


export type QueryUserSettingArgs = {
  userId: Scalars['String'];
};

export type QueryResponse = {
  __typename?: 'QueryResponse';
  cursor?: Maybe<Scalars['String']>;
  data?: Maybe<Array<Maybe<Tracking>>>;
};

export type Rate = OtaiRate | RateShop;

export type RateChange = {
  __typename?: 'RateChange';
  /** Is the rate change a best flex rate? */
  best_flex?: Maybe<Scalars['Boolean']>;
  /** Is the rate change cancellable? */
  cancellable?: Maybe<Scalars['Boolean']>;
  /** An error message if the rate change is not available */
  message?: Maybe<Scalars['String']>;
  /** The name of the room for the rate change */
  roomName?: Maybe<Scalars['String']>;
  /** The room type for the rate change */
  roomType?: Maybe<Scalars['String']>;
  /** The shopped rate for the rate change */
  value?: Maybe<Scalars['Float']>;
};

export type RateChangeLog = {
  __typename?: 'RateChangeLog';
  appName?: Maybe<Scalars['String']>;
  brandCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  errorMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Float']>;
  stayDate?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<Scalars['String']>;
};

export type RateChangesList = {
  __typename?: 'RateChangesList';
  /** The details of the rate change */
  change?: Maybe<RateChange>;
  /** How many days ago the rate change occurred */
  days?: Maybe<Scalars['Int']>;
};

/**
 * The paramaeters for the OTAI Lowest Rates API
 * The latest documenation can be found at https://api.mylighthouse.com/
 */
export type RateQueryParams = {
  /** Filter to return only Best Flex rates. Default: false */
  bar?: InputMaybe<Scalars['Boolean']>;
  /**
   * Days with which to calculate ratechanges, relative to the local hotel date. Must be comma-separated.
   * E.g. 1,3,7 returns changes with 1, 3 and 7 days in the past.
   * Default: no ratechanges, min: 1, max: 30. Max 3 values allowed.
   * Can not be used when a fromDate in the past is given.
   */
  changeDays?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /**
   * IDs of the compsets from which you want to include the competitors.
   * Default: 1, the primary compset which can be configured in the app.
   */
  compsetIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /**
   * Specify desired currency of the returned rates.
   * Value is the 3-letter currency code (see e.g. World Currency Symbols).
   * Default: currency of the hotel.
   */
  currency?: InputMaybe<Scalars['String']>;
  /**
   * Specify the output format.
   * Supported formats are: json (the default) and csv.
   * The csv output has the same values.
   */
  format?: InputMaybe<Scalars['String']>;
  /**
   * Format: YYYY-MM-DD. Return rates starting from this arrival date.
   * This can be in the past as well as in the future.
   */
  fromDate?: InputMaybe<Scalars['String']>;
  /**
   * Length of stay.
   * Default: 1, Min: 1, Max: 45.
   */
  los?: InputMaybe<Scalars['Int']>;
  /**
   * Filter to only return rates that include a mealtype. One of:
   * Filter not applied: 0
   * Breakfast included: 1
   * Half board included: 2
   * Full board included: 3
   * All inclusive: 4
   * Room only: 5
   * Default: filter not applied.
   */
  mealType?: InputMaybe<MealType>;
  /**
   * Available OTAs for the subscription can be queried using the Hotels API.
   * Default: bookingdotcom.
   */
  ota?: InputMaybe<Scalars['String']>;
  /**
   * Filter to return rates that accommodate at least this amount of persons.
   * Default: 2, Min: 1, Max: 10 for bookingdotcom, 2 for others.
   */
  persons?: InputMaybe<Scalars['Int']>;
  /**
   * Filter to only return rates of a roomtype. One of:
   *   dormitory
   *   sharedbathroom
   *   suite
   *   family
   *   apartment
   *   parkandfly
   *   camper
   *   villa
   *   bungalow
   *   budget
   *   premium
   *   standard
   *   single
   * Default: filter not applied.
   */
  roomType?: InputMaybe<Scalars['String']>;
  /**
   * Amount of days to return, starting from the current hotel's date,
   * or from the fromDate if it was provided.
   * Max: 365.
   */
  shopLength?: InputMaybe<Scalars['Int']>;
  /** Required ID of the OTAI subscription. */
  subscriptionId: Scalars['String'];
};

export type RateShop = {
  __typename?: 'RateShop';
  /** The channel identifier for the rate */
  channel_id?: Maybe<Scalars['String']>;
  /** The internal ID of the competitor hotel, as provided by the rate shop vendor */
  competitor_id?: Maybe<Scalars['String']>;
  /** The name of the competitor hotel */
  competitor_name?: Maybe<Scalars['String']>;
  /** The currency of the rate */
  currency?: Maybe<Scalars['String']>;
  /** The file in S3 that contains the rate */
  filename?: Maybe<Scalars['String']>;
  /** The number of guests for the shopped rate */
  guests?: Maybe<Scalars['Int']>;
  /** The internal ID of the client hotel, as provided by the rate shop vendor */
  hotel_id?: Maybe<Scalars['Int']>;
  /** Date and time the job to build the table was processed. Format: YYYY-MM-DD HH:MM:SS.ms */
  jobprocesseddatetime?: Maybe<Scalars['String']>;
  /** The length of stay for the shopped rate */
  los?: Maybe<Scalars['Int']>;
  /** The meal type for the shopped rate */
  meal_type?: Maybe<Scalars['String']>;
  /** The membership type for the shopped rate */
  membership_type?: Maybe<Scalars['String']>;
  /** The BW Brand ID for the client hotel */
  property_id?: Maybe<Scalars['String']>;
  /** The shopped rate */
  rate?: Maybe<Scalars['Float']>;
  /** The rate remark for the shopped rate */
  rate_remark?: Maybe<Scalars['String']>;
  /** The rate type for the shopped rate */
  rate_type?: Maybe<Scalars['String']>;
  /** Date and timestamp of when rate was shopped. Format: YYYY-MM-DD HH:MM:SS */
  rateshop_date?: Maybe<Scalars['String']>;
  /** The requested rate type for the shopped rate */
  requested_rate_type?: Maybe<Scalars['String']>;
  /** The name of the room */
  room_name?: Maybe<Scalars['String']>;
  /** The room type for the shopped rate */
  room_type?: Maybe<Scalars['String']>;
  /** The date this rate was shopped. Format: YYYY-MM-DD */
  snapshot_date?: Maybe<Scalars['Date']>;
  /** The date of the shopped rate. Format: YYYY-MM-DD */
  stay_date?: Maybe<Scalars['Date']>;
};

/** RM Reports store the report file and metadata for a report */
export type Report = {
  __typename?: 'Report';
  /** When this record was created. */
  created_at?: Maybe<Scalars['String']>;
  /** Relationship to the user who created this RM report. */
  created_by?: Maybe<User>;
  /** The ID of the user who created this RM report. */
  created_by_id?: Maybe<Scalars['String']>;
  /** The data file for this RM report. */
  data_file?: Maybe<Scalars['String']>;
  /** Relationship to the hotel this RM report belongs to. */
  hotel?: Maybe<Hotel>;
  /** The ID of the hotel this RM report belongs to. */
  hotel_id?: Maybe<Scalars['Int']>;
  /** The database ID of the RM report */
  id?: Maybe<Scalars['String']>;
  /** The notes for this RM report. */
  notes?: Maybe<NoteContent>;
  /** The report date for this RM report. */
  report_date?: Maybe<Scalars['Date']>;
  /** The snapshot date for this RM report. */
  snapshot_date?: Maybe<Scalars['Date']>;
  /** When this record was last updated. */
  updated_at?: Maybe<Scalars['String']>;
};

/** rm_forecast_data table from Redshift */
export type RmData = {
  __typename?: 'RmData';
  /** Average daily rate */
  adr?: Maybe<Scalars['Float']>;
  /** Average daily rate same time last year */
  adr_ly?: Maybe<Scalars['Float']>;
  /** Average daily rate same time last year final */
  adr_ly_final?: Maybe<Scalars['Float']>;
  /** Hotel's 5-digit brand ID number. Alias of resort_id */
  brand_code?: Maybe<Scalars['BrandCode']>;
  /** Capacity that does not include Out of Order (OOO) rooms */
  capacity?: Maybe<Scalars['Int']>;
  /** Capacity that does not include Out of Order (OOO) rooms same time last year */
  capacity_ly?: Maybe<Scalars['Int']>;
  /** Capacity that does not include Out of Order (OOO) rooms same time last year final */
  capacity_ly_final?: Maybe<Scalars['Int']>;
  /** Competitor set ADR from prior year STR data */
  compadr_py?: Maybe<Scalars['Float']>;
  /** Competitor set Occupancy from prior year STR data */
  compocc_py?: Maybe<Scalars['Float']>;
  /** Competitor set RevPAR from prior year STR data */
  comprevpar_py?: Maybe<Scalars['Float']>;
  /** Day of week of stay date. 0 (Sunday) to 6 (Saturday). */
  date_day?: Maybe<Scalars['String']>;
  /** Day of month for stay date */
  date_dom?: Maybe<Scalars['String']>;
  /** Month of stay date. */
  date_month?: Maybe<Scalars['String']>;
  /** Quarter of stay date */
  date_quarter?: Maybe<Scalars['String']>;
  /** Week of stay date */
  date_week?: Maybe<Scalars['String']>;
  /** Year and day of week, of stay date */
  date_ydw?: Maybe<Scalars['String']>;
  /** Year of stay date */
  date_year?: Maybe<Scalars['String']>;
  /** Year of stay date one year into the future */
  date_year_ny?: Maybe<Scalars['String']>;
  /** Year and month, of stay date */
  date_ym?: Maybe<Scalars['String']>;
  /** Year and month, of stay date one year into the future */
  date_ym_ny?: Maybe<Scalars['String']>;
  /** Year and quarter, of stay date */
  date_yq?: Maybe<Scalars['String']>;
  /** Year and quarter, of stay date one year into the future */
  date_yq_ny?: Maybe<Scalars['String']>;
  /** Year and week, of stay date */
  date_yw?: Maybe<Scalars['String']>;
  /** Year and week, of stay date one year into the future */
  date_yw_ny?: Maybe<Scalars['String']>;
  /** Δ 1 Day Delta Sold */
  delta_1day?: Maybe<Scalars['Int']>;
  /** Δ 3 Day Delta Sold */
  delta_3day?: Maybe<Scalars['Int']>;
  /** Δ 7 Day Delta Sold */
  delta_7day?: Maybe<Scalars['Int']>;
  /** Day of week */
  dow?: Maybe<Scalars['String']>;
  /** Group ADR */
  group_adr?: Maybe<Scalars['Float']>;
  /** Group ADR same time last year final */
  group_adr_ly_final?: Maybe<Scalars['Float']>;
  group_adr_ly_final_n?: Maybe<Scalars['Float']>;
  group_adr_ly_n?: Maybe<Scalars['Float']>;
  /** Group block remaining */
  group_blocked?: Maybe<Scalars['Int']>;
  /** 1 day group sold picked up */
  group_pu_1day?: Maybe<Scalars['Int']>;
  /** 2 day group sold picked up */
  group_pu_2day?: Maybe<Scalars['Int']>;
  /** 3 day group sold picked up */
  group_pu_3day?: Maybe<Scalars['Int']>;
  /** 4 day group sold picked up */
  group_pu_4day?: Maybe<Scalars['Int']>;
  /** 5 day group sold picked up */
  group_pu_5day?: Maybe<Scalars['Int']>;
  /** 6 day group sold picked up */
  group_pu_6day?: Maybe<Scalars['Int']>;
  /** 7 day group sold picked up */
  group_pu_7day?: Maybe<Scalars['Int']>;
  /** 14 day group sold picked up */
  group_pu_14day?: Maybe<Scalars['Int']>;
  /** 30 day group sold picked up */
  group_pu_30day?: Maybe<Scalars['Int']>;
  /** Group revenue */
  group_revenue?: Maybe<Scalars['Float']>;
  /** Group revenue same time last year final */
  group_revenue_ly_final?: Maybe<Scalars['Float']>;
  group_revenue_ly_final_n?: Maybe<Scalars['Float']>;
  group_revenue_ly_n?: Maybe<Scalars['Float']>;
  /** Group RevPAR */
  group_revpar?: Maybe<Scalars['Float']>;
  /** Group sold */
  group_sold?: Maybe<Scalars['Int']>;
  /** Group sold same time last year */
  group_sold_ly?: Maybe<Scalars['Int']>;
  /** Group sold same time last year final */
  group_sold_ly_final?: Maybe<Scalars['Int']>;
  group_sold_ly_final_n?: Maybe<Scalars['Int']>;
  group_sold_ly_n?: Maybe<Scalars['Int']>;
  /** Last transient booking date */
  last_transient_bkg_dt?: Maybe<Scalars['Date']>;
  /** Rate level A details */
  lvl_a_details?: Maybe<Scalars['String']>;
  /** Rate level A occupancy */
  lvl_a_occ?: Maybe<Scalars['Float']>;
  /** Rate level A remaining */
  lvl_a_remaining?: Maybe<Scalars['Int']>;
  /** Rate level A sold */
  lvl_a_sold?: Maybe<Scalars['Int']>;
  /** Rate level A status */
  lvl_a_status?: Maybe<Scalars['String']>;
  /** Rate level B details */
  lvl_b_details?: Maybe<Scalars['String']>;
  /** Rate level B occupancy */
  lvl_b_occ?: Maybe<Scalars['Float']>;
  /** Rate level B remaining */
  lvl_b_remaining?: Maybe<Scalars['Int']>;
  /** Rate level B sold */
  lvl_b_sold?: Maybe<Scalars['Int']>;
  /** Rate level B status */
  lvl_b_status?: Maybe<Scalars['String']>;
  /** Rate level C details */
  lvl_c_details?: Maybe<Scalars['String']>;
  /** Rate level C occupancy */
  lvl_c_occ?: Maybe<Scalars['Float']>;
  /** Rate level C remaining */
  lvl_c_remaining?: Maybe<Scalars['Int']>;
  /** Rate level C sold */
  lvl_c_sold?: Maybe<Scalars['Int']>;
  /** Rate level C status */
  lvl_c_status?: Maybe<Scalars['String']>;
  /** Rate level D details */
  lvl_d_details?: Maybe<Scalars['String']>;
  /** Rate level D occupancy */
  lvl_d_occ?: Maybe<Scalars['Float']>;
  /** Rate level D remaining */
  lvl_d_remaining?: Maybe<Scalars['Int']>;
  /** Rate level D sold */
  lvl_d_sold?: Maybe<Scalars['Int']>;
  /** Rate level D status */
  lvl_d_status?: Maybe<Scalars['String']>;
  /** Rate level E details */
  lvl_e_details?: Maybe<Scalars['String']>;
  /** Rate level E occupancy */
  lvl_e_occ?: Maybe<Scalars['Float']>;
  /** Rate level E remaining */
  lvl_e_remaining?: Maybe<Scalars['Int']>;
  /** Rate level E sold */
  lvl_e_sold?: Maybe<Scalars['Int']>;
  /** Rate level E status */
  lvl_e_status?: Maybe<Scalars['String']>;
  /** Rate level F details */
  lvl_f_details?: Maybe<Scalars['String']>;
  /** Rate level F occupancy */
  lvl_f_occ?: Maybe<Scalars['Float']>;
  /** Rate level F remaining */
  lvl_f_remaining?: Maybe<Scalars['Int']>;
  /** Rate level F sold */
  lvl_f_sold?: Maybe<Scalars['Int']>;
  /** Rate level F status */
  lvl_f_status?: Maybe<Scalars['String']>;
  /** Rate level G details */
  lvl_g_details?: Maybe<Scalars['String']>;
  /** Rate level G occupancy */
  lvl_g_occ?: Maybe<Scalars['Float']>;
  /** Rate level G remaining */
  lvl_g_remaining?: Maybe<Scalars['Int']>;
  /** Rate level G sold */
  lvl_g_sold?: Maybe<Scalars['Int']>;
  /** Rate level G status */
  lvl_g_status?: Maybe<Scalars['String']>;
  /** Rate level H details */
  lvl_h_details?: Maybe<Scalars['String']>;
  /** Rate level H occupancy */
  lvl_h_occ?: Maybe<Scalars['Float']>;
  /** Rate level H remaining */
  lvl_h_remaining?: Maybe<Scalars['Int']>;
  /** Rate level H sold */
  lvl_h_sold?: Maybe<Scalars['Int']>;
  /** Rate level H status */
  lvl_h_status?: Maybe<Scalars['String']>;
  /**
   * Group revenue same time last year final. Alias of group_revenue_ly_final
   * @deprecated Use 'group_revenue_ly_final' instead
   */
  ly_final_revenue_grp?: Maybe<Scalars['Float']>;
  /**
   * Occupancy.
   * Calculated using total_capacity and sold.
   */
  occ?: Maybe<Scalars['Float']>;
  /**
   * Occupancy same time last year.
   * Calculated using total_capacity_ly and sold_ly.
   */
  occ_ly?: Maybe<Scalars['Float']>;
  /**
   * Occupancy same time last year final.
   * Calculated using total_capacity_ly_final and sold_ly_final.
   */
  occ_ly_final?: Maybe<Scalars['Float']>;
  /**
   * Occupancy, including Rooms Committed (i.e. Group Blocks).
   * Calculated as rooms_committed / total_capacity.
   */
  occ_rc?: Maybe<Scalars['Float']>;
  /** Out of order rooms */
  ooo?: Maybe<Scalars['Int']>;
  /** Out of order rooms same time last year */
  ooo_ly?: Maybe<Scalars['Int']>;
  /** Out of order rooms same time last year final */
  ooo_ly_final?: Maybe<Scalars['Int']>;
  /** Pace variance. TODO - Need more details. */
  pace_var?: Maybe<Scalars['Float']>;
  /** Guarantee and cancel policy */
  policy_cd?: Maybe<Scalars['String']>;
  /** 1 day sold picked up */
  pu_1day?: Maybe<Scalars['Int']>;
  /** 1 day sold picked up same time last year */
  pu_1day_ly?: Maybe<Scalars['Int']>;
  /** 2 day sold picked up */
  pu_2day?: Maybe<Scalars['Int']>;
  /** 3 day sold picked up */
  pu_3day?: Maybe<Scalars['Int']>;
  /** 3 day sold picked up same time last year */
  pu_3day_ly?: Maybe<Scalars['Int']>;
  /** 4 day sold picked up */
  pu_4day?: Maybe<Scalars['Int']>;
  /** 5 day sold picked up */
  pu_5day?: Maybe<Scalars['Int']>;
  /** 6 day sold picked up */
  pu_6day?: Maybe<Scalars['Int']>;
  /** 7 day sold picked up */
  pu_7day?: Maybe<Scalars['Int']>;
  /** 7 day sold picked up same time last year */
  pu_7day_ly?: Maybe<Scalars['Int']>;
  /** 14 day sold picked up */
  pu_14day?: Maybe<Scalars['Int']>;
  /** 30 day sold picked up */
  pu_30day?: Maybe<Scalars['Int']>;
  /** Pickup on the day of arrival. Alias of pu_dayofarrival */
  pu_dayofarr?: Maybe<Scalars['Int']>;
  /** Pickup on the day of arrival same time last year */
  pu_dayofarr_ly?: Maybe<Scalars['Int']>;
  /**
   * Pickup on the day of arrival
   * @deprecated Use 'pu_dayofarr' instead
   */
  pu_dayofarrival?: Maybe<Scalars['Int']>;
  /** Rate. TODO - Need more details. */
  rate?: Maybe<Scalars['Float']>;
  /** Remaining rooms. Rooms left to sell */
  remaining?: Maybe<Scalars['Int']>;
  /** Remaining rooms same time last year */
  remaining_ly?: Maybe<Scalars['Int']>;
  /** Remaining rooms same time last year final */
  remaining_ly_final?: Maybe<Scalars['Int']>;
  /**
   * Hotel's 5-digit brand ID number
   * @deprecated Use 'brand_code' instead
   */
  resort_id?: Maybe<Scalars['BrandCode']>;
  /** Revenue */
  revenue?: Maybe<Scalars['Float']>;
  /** Revenue same time last year */
  revenue_ly?: Maybe<Scalars['Float']>;
  /** Revenue same time last year final */
  revenue_ly_final?: Maybe<Scalars['Float']>;
  /** RevPAR. Revenue per available room */
  revpar?: Maybe<Scalars['Float']>;
  /** RevPAR same time last year */
  revpar_ly?: Maybe<Scalars['Float']>;
  /** RevPAR same time last year final */
  revpar_ly_final?: Maybe<Scalars['Float']>;
  /**
   * Rooms committed.
   * Calculated as sum of transient_sold, group_sold, and group_blocked.
   */
  rooms_committed?: Maybe<Scalars['Int']>;
  /** Snapshot date the data was taken */
  snapshot_date?: Maybe<Scalars['Date']>;
  /** Snapshot date the data was taken same time last year */
  snapshot_date_ly?: Maybe<Scalars['Date']>;
  /** Rooms sold */
  sold?: Maybe<Scalars['Int']>;
  /** Rooms sold same time last year */
  sold_ly?: Maybe<Scalars['Int']>;
  /** Rooms sold same time last year final */
  sold_ly_final?: Maybe<Scalars['Int']>;
  sold_ly_final_n?: Maybe<Scalars['Int']>;
  sold_ly_n?: Maybe<Scalars['Int']>;
  sold_n?: Maybe<Scalars['Int']>;
  /** Arrival date for data row */
  stay_date?: Maybe<Scalars['Date']>;
  /** Arrival date for data row same time last year */
  stay_date_ly?: Maybe<Scalars['Date']>;
  /** Same date one year into the future */
  stay_date_ny?: Maybe<Scalars['Date']>;
  /**
   * Total capacity.
   * Calculated using ooo and capacity.
   */
  total_capacity?: Maybe<Scalars['Int']>;
  /**
   * Total capacity same time last year.
   * Calculated using ooo_ly and capacity_ly.
   */
  total_capacity_ly?: Maybe<Scalars['Int']>;
  /**
   * Total capacity same time last year final.
   * Calculated using ooo_ly_final and capacity_ly_final.
   */
  total_capacity_ly_final?: Maybe<Scalars['Int']>;
  /**
   * Total group rooms blocked.
   * Calculated using group_sold and group_blocked.
   */
  total_potential_group?: Maybe<Scalars['Int']>;
  /**
   * Total sold.
   * Calculated using transient_sold and group_sold.
   */
  total_sold?: Maybe<Scalars['Int']>;
  /**
   * Transient ADR.
   * Calculated using transient_sold and transient_revenue.
   */
  transient_adr?: Maybe<Scalars['Float']>;
  /** Transient rooms booked last 7 days */
  transient_l7_bkg_days?: Maybe<Scalars['Int']>;
  /**
   * Total revenue.
   * Calculated using revenue minus group_revenue.
   */
  transient_revenue?: Maybe<Scalars['Float']>;
  /** Transient rooms sold */
  transient_sold?: Maybe<Scalars['Int']>;
  /** Transient rooms sold same time last year */
  transient_sold_ly?: Maybe<Scalars['Int']>;
  /** Transient rooms sold same time last year final */
  transient_sold_ly_final?: Maybe<Scalars['Int']>;
  transient_sold_ly_final_n?: Maybe<Scalars['Int']>;
  transient_sold_ly_n?: Maybe<Scalars['Int']>;
  /** Variance ADR compared to ADR same time last year */
  var_adr_ly?: Maybe<Scalars['Float']>;
  /** Variance ADR compared to ADR same time last year final */
  var_adr_ly_final?: Maybe<Scalars['Float']>;
  var_adr_ly_final_n?: Maybe<Scalars['Float']>;
  var_adr_ly_n?: Maybe<Scalars['Float']>;
  /** Variance capacity compared to capacity same time last year */
  var_capacity_ly?: Maybe<Scalars['Int']>;
  /** Variance capacity compared to capacity same time last year final */
  var_capacity_ly_final?: Maybe<Scalars['Int']>;
  /** Variance group revenue compared to group revenue same time last year final */
  var_group_revenue_ly_final?: Maybe<Scalars['Float']>;
  /** Variance group sold compared to group sold same time last year */
  var_group_sold_ly?: Maybe<Scalars['Int']>;
  /** Variance group sold compared to group sold same time last year final */
  var_group_sold_ly_final?: Maybe<Scalars['Int']>;
  /** Variance occpupancy compared to occupancy same time last year */
  var_occ_ly?: Maybe<Scalars['Float']>;
  /** Variance occpupancy compared to occupancy same time last year final */
  var_occ_ly_final?: Maybe<Scalars['Float']>;
  /** Variance OOO compared to OOO same time last year */
  var_ooo_ly?: Maybe<Scalars['Float']>;
  /** Variance OOO compared to OOO same time last year final */
  var_ooo_ly_final?: Maybe<Scalars['Int']>;
  /** Variance remaining compared to remaining same time last year */
  var_remaining_ly?: Maybe<Scalars['Int']>;
  /** Variance remaining compared to remaining same time last year final */
  var_remaining_ly_final?: Maybe<Scalars['Int']>;
  /** Variance revenue compared to revenue same time last year */
  var_revenue_ly?: Maybe<Scalars['Float']>;
  /** Variance revenue compared to revenue same time last year final */
  var_revenue_ly_final?: Maybe<Scalars['Float']>;
  var_revenue_ly_final_n?: Maybe<Scalars['Float']>;
  var_revenue_ly_n?: Maybe<Scalars['Float']>;
  /** Variance revpar compared to revpar same time last year */
  var_revpar_ly?: Maybe<Scalars['Float']>;
  /** Variance revpar compared to revpar same time last year final */
  var_revpar_ly_final?: Maybe<Scalars['Float']>;
  /** Variance sold compared to sold same time last year */
  var_sold_ly?: Maybe<Scalars['Int']>;
  /** Variance sold compared to sold same time last year final */
  var_sold_ly_final?: Maybe<Scalars['Int']>;
  var_sold_ly_final_n?: Maybe<Scalars['Int']>;
  var_sold_ly_n?: Maybe<Scalars['Int']>;
  /** Variance total_capacity compared to total_capacity same time last year */
  var_total_capacity_ly?: Maybe<Scalars['Int']>;
  /** Variance total_capacity compared to total_capacity same time last year final */
  var_total_capacity_ly_final?: Maybe<Scalars['Int']>;
  /** Variance transient_sold compared to transient_sold same time last year */
  var_transient_sold_ly?: Maybe<Scalars['Int']>;
  /** Variance transient_sold compared to transient_sold same time last year final */
  var_transient_sold_ly_final?: Maybe<Scalars['Int']>;
};


/** rm_forecast_data table from Redshift */
export type RmDataDate_Year_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


/** rm_forecast_data table from Redshift */
export type RmDataDate_Ym_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


/** rm_forecast_data table from Redshift */
export type RmDataDate_Yq_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


/** rm_forecast_data table from Redshift */
export type RmDataDate_Yw_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


/** rm_forecast_data table from Redshift */
export type RmDataStay_Date_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};

export type RmDataArgs = {
  brandCode?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  hotelId?: InputMaybe<Scalars['Int']>;
  snapshotDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
  useCache?: InputMaybe<Scalars['Boolean']>;
  yearsCompare?: InputMaybe<Scalars['Int']>;
};

/**
 * RM Recommendations store rates and notes
 * that a Revenue Manager suggests a hotel change.
 */
export type RmRec = {
  __typename?: 'RmRec';
  /** Current ADR for the stay date. Derived from RM Forecast Data */
  adr?: Maybe<Scalars['Float']>;
  /** When this record was created. */
  created_at?: Maybe<Scalars['String']>;
  /** Relationship to the user who created this RM Rec. */
  created_by?: Maybe<User>;
  /** The ID of the user who created this RM Rec. */
  created_by_id?: Maybe<Scalars['String']>;
  /** What is the lowest current rate based on rooms. Derived from DailyRoomRate */
  current_rate?: Maybe<Scalars['Float']>;
  /** Day of week of stay date. 0 (Sunday) to 6 (Saturday). */
  date_day?: Maybe<Scalars['String']>;
  /** Day of month for stay date */
  date_dom?: Maybe<Scalars['String']>;
  /** Month of stay date. */
  date_month?: Maybe<Scalars['String']>;
  /** Quarter of stay date */
  date_quarter?: Maybe<Scalars['String']>;
  /** Week of stay date */
  date_week?: Maybe<Scalars['String']>;
  /** Year and day of week, of stay date */
  date_ydw?: Maybe<Scalars['String']>;
  /** Year of stay date */
  date_year?: Maybe<Scalars['String']>;
  /** Year of stay date one year into the future */
  date_year_ny?: Maybe<Scalars['String']>;
  /** Year and month, of stay date */
  date_ym?: Maybe<Scalars['String']>;
  /** Year and month, of stay date one year into the future */
  date_ym_ny?: Maybe<Scalars['String']>;
  /** Year and quarter, of stay date */
  date_yq?: Maybe<Scalars['String']>;
  /** Year and quarter, of stay date one year into the future */
  date_yq_ny?: Maybe<Scalars['String']>;
  /** Year and week, of stay date */
  date_yw?: Maybe<Scalars['String']>;
  /** Year and week, of stay date one year into the future */
  date_yw_ny?: Maybe<Scalars['String']>;
  /** Is this record visible? */
  hidden?: Maybe<Scalars['Boolean']>;
  /** Relationship to the hotel this RM Rec belongs to. */
  hotel?: Maybe<Hotel>;
  /** The ID of the hotel this RM Rec belongs to. */
  hotel_id?: Maybe<Scalars['Int']>;
  /** The database ID of the RM Rec */
  id?: Maybe<Scalars['String']>;
  /** The notes that the RM suggests */
  notes?: Maybe<Scalars['String']>;
  /** The rate that the RM recommends */
  rate?: Maybe<Scalars['Float']>;
  /** Current rooms sold. Derived from RM Forecast Data */
  sold?: Maybe<Scalars['Int']>;
  /** The status of the RM recommendation */
  status?: Maybe<RmRecStatus>;
  /** The arrival date for the RM Rec */
  stay_date?: Maybe<Scalars['Date']>;
  /** Same date one year into the future */
  stay_date_ny?: Maybe<Scalars['Date']>;
  /** When this record was last updated. */
  updated_at?: Maybe<Scalars['String']>;
};


/**
 * RM Recommendations store rates and notes
 * that a Revenue Manager suggests a hotel change.
 */
export type RmRecDate_Year_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


/**
 * RM Recommendations store rates and notes
 * that a Revenue Manager suggests a hotel change.
 */
export type RmRecDate_Ym_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


/**
 * RM Recommendations store rates and notes
 * that a Revenue Manager suggests a hotel change.
 */
export type RmRecDate_Yq_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


/**
 * RM Recommendations store rates and notes
 * that a Revenue Manager suggests a hotel change.
 */
export type RmRecDate_Yw_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


/**
 * RM Recommendations store rates and notes
 * that a Revenue Manager suggests a hotel change.
 */
export type RmRecStay_Date_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};

export enum RmRecStatus {
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Error = 'ERROR',
  Open = 'OPEN',
  Processing = 'PROCESSING',
  Uploaded = 'UPLOADED'
}

export type Room = {
  __typename?: 'Room';
  /** Hotel's 5-digit brand ID number. Alias of resort_id */
  brand_code?: Maybe<Scalars['BrandCode']>;
  /** Day of week of stay date. 0 (Sunday) to 6 (Saturday). */
  date_day?: Maybe<Scalars['String']>;
  /** Day of month for stay date */
  date_dom?: Maybe<Scalars['String']>;
  /** Month of stay date. */
  date_month?: Maybe<Scalars['String']>;
  /** Quarter of stay date */
  date_quarter?: Maybe<Scalars['String']>;
  /** Week of stay date */
  date_week?: Maybe<Scalars['String']>;
  /** Year and day of week, of stay date */
  date_ydw?: Maybe<Scalars['String']>;
  /** Year of stay date */
  date_year?: Maybe<Scalars['String']>;
  /** Year of stay date one year into the future */
  date_year_ny?: Maybe<Scalars['String']>;
  /** Year and month, of stay date */
  date_ym?: Maybe<Scalars['String']>;
  /** Year and month, of stay date one year into the future */
  date_ym_ny?: Maybe<Scalars['String']>;
  /** Year and quarter, of stay date */
  date_yq?: Maybe<Scalars['String']>;
  /** Year and quarter, of stay date one year into the future */
  date_yq_ny?: Maybe<Scalars['String']>;
  /** Year and week, of stay date */
  date_yw?: Maybe<Scalars['String']>;
  /** Year and week, of stay date one year into the future */
  date_yw_ny?: Maybe<Scalars['String']>;
  /**
   * Hotel's 5-digit brand ID number
   * @deprecated Use 'brand_code' instead
   */
  resort_id?: Maybe<Scalars['BrandCode']>;
  /** Numeric code for the room category */
  room_category?: Maybe<Scalars['String']>;
  /** Calculated value of room code */
  room_code?: Maybe<Scalars['String']>;
  /** Room bed type */
  rt_bed_type?: Maybe<Scalars['String']>;
  /** Room description */
  rt_description?: Maybe<Scalars['String']>;
  /** Calculated value of room type details */
  rt_details?: Maybe<Scalars['String']>;
  /** Best flexible rate (RACK) for the room */
  rt_flex_rate?: Maybe<Scalars['Float']>;
  /** Room meal code */
  rt_meal_code?: Maybe<Scalars['String']>;
  /** Occupancy percentage */
  rt_occ?: Maybe<Scalars['Float']>;
  /** Old room type code */
  rt_old_roomtype?: Maybe<Scalars['String']>;
  /** Order that rooms are sorted in sell order */
  rt_order_by?: Maybe<Scalars['Int']>;
  /** Room qualifier code */
  rt_qual_code?: Maybe<Scalars['String']>;
  /** Number of rooms available */
  rt_remaining?: Maybe<Scalars['Int']>;
  /** Allocated room count */
  rt_room_allocation?: Maybe<Scalars['Int']>;
  /** Number of rooms sold */
  rt_sold?: Maybe<Scalars['Int']>;
  /** Room status */
  rt_status?: Maybe<Scalars['String']>;
  /** Snapshot date the data was taken */
  snapshot_date?: Maybe<Scalars['Date']>;
  /** Arrival date for data row */
  stay_date?: Maybe<Scalars['Date']>;
  /** Same date one year into the future */
  stay_date_ny?: Maybe<Scalars['Date']>;
};


export type RoomDate_Year_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


export type RoomDate_Ym_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


export type RoomDate_Yq_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


export type RoomDate_Yw_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


export type RoomStay_Date_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};

export type Setting = {
  __typename?: 'Setting';
  /** When this record was created. */
  created_at?: Maybe<Scalars['String']>;
  /** The JSON Data for the dashboard */
  dashboards?: Maybe<DetailTable>;
  /** The ID of the Setting record. */
  id: Scalars['String'];
  /** When this record was last updated. */
  updated_at?: Maybe<Scalars['String']>;
  /** The user who the setting belongs to. */
  user?: Maybe<User>;
  /** The ID of the user who the setting belongs to. */
  user_id?: Maybe<Scalars['String']>;
};

export type ShoppedHotel = {
  __typename?: 'ShoppedHotel';
  columnSize?: Maybe<Scalars['Int']>;
  /** The internal ID of the competitor hotel, as provided by the rate shop vendor */
  competitor_id?: Maybe<Scalars['String']>;
  /** The name of the competitor hotel */
  competitor_name?: Maybe<Scalars['String']>;
  dataSource?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  renderer?: Maybe<Scalars['String']>;
  tooltip?: Maybe<Scalars['String']>;
};

export enum State {
  Abw = 'ABW',
  Ae = 'AE',
  Ain = 'AIN',
  Alabama = 'ALABAMA',
  Alaska = 'ALASKA',
  Albania = 'ALBANIA',
  Alberta = 'ALBERTA',
  Algeria = 'ALGERIA',
  AlpesMaritimes = 'ALPES_MARITIMES',
  AntiguaAndBarbuda = 'ANTIGUA_AND_BARBUDA',
  Argentina = 'ARGENTINA',
  Arizona = 'ARIZONA',
  Arkansas = 'ARKANSAS',
  Armenia = 'ARMENIA',
  Aruba = 'ARUBA',
  Austalia = 'AUSTALIA',
  Australia = 'AUSTRALIA',
  AustralianCapital = 'AUSTRALIAN_CAPITAL',
  AustralianCapitalTerritory = 'AUSTRALIAN_CAPITAL_TERRITORY',
  Austria = 'AUSTRIA',
  Av = 'AV',
  Az = 'AZ',
  Bahrain = 'BAHRAIN',
  Bali = 'BALI',
  Bangkok = 'BANGKOK',
  Bangladesh = 'BANGLADESH',
  Belgium = 'BELGIUM',
  Belize = 'BELIZE',
  Benin = 'BENIN',
  Bh = 'BH',
  Bolivia = 'BOLIVIA',
  Botswana = 'BOTSWANA',
  Brazil = 'BRAZIL',
  BritishColumbia = 'BRITISH_COLUMBIA',
  Bulgaria = 'BULGARIA',
  Ca = 'CA',
  California = 'CALIFORNIA',
  Cambodia = 'CAMBODIA',
  Cameroon = 'CAMEROON',
  CanaryIslands = 'CANARY_ISLANDS',
  Caribbean = 'CARIBBEAN',
  Carribean = 'CARRIBEAN',
  ChannelIslands = 'CHANNEL_ISLANDS',
  Chile = 'CHILE',
  China = 'CHINA',
  Cn = 'CN',
  Co = 'CO',
  Colombia = 'COLOMBIA',
  Colorado = 'COLORADO',
  Combodia = 'COMBODIA',
  Connecticut = 'CONNECTICUT',
  CookIsland = 'COOK_ISLAND',
  CostaRica = 'COSTA_RICA',
  Crete = 'CRETE',
  Croatia = 'CROATIA',
  Cur = 'CUR',
  CuracaoCountry = 'CURACAO_COUNTRY',
  Cyprus = 'CYPRUS',
  CzechRepublic = 'CZECH_REPUBLIC',
  De = 'DE',
  Delaware = 'DELAWARE',
  Denmark = 'DENMARK',
  DistrictOfColumbia = 'DISTRICT_OF_COLUMBIA',
  Do = 'DO',
  Do_12 = 'DO_12',
  Ecuador = 'ECUADOR',
  Egpyt = 'EGPYT',
  Egypt = 'EGYPT',
  ElSalvador = 'EL_SALVADOR',
  En = 'EN',
  England = 'ENGLAND',
  Essonne = 'ESSONNE',
  Estonia = 'ESTONIA',
  Ethiopia = 'ETHIOPIA',
  FaroeIslands = 'FAROE_ISLANDS',
  Fiji = 'FIJI',
  FijiIslands = 'FIJI_ISLANDS',
  Finland = 'FINLAND',
  Florida = 'FLORIDA',
  Fr = 'FR',
  France = 'FRANCE',
  FrenchGuiana = 'FRENCH_GUIANA',
  FrenchGuyana = 'FRENCH_GUYANA',
  Fujian = 'FUJIAN',
  Gb = 'GB',
  Georgia = 'GEORGIA',
  Ger = 'GER',
  Germany = 'GERMANY',
  Ghana = 'GHANA',
  Gn = 'GN',
  GreatBritain = 'GREAT_BRITAIN',
  Greece = 'GREECE',
  Greenland = 'GREENLAND',
  Guadeloupe = 'GUADELOUPE',
  Guam = 'GUAM',
  Guatemala = 'GUATEMALA',
  Haiti = 'HAITI',
  HautsDeSeine = 'HAUTS_DE_SEINE',
  Hawaii = 'HAWAII',
  HimachalPradesh = 'HIMACHAL_PRADESH',
  Honduras = 'HONDURAS',
  HongKong = 'HONG_KONG',
  Hungary = 'HUNGARY',
  Iceland = 'ICELAND',
  Idaho = 'IDAHO',
  Il = 'IL',
  Illinois = 'ILLINOIS',
  India = 'INDIA',
  Indiana = 'INDIANA',
  Indonesia = 'INDONESIA',
  Iowa = 'IOWA',
  Iraq = 'IRAQ',
  Ireland = 'IRELAND',
  IsleOfMan = 'ISLE_OF_MAN',
  Israel = 'ISRAEL',
  It = 'IT',
  Italy = 'ITALY',
  Jalisco = 'JALISCO',
  Japan = 'JAPAN',
  JeollabukDo = 'JEOLLABUK_DO',
  Jordan = 'JORDAN',
  Kansas = 'KANSAS',
  Karnataka = 'KARNATAKA',
  Kazakhstan = 'KAZAKHSTAN',
  Kentucky = 'KENTUCKY',
  Kenya = 'KENYA',
  Kosovo = 'KOSOVO',
  Kuwait = 'KUWAIT',
  Laos = 'LAOS',
  Latvia = 'LATVIA',
  Lebanon = 'LEBANON',
  Lithuania = 'LITHUANIA',
  Louisiana = 'LOUISIANA',
  Lozere = 'LOZERE',
  Luxembourg = 'LUXEMBOURG',
  Ma = 'MA',
  Macedonia = 'MACEDONIA',
  Maine = 'MAINE',
  Malaysia = 'MALAYSIA',
  Maldives = 'MALDIVES',
  Malta = 'MALTA',
  Manitoba = 'MANITOBA',
  Maryland = 'MARYLAND',
  Massachusetts = 'MASSACHUSETTS',
  Mauritius = 'MAURITIUS',
  Mexico = 'MEXICO',
  Michigan = 'MICHIGAN',
  Minnesota = 'MINNESOTA',
  Mississippi = 'MISSISSIPPI',
  Missouri = 'MISSOURI',
  Moldova = 'MOLDOVA',
  Mongolia = 'MONGOLIA',
  Montana = 'MONTANA',
  Morocco = 'MOROCCO',
  Mx = 'MX',
  Myanmar = 'MYANMAR',
  Nebraska = 'NEBRASKA',
  Nepal = 'NEPAL',
  Netherlands = 'NETHERLANDS',
  Nevada = 'NEVADA',
  Newfoundland = 'NEWFOUNDLAND',
  NewBrunswick = 'NEW_BRUNSWICK',
  NewCaledonia = 'NEW_CALEDONIA',
  NewHampshire = 'NEW_HAMPSHIRE',
  NewJersey = 'NEW_JERSEY',
  NewMexico = 'NEW_MEXICO',
  NewSouthWales = 'NEW_SOUTH_WALES',
  NewYork = 'NEW_YORK',
  NewZealand = 'NEW_ZEALAND',
  Nicaragua = 'NICARAGUA',
  Nigeria = 'NIGERIA',
  Nl = 'NL',
  NoordHolland = 'NOORD_HOLLAND',
  NorthernIreland = 'NORTHERN_IRELAND',
  NorthernMarianaIslands = 'NORTHERN_MARIANA_ISLANDS',
  NorthernTerritory = 'NORTHERN_TERRITORY',
  NorthCarolina = 'NORTH_CAROLINA',
  NorthDakota = 'NORTH_DAKOTA',
  NorthIsland = 'NORTH_ISLAND',
  NorthRhineWestphalia = 'NORTH_RHINE_WESTPHALIA',
  Norway = 'NORWAY',
  NovaScotia = 'NOVA_SCOTIA',
  Ny = 'NY',
  Nyanza = 'NYANZA',
  Ohio = 'OHIO',
  Oklahoma = 'OKLAHOMA',
  Oman = 'OMAN',
  Ontario = 'ONTARIO',
  Oregon = 'OREGON',
  Pakistan = 'PAKISTAN',
  Panama = 'PANAMA',
  Paraguay = 'PARAGUAY',
  Pennsylvania = 'PENNSYLVANIA',
  Peru = 'PERU',
  Philippines = 'PHILIPPINES',
  Poland = 'POLAND',
  Portugal = 'PORTUGAL',
  PrinceEdwardIsland = 'PRINCE_EDWARD_ISLAND',
  PuertoRico = 'PUERTO_RICO',
  Punjab = 'PUNJAB',
  PyreneesAtlantiques = 'PYRENEES_ATLANTIQUES',
  Qatar = 'QATAR',
  Qld = 'QLD',
  Quebec = 'QUEBEC',
  Queensland = 'QUEENSLAND',
  RepublicOfKorea = 'REPUBLIC_OF_KOREA',
  RepublicOfMontenegro = 'REPUBLIC_OF_MONTENEGRO',
  RepublicOfSerbia = 'REPUBLIC_OF_SERBIA',
  RepulicOfKorea = 'REPULIC_OF_KOREA',
  ReunionFrench = 'REUNION_FRENCH',
  RhodeIsland = 'RHODE_ISLAND',
  Rhone = 'RHONE',
  Romania = 'ROMANIA',
  Russia = 'RUSSIA',
  SaintLucia = 'SAINT_LUCIA',
  SanMarinoRepublic = 'SAN_MARINO_REPUBLIC',
  Saskatchewan = 'SASKATCHEWAN',
  SaudiArabia = 'SAUDI_ARABIA',
  Scotland = 'SCOTLAND',
  Selangor = 'SELANGOR',
  Serbia = 'SERBIA',
  Seychelles = 'SEYCHELLES',
  SeychellesIsl = 'SEYCHELLES_ISL',
  Sg = 'SG',
  Shandong = 'SHANDONG',
  Singapore = 'SINGAPORE',
  Slovakia = 'SLOVAKIA',
  Slovenia = 'SLOVENIA',
  SouthAfrica = 'SOUTH_AFRICA',
  SouthAustralia = 'SOUTH_AUSTRALIA',
  SouthCarolina = 'SOUTH_CAROLINA',
  SouthDakota = 'SOUTH_DAKOTA',
  SouthIsland = 'SOUTH_ISLAND',
  Spain = 'SPAIN',
  SriLanka = 'SRI_LANKA',
  StateOfBahrain = 'STATE_OF_BAHRAIN',
  Suriname = 'SURINAME',
  Sweden = 'SWEDEN',
  Switzerland = 'SWITZERLAND',
  Taiwan = 'TAIWAN',
  Tamaulipas = 'TAMAULIPAS',
  Tanzania = 'TANZANIA',
  Tasmania = 'TASMANIA',
  Tennessee = 'TENNESSEE',
  TestState = 'TEST_STATE',
  Texas = 'TEXAS',
  Th = 'TH',
  Thailand = 'THAILAND',
  TheNetherlands = 'THE_NETHERLANDS',
  Tlaxcala = 'TLAXCALA',
  Tonga = 'TONGA',
  TrinidadAndTobago = 'TRINIDAD_AND_TOBAGO',
  Tt = 'TT',
  Tunisia = 'TUNISIA',
  Turkey = 'TURKEY',
  TurksAndCaicosIslands = 'TURKS_AND_CAICOS_ISLANDS',
  TyneAndWear = 'TYNE_AND_WEAR',
  Uganda = 'UGANDA',
  Ukraine = 'UKRAINE',
  UnitedArabEmirates = 'UNITED_ARAB_EMIRATES',
  Unknown = 'UNKNOWN',
  Uruguay = 'URUGUAY',
  Utah = 'UTAH',
  Va = 'VA',
  Venezuela = 'VENEZUELA',
  Vermont = 'VERMONT',
  Vic = 'VIC',
  Victoria = 'VICTORIA',
  Vietnam = 'VIETNAM',
  VietNam = 'VIET_NAM',
  Virginia = 'VIRGINIA',
  VirginIslands = 'VIRGIN_ISLANDS',
  Wa = 'WA',
  Wales = 'WALES',
  Washington = 'WASHINGTON',
  WesternAustralia = 'WESTERN_AUSTRALIA',
  WestVirginia = 'WEST_VIRGINIA',
  Wisconsin = 'WISCONSIN',
  Wyoming = 'WYOMING',
  Xx = 'XX',
  Yemen = 'YEMEN',
  YukonTerritory = 'YUKON_TERRITORY',
  Yvelines = 'YVELINES',
  Zambia = 'ZAMBIA',
  Zhejiang = 'ZHEJIANG',
  Zimbabwe = 'ZIMBABWE'
}

export type TableOverview = {
  __typename?: 'TableOverview';
  adr?: Maybe<Scalars['Float']>;
  brand_code?: Maybe<Scalars['BrandCode']>;
  compocc_py?: Maybe<Scalars['Float']>;
  current_rate?: Maybe<Scalars['Float']>;
  current_rate_ly?: Maybe<Scalars['Float']>;
  current_rate_ly_final?: Maybe<Scalars['Float']>;
  current_rate_ly_final_n?: Maybe<Scalars['Float']>;
  current_rate_ly_n?: Maybe<Scalars['Float']>;
  current_rate_n?: Maybe<Scalars['Float']>;
  /** Day of week of stay date. 0 (Sunday) to 6 (Saturday). */
  date_day?: Maybe<Scalars['String']>;
  /** Day of month for stay date */
  date_dom?: Maybe<Scalars['String']>;
  /** Month of stay date. */
  date_month?: Maybe<Scalars['String']>;
  /** Quarter of stay date */
  date_quarter?: Maybe<Scalars['String']>;
  /** Week of stay date */
  date_week?: Maybe<Scalars['String']>;
  /** Year and day of week, of stay date */
  date_ydw?: Maybe<Scalars['String']>;
  /** Year of stay date */
  date_year?: Maybe<Scalars['String']>;
  /** Year of stay date one year into the future */
  date_year_ny?: Maybe<Scalars['String']>;
  /** Year and month, of stay date */
  date_ym?: Maybe<Scalars['String']>;
  /** Year and month, of stay date one year into the future */
  date_ym_ny?: Maybe<Scalars['String']>;
  /** Year and quarter, of stay date */
  date_yq?: Maybe<Scalars['String']>;
  /** Year and quarter, of stay date one year into the future */
  date_yq_ny?: Maybe<Scalars['String']>;
  /** Year and week, of stay date */
  date_yw?: Maybe<Scalars['String']>;
  /** Year and week, of stay date one year into the future */
  date_yw_ny?: Maybe<Scalars['String']>;
  delta_1day?: Maybe<Scalars['Int']>;
  delta_3day?: Maybe<Scalars['Int']>;
  delta_7day?: Maybe<Scalars['Int']>;
  dow?: Maybe<Scalars['String']>;
  events_ly?: Maybe<DailyEventDetails>;
  events_ty?: Maybe<DailyEventDetails>;
  group_adr?: Maybe<Scalars['Float']>;
  group_adr_ly_final?: Maybe<Scalars['Float']>;
  group_adr_ly_final_n?: Maybe<Scalars['Float']>;
  group_adr_ly_n?: Maybe<Scalars['Float']>;
  group_blocked?: Maybe<Scalars['Int']>;
  group_pu_1day?: Maybe<Scalars['Int']>;
  group_pu_2day?: Maybe<Scalars['Int']>;
  group_pu_3day?: Maybe<Scalars['Int']>;
  group_pu_4day?: Maybe<Scalars['Int']>;
  group_pu_5day?: Maybe<Scalars['Int']>;
  group_pu_6day?: Maybe<Scalars['Int']>;
  group_pu_7day?: Maybe<Scalars['Int']>;
  group_pu_14day?: Maybe<Scalars['Int']>;
  group_pu_30day?: Maybe<Scalars['Int']>;
  group_revenue?: Maybe<Scalars['Float']>;
  group_revenue_ly_final?: Maybe<Scalars['Float']>;
  group_revenue_ly_final_n?: Maybe<Scalars['Float']>;
  group_revenue_ly_n?: Maybe<Scalars['Float']>;
  group_revpar?: Maybe<Scalars['Float']>;
  group_sold?: Maybe<Scalars['Int']>;
  group_sold_ly?: Maybe<Scalars['Int']>;
  group_sold_ly_final?: Maybe<Scalars['Int']>;
  group_sold_ly_final_n?: Maybe<Scalars['Int']>;
  group_sold_ly_n?: Maybe<Scalars['Int']>;
  market_rate?: Maybe<Scalars['Float']>;
  occ?: Maybe<Scalars['Float']>;
  ooo?: Maybe<Scalars['Int']>;
  recommended_rate?: Maybe<Scalars['Float']>;
  remaining?: Maybe<Scalars['Int']>;
  revenue?: Maybe<Scalars['Float']>;
  rooms_committed?: Maybe<Scalars['Int']>;
  rt_rooms_committed?: Maybe<Scalars['Int']>;
  sold?: Maybe<Scalars['Int']>;
  sold_ly?: Maybe<Scalars['Int']>;
  sold_ly_final?: Maybe<Scalars['Int']>;
  sold_ly_final_n?: Maybe<Scalars['Int']>;
  sold_ly_n?: Maybe<Scalars['Int']>;
  sold_n?: Maybe<Scalars['Int']>;
  stay_date?: Maybe<Scalars['Date']>;
  /** Same date one year into the future */
  stay_date_ny?: Maybe<Scalars['Date']>;
  transient_adr?: Maybe<Scalars['Float']>;
  transient_l7_bkg_days?: Maybe<Scalars['Float']>;
  transient_revenue?: Maybe<Scalars['Float']>;
  transient_sold?: Maybe<Scalars['Float']>;
  transient_sold_ly?: Maybe<Scalars['Float']>;
  transient_sold_ly_final?: Maybe<Scalars['Float']>;
  transient_sold_ly_final_n?: Maybe<Scalars['Float']>;
  transient_sold_ly_n?: Maybe<Scalars['Float']>;
  var_adr_ly?: Maybe<Scalars['Float']>;
  var_adr_ly_final?: Maybe<Scalars['Float']>;
  var_adr_ly_final_n?: Maybe<Scalars['Float']>;
  var_adr_ly_n?: Maybe<Scalars['Float']>;
  var_revenue_ly?: Maybe<Scalars['Float']>;
  var_revenue_ly_final?: Maybe<Scalars['Float']>;
  var_revenue_ly_final_n?: Maybe<Scalars['Float']>;
  var_revenue_ly_n?: Maybe<Scalars['Float']>;
  var_sold_ly?: Maybe<Scalars['Int']>;
  var_sold_ly_final?: Maybe<Scalars['Int']>;
  var_sold_ly_final_n?: Maybe<Scalars['Int']>;
  var_sold_ly_n?: Maybe<Scalars['Int']>;
};


export type TableOverviewDate_Year_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


export type TableOverviewDate_Ym_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


export type TableOverviewDate_Yq_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


export type TableOverviewDate_Yw_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};


export type TableOverviewStay_Date_NyArgs = {
  days?: InputMaybe<Scalars['Int']>;
};

export type TableOverviewArgs = {
  brandCode?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  hotelId?: InputMaybe<Scalars['Int']>;
  snapshotDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
  yearsCompare?: InputMaybe<Scalars['Int']>;
};

export type TeamMember = {
  __typename?: 'TeamMember';
  active?: Maybe<TeamMemberActive>;
  activeChanged?: Maybe<Scalars['String']>;
  brandCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  createdById?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailPref?: Maybe<MeetingEmail>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export enum TeamMemberActive {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type Timeline = {
  __typename?: 'Timeline';
  endTime?: Maybe<Scalars['String']>;
  intervals?: Maybe<Array<Maybe<TimelineInterval>>>;
  startTime?: Maybe<Scalars['String']>;
  timestep?: Maybe<Scalars['String']>;
};

export type TimelineInterval = {
  __typename?: 'TimelineInterval';
  startTime?: Maybe<Scalars['String']>;
  values?: Maybe<TimelineValue>;
};

export type TimelineValue = {
  __typename?: 'TimelineValue';
  temperature?: Maybe<Scalars['Float']>;
};

export type Tracking = {
  __typename?: 'Tracking';
  action?: Maybe<Scalars['String']>;
  agendaItems?: Maybe<Array<Maybe<Scalars['String']>>>;
  app?: Maybe<Scalars['String']>;
  attendees?: Maybe<Array<Maybe<Scalars['String']>>>;
  brandCode?: Maybe<Scalars['BrandCode']>;
  colLock?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
  dateAdded?: Maybe<Scalars['Date']>;
  dateSegment?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['Date']>;
  event?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  ipAddr?: Maybe<Scalars['String']>;
  meetingCategory?: Maybe<Scalars['String']>;
  meetingDate?: Maybe<Scalars['Date']>;
  meetingId?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['String']>;
  snapshotDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
  updatedAt?: Maybe<Scalars['String']>;
  userEmail?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  userRole?: Maybe<Scalars['String']>;
  yearsCompare?: Maybe<Scalars['Int']>;
};

export type TrackingInput = {
  action?: InputMaybe<Scalars['String']>;
  agendaItems?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  app?: InputMaybe<Scalars['String']>;
  attendees?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  brandCode?: InputMaybe<Scalars['String']>;
  colLock?: InputMaybe<Scalars['Int']>;
  dateAdded?: InputMaybe<Scalars['String']>;
  dateSegment?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  event?: InputMaybe<Scalars['String']>;
  ipAddr?: InputMaybe<Scalars['String']>;
  meetingCategory?: InputMaybe<Scalars['String']>;
  meetingDate?: InputMaybe<Scalars['String']>;
  meetingId?: InputMaybe<Scalars['String']>;
  month?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['String']>;
  snapshotDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
  userEmail: Scalars['String'];
  userName?: InputMaybe<Scalars['String']>;
  userRole?: InputMaybe<Scalars['String']>;
  yearsCompare?: InputMaybe<Scalars['Int']>;
};

export type UpdateEventInput = {
  /** The ID of the user who created this event. */
  created_by_id?: InputMaybe<Scalars['String']>;
  /** Additional details about the event. */
  details?: InputMaybe<Scalars['String']>;
  /** The date the event ends. */
  end_date?: InputMaybe<Scalars['String']>;
  /** The ID of the hotel this event belongs to. */
  hotel_id?: InputMaybe<Scalars['Int']>;
  /** The name of the event. */
  name?: InputMaybe<Scalars['String']>;
  /** The date the event starts. */
  start_date?: InputMaybe<Scalars['String']>;
};

export type UpdateFeatureFlagInput = {
  active?: InputMaybe<Scalars['Boolean']>;
  default_value?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  key?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['String']>;
};

export type UpdateForecastInput = {
  /** The forecasted ADR for the stay date. Option 1. */
  forecast_adr_1?: InputMaybe<Scalars['Float']>;
  /** The forecasted ADR for the stay date. Option 2. */
  forecast_adr_2?: InputMaybe<Scalars['Float']>;
  /** The forecasted sold for the stay date. Option 1. */
  forecast_sold_1?: InputMaybe<Scalars['Int']>;
  /** The forecasted sold for the stay date. Option 2. */
  forecast_sold_2?: InputMaybe<Scalars['Int']>;
};

export type UpdateHotelInput = {
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  brand_code: Scalars['String'];
  chain_code?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Country>;
  country_code?: InputMaybe<Scalars['String']>;
  descriptor?: InputMaybe<BrandDescriptor>;
  international_domestic_ind?: InputMaybe<Scalars['String']>;
  is_prm?: InputMaybe<Scalars['Boolean']>;
  latlong_x_position?: InputMaybe<Scalars['Longitude']>;
  latlong_y_position?: InputMaybe<Scalars['Latitude']>;
  name?: InputMaybe<Scalars['String']>;
  rm_serv_code?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<State>;
  use_forecast?: InputMaybe<Scalars['Boolean']>;
  zip_code?: InputMaybe<Scalars['String']>;
};

export type UpdateMonthlyBudgetInput = {
  /** The budgeted amount for the ADR */
  budget_adr?: InputMaybe<Scalars['Float']>;
  /** The budgeted amount for the revenue */
  budget_revenue?: InputMaybe<Scalars['Float']>;
  /** The budgeted amount for the sold */
  budget_sold?: InputMaybe<Scalars['Int']>;
  /** The first day of the month that the Monthly Budget applies to. */
  stay_date?: InputMaybe<Scalars['String']>;
};

export type UpdateNoteInput = {
  /** The content of the note. */
  content?: InputMaybe<Scalars['NoteScalar']>;
  /** The stay date associated with this note. */
  stay_date?: InputMaybe<Scalars['String']>;
};

export type UpdateOtaiSubscriptionInput = {
  /** Hotel's 5-digit brand ID number. Alias of resort_id */
  brand_code?: InputMaybe<Scalars['BrandCode']>;
  /** The name of the client */
  client_name?: InputMaybe<Scalars['String']>;
  /** List of competitor sets */
  comp_sets?: InputMaybe<Array<InputMaybe<CompSetInput>>>;
  /** List of competitors */
  competitors?: InputMaybe<Array<InputMaybe<CompetitorInput>>>;
  /** List of features */
  features?: InputMaybe<Array<InputMaybe<FeatureInput>>>;
  /** The OTAI hotel ID */
  otai_hotel_id?: InputMaybe<Scalars['String']>;
  /** The number of stars */
  stars?: InputMaybe<Scalars['Int']>;
  /** The OTAI subscription ID */
  sub_id?: InputMaybe<Scalars['String']>;
};

export type UpdateRmRecInput = {
  /** The notes that the RM suggests */
  notes?: InputMaybe<Scalars['String']>;
  /** The rate that the RM recommends */
  rate?: InputMaybe<Scalars['Float']>;
  /** The status of the RM recommendation */
  status?: InputMaybe<RmRecStatus>;
  /** The arrival date for the RM Rec */
  stay_date?: InputMaybe<Scalars['String']>;
};

export type UpdateRmReportInput = {
  /** The data file for this RM report. */
  data_file?: InputMaybe<Scalars['String']>;
  /** The database ID of the hotel this RM Report belongs to. */
  hotel_id?: InputMaybe<Scalars['Int']>;
  /** The notes for this RM report. */
  notes?: InputMaybe<NoteContentInput>;
  /** The report date for this RM report. */
  report_date?: InputMaybe<Scalars['String']>;
  /** The snapshot date associated with this RM Report. */
  snapshot_date?: InputMaybe<Scalars['String']>;
};

export type UpdateUserInput = {
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  okta_uid?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<UserRole>;
  time_zone?: InputMaybe<Scalars['String']>;
};

export type UploadRatesInput = {
  /** The hotel's current rate for that day */
  current_rate?: InputMaybe<Scalars['Float']>;
  /** The database ID of the RM Rec */
  id: Scalars['String'];
};

export type UpsertRmRecInput = {
  /** The ID of the user who created this RM Rec. */
  created_by_id?: InputMaybe<Scalars['String']>;
  /** The ID of the hotel this RM Rec belongs to. */
  hotel_id?: InputMaybe<Scalars['Int']>;
  /** The database ID of the RM Rec */
  id?: InputMaybe<Scalars['String']>;
  /** The notes that the RM suggests */
  notes?: InputMaybe<Scalars['String']>;
  /** The rate that the RM recommends */
  rate?: InputMaybe<Scalars['Float']>;
  /** The status of the RM recommendation */
  status?: InputMaybe<RmRecStatus>;
  /** The arrival date for the RM Rec */
  stay_date: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  /** DateTime when the user was created */
  created_at?: Maybe<Scalars['String']>;
  /** DateTime when user signed in current session */
  current_sign_in_at?: Maybe<Scalars['String']>;
  /** User email */
  email?: Maybe<Scalars['String']>;
  /** Database ID */
  id?: Maybe<Scalars['String']>;
  /** DateTime when user signed in last time */
  last_sign_in_at?: Maybe<Scalars['String']>;
  /** User name */
  name?: Maybe<Scalars['String']>;
  /** Okta User ID */
  okta_uid?: Maybe<Scalars['String']>;
  /** User's Portfolio of hotels */
  portfolio?: Maybe<Portfolio>;
  /** User role */
  role?: Maybe<UserRole>;
  /** User time zone */
  time_zone?: Maybe<Scalars['String']>;
  /** User uid */
  uid?: Maybe<Scalars['String']>;
  /** DateTime when user was updated */
  updated_at?: Maybe<Scalars['String']>;
};

export type UserLookup = {
  lookupColumn: UserLookupType;
  lookupValue: Scalars['String'];
};

export enum UserLookupType {
  Email = 'EMAIL',
  Id = 'ID',
  OktaUid = 'OKTA_UID',
  Uid = 'UID'
}

export type UserOption = {
  __typename?: 'UserOption';
  appNotes?: Maybe<Scalars['String']>;
  brandCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  headers?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  overviewSettings?: Maybe<Scalars['String']>;
  shopParams?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export enum UserRole {
  Admin = 'ADMIN',
  Manager = 'MANAGER',
  Rm = 'RM'
}
