import * as DateHelpers from '../helpers/dateHelpers';

import { Forecast, Hotel, MonthlyBudget, RmData } from '../graphql/types';
import { useEffect, useState } from 'react';

import { CurrencyCell } from '../components/TableCells';
import { FixMeLater } from '@/types/FixMeLaterType';
import { SoldCell } from '../components/TableCells';
import _ from 'lodash';
import clsx from 'clsx';
import dayjs from 'dayjs';
import numbro from 'numbro';
import { useShowForecast } from '../hooks/useShowForecast';

type MonthlyPerformanceProps = {
  data?: RmData[];
  forecast?: Forecast[];
  hotel?: Hotel;
  budget?: MonthlyBudget[];
};

type MonthlyPerformanceData = {
  stay_date: string;
  adr: number;
  adr_ly: number | null;
  adr_ly_final: number | null;
  revenue: number;
  revenue_ly: number | null;
  revenue_ly_final: number | null;
  revpar: number;
  revpar_ly: number | null;
  revpar_ly_final: number | null;
  sold: number;
  sold_ly: number | null;
  sold_ly_final: number | null;
  date_month: string;
  forecast_adr_1: number | null;
  forecast_adr_2: number | null;
  forecast_revenue_1: number | null;
  forecast_revenue_2: number | null;
  forecast_revpar_1: number | null;
  forecast_revpar_2: number | null;
  forecast_sold_1: number | null;
  forecast_sold_2: number | null;
  month?: number;
  year?: number;
  budget_adr?: number;
  budget_revenue?: number;
  budget_revpar?: number;
  budget_sold?: number;
};

type AggregatedData = MonthlyPerformanceData & {
  total_capacity: number;
  total_capacity_ly: number;
  total_capacity_ly_final: number;
};

function prepForNumbro(value: number | null, thousandSeparated = true): string {
  if (
    isNaN(value as number) ||
    value === null ||
    value === undefined ||
    value === 0
  ) {
    return '';
  } else {
    return numbro(value).format({
      thousandSeparated,
    });
  }
}

function calculateMonthlyTotals(
  acc: FixMeLater,
  curr: FixMeLater,
  includeForecast = false
) {
  const base = {
    adr: (acc.revenue! + curr.revenue!) / (acc.sold! + curr.sold!),
    adr_ly:
      (acc.revenue_ly! + curr.revenue_ly!) / (acc.sold_ly! + curr.sold_ly!),
    adr_ly_final:
      (acc.revenue_ly_final! + curr.revenue_ly_final!) /
      (acc.sold_ly_final! + curr.sold_ly_final!),
    revenue: acc.revenue! + curr.revenue!,
    revenue_ly: acc.revenue_ly! + curr.revenue_ly!,
    revenue_ly_final: acc.revenue_ly_final! + curr.revenue_ly_final!,
    revpar:
      (acc.revenue! + curr.revenue!) /
      (acc.total_capacity! + curr.total_capacity!),
    revpar_ly:
      (acc.revenue_ly! + curr.revenue_ly!) /
      (acc.total_capacity_ly! + curr.total_capacity_ly!),
    revpar_ly_final:
      (acc.revenue_ly_final! + curr.revenue_ly_final!) /
      (acc.total_capacity_ly_final! + curr.total_capacity_ly_final!),
    sold: acc.sold! + curr.sold!,
    sold_ly: acc.sold_ly! + curr.sold_ly!,
    sold_ly_final: acc.sold_ly_final! + curr.sold_ly_final!,
    total_capacity: acc.total_capacity! + curr.total_capacity!,
    total_capacity_ly: acc.total_capacity_ly! + curr.total_capacity_ly!,
    total_capacity_ly_final:
      acc.total_capacity_ly_final! + curr.total_capacity_ly_final!,
  };

  if (!includeForecast) return base;

  return {
    ...base,
    forecast_adr_1:
      (acc.forecast_revenue_1! + curr.forecast_revenue_1!) /
      (acc.forecast_sold_1! + curr.forecast_sold_1!),
    forecast_adr_2:
      ((acc.forecast_revenue_2 || 0) + (curr.forecast_revenue_2 || 0)) /
      ((acc.forecast_sold_2 || 0) + (curr.forecast_sold_2 || 0)),
    forecast_revpar_1:
      (acc.forecast_revenue_1! + curr.forecast_revenue_1!) /
      (acc.total_capacity! + curr.total_capacity!),
    forecast_revpar_2:
      ((acc.forecast_revenue_2 || 0) + (curr.forecast_revenue_2 || 0)) /
      ((acc.total_capacity || 0) + (curr.total_capacity || 0)),
    forecast_revenue_1: acc.forecast_revenue_1! + curr.forecast_revenue_1!,
    forecast_revenue_2:
      (acc.forecast_revenue_2 || 0) + (curr.forecast_revenue_2 || 0),
    forecast_sold_1: acc.forecast_sold_1! + curr.forecast_sold_1!,
    forecast_sold_2: (acc.forecast_sold_2 || 0) + (curr.forecast_sold_2 || 0),
  };
}

function calculateGrandTotals(
  acc: AggregatedData,
  curr: AggregatedData,
  includeForecast = false
) {
  const base = {
    adr: (acc.revenue + curr.revenue) / (acc.sold + curr.sold),
    adr_ly:
      ((acc.revenue_ly ?? 0) + (curr.revenue_ly ?? 0)) /
      ((acc.sold_ly ?? 0) + (curr.sold_ly ?? 0)),
    adr_ly_final:
      ((acc.revenue_ly_final ?? 0) + (curr.revenue_ly_final ?? 0)) /
      ((acc.sold_ly_final ?? 0) + (curr.sold_ly_final ?? 0)),
    revenue: acc.revenue + curr.revenue,
    revenue_ly: (acc.revenue_ly ?? 0) + (curr.revenue_ly ?? 0),
    revenue_ly_final:
      (acc.revenue_ly_final ?? 0) + (curr.revenue_ly_final ?? 0),
    revpar:
      (acc.revenue + curr.revenue) / (acc.total_capacity + curr.total_capacity),
    revpar_ly:
      ((acc.revenue_ly ?? 0) + (curr.revenue_ly ?? 0)) /
      (acc.total_capacity_ly + curr.total_capacity_ly),
    revpar_ly_final:
      ((acc.revenue_ly_final ?? 0) + (curr.revenue_ly_final ?? 0)) /
      (acc.total_capacity_ly_final + curr.total_capacity_ly_final),
    sold: acc.sold + curr.sold,
    sold_ly: (acc.sold_ly ?? 0) + (curr.sold_ly ?? 0),
    sold_ly_final: (acc.sold_ly_final ?? 0) + (curr.sold_ly_final ?? 0),
    budget_sold: (acc.budget_sold || 0) + (curr.budget_sold || 0),
    budget_adr:
      ((acc.budget_revenue || 0) + (curr.budget_revenue || 0)) /
      ((acc.budget_sold || 0) + (curr.budget_sold || 0)),
    budget_revpar:
      ((acc.budget_revenue || 0) + (curr.budget_revenue || 0)) /
      ((acc.total_capacity || 0) + (curr.total_capacity || 0)),
    budget_revenue: (acc.budget_revenue || 0) + (curr.budget_revenue || 0),
    total_capacity: acc.total_capacity! + curr.total_capacity!,
    total_capacity_ly: acc.total_capacity_ly! + curr.total_capacity_ly!,
    total_capacity_ly_final:
      acc.total_capacity_ly_final! + curr.total_capacity_ly_final!,
  };

  if (!includeForecast) return base;

  return {
    ...base,
    forecast_adr_1:
      ((acc.forecast_revenue_1 || acc.revenue) +
        (curr.forecast_revenue_1 || curr.revenue)) /
      ((acc.forecast_sold_1 || acc.sold) + (curr.forecast_sold_1 || curr.sold)),
    forecast_adr_2:
      ((acc.forecast_revenue_2 || 0) + (curr.forecast_revenue_2 || 0)) /
      ((acc.forecast_sold_2 || 0) + (curr.forecast_sold_2 || 0)),
    forecast_revpar_1:
      ((acc.forecast_revenue_1 || acc.revenue) +
        (curr.forecast_revenue_1 || curr.revenue)) /
      (acc.total_capacity || acc.total_capacity),
    forecast_revpar_2:
      ((acc.forecast_revenue_2 || acc.revenue) +
        (curr.forecast_revenue_2 || curr.revenue)) /
      (acc.total_capacity || acc.total_capacity),
    forecast_revenue_1:
      (acc.forecast_revenue_1 || acc.revenue) +
      (curr.forecast_revenue_1 || curr.revenue),
    forecast_revenue_2:
      (acc.forecast_revenue_2 || 0) + (curr.forecast_revenue_2 || 0),
    forecast_sold_1:
      (acc.forecast_sold_1 || acc.sold) + (curr.forecast_sold_1 || curr.sold),
    forecast_sold_2: (acc.forecast_sold_2 || 0) + (curr.forecast_sold_2 || 0),
  };
}

function processMonthlyData(
  data: RmData[],
  forecast: Forecast[] | undefined,
  budget: MonthlyBudget[] | undefined,
  showForecast: boolean
) {
  const mergedData =
    showForecast && forecast
      ? data.map((d) => ({
          ...d,
          ...forecast.find((f) => f?.stay_date === d?.stay_date),
        }))
      : data;

  const groupedData = _.groupBy(mergedData, 'date_month');
  // @ts-ignore
  const sortedGroupData = _.orderBy(groupedData, (d) => d[0].stay_date);

  const tableMap = new Map();

  sortedGroupData.forEach((value) => {
    const monthTotal = value.reduce((acc, curr) =>
      calculateMonthlyTotals(acc, curr, showForecast)
    );
    // @ts-ignore
    const key = value[0].date_month;
    const budgetData = budget?.find(
      (b) => key && parseInt(DateHelpers.formatDate(key, 'M')) === b?.month
    );

    // @ts-ignore
    const month = dayjs(value[0].stay_date).format('MMM YYYY');
    tableMap.set(month, {
      // @ts-ignore
      ...monthTotal,
      ...budgetData,
      // @ts-ignore
      budget_revpar: monthTotal.total_capacity
        ? // @ts-ignore
          (budgetData?.budget_revenue ?? 0) / monthTotal.total_capacity
        : 0,
    });
  });

  const grandTotal = Array.from(tableMap)
    .map(([, value]) => value)
    .reduce((acc, curr) => calculateGrandTotals(acc, curr, showForecast));

  tableMap.set('Total', grandTotal);
  return tableMap;
}

function calculateMonthlyData(
  data: RmData[] | undefined,
  forecast: Forecast[] | undefined,
  budget: MonthlyBudget[] | undefined,
  showForecast: boolean
): Map<string, MonthlyPerformanceData> | undefined {
  if (!data) return undefined;
  return processMonthlyData(data, forecast, budget, showForecast);
}

function renderActualRow(
  key: string,
  data: MonthlyPerformanceData,
  showForecast: boolean
): JSX.Element {
  return (
    <tr
      key={key}
      className={clsx(
        'whitespace-nowrap text-sm border-2',
        'hover:bg-slate-200',
        key === 'Total' && 'font-semibold'
      )}
    >
      <td className='text-center border-r-2 pr-1.5 py-1 font-semibold'>
        {key}
      </td>
      <td className='text-center'>
        {numbro(data.sold).format({
          thousandSeparated: true,
        })}
      </td>
      <CurrencyCell value={data.adr} metric='adr' />
      <CurrencyCell value={data.revpar} metric='revpar' />
      <CurrencyCell
        value={data.revenue}
        metric='revenue'
        style={['border-r-2', 'pr-1.5']}
      />
      <td className='text-center'>{prepForNumbro(data.sold_ly)}</td>
      <CurrencyCell value={data.adr_ly} metric='adr' />
      <CurrencyCell value={data.revpar_ly} metric='revpar' />
      <CurrencyCell
        value={data.revenue_ly}
        metric='revenue'
        style={['border-r-2', 'pr-1.5']}
      />
      <td className='text-center'>{prepForNumbro(data.sold_ly_final)}</td>
      <CurrencyCell value={data.adr_ly_final} metric='adr' />
      <CurrencyCell value={data.revpar_ly_final} metric='revpar' />
      <CurrencyCell
        value={data.revenue_ly_final}
        metric='revenue'
        style={['border-r-2', 'pr-1.5']}
      />
      {showForecast ? (
        <>
          <td className='text-center'>{prepForNumbro(data.forecast_sold_1)}</td>
          <CurrencyCell value={data.forecast_adr_1} metric='adr' />
          <CurrencyCell value={data.forecast_revpar_1} metric='revpar' />
          <CurrencyCell
            value={data.forecast_revenue_1 || ''}
            metric='revenue'
            style={['border-r-2', 'pr-1.5']}
          />
          <td className='text-center'>{prepForNumbro(data.forecast_sold_2)}</td>
          <CurrencyCell value={data.forecast_adr_2 || ''} metric='adr' />
          <CurrencyCell value={data.forecast_revpar_2 || ''} metric='revpar' />
          <CurrencyCell
            value={data.forecast_revenue_2 || ''}
            metric='revenue'
            style={['border-r-2', 'pr-1.5']}
          />
        </>
      ) : null}
      <td className='text-center'>{data.budget_sold}</td>
      <CurrencyCell value={data.budget_adr} metric='adr' />
      <CurrencyCell value={data.budget_revpar} metric='revpar' />
      <CurrencyCell
        value={data.budget_revenue}
        metric='revenue'
        style={['border-r-2', 'pr-1.5']}
      />
    </tr>
  );
}

function renderVarianceRow(
  key: string,
  data: MonthlyPerformanceData,
  showForecast: boolean
): JSX.Element {
  return (
    <tr
      key={key}
      className={clsx(
        'whitespace-nowrap text-sm border-2',
        'hover:bg-slate-200',
        key === 'Total' && 'font-semibold'
      )}
    >
      <td className='text-center pr-1.5 py-1 font-semibold'>{key}</td>
      <td></td>
      <td></td>
      <td></td>
      <td className='border-r-2'></td>
      <SoldCell value={data.sold - (data.sold_ly || 0)} />
      <CurrencyCell
        value={data.adr - (data.adr_ly || 0)}
        metric='adr'
        style={['border-r-2', 'pr-1.5']}
      />
      <CurrencyCell
        value={data.revpar - (data.revpar_ly || 0)}
        metric='revpar'
        style={['border-r-2', 'pr-1.5']}
      />
      <CurrencyCell
        value={data.revenue - (data.revenue_ly || 0)}
        metric='revenue'
        style={['border-r-2', 'pr-1.5']}
      />
      <SoldCell value={data.sold - (data.sold_ly_final || 0)} />
      <CurrencyCell
        value={data.adr - (data.adr_ly_final || 0)}
        metric='adr'
        style={['border-r-2', 'pr-1.5']}
      />
      <CurrencyCell
        value={data.revpar - (data.revpar_ly_final || 0)}
        metric='revpar'
        style={['border-r-2', 'pr-1.5']}
      />
      <CurrencyCell
        value={data.revenue - (data.revenue_ly_final || 0)}
        metric='revenue'
        style={['border-r-2', 'pr-1.5']}
      />
      {showForecast ? (
        <>
          <SoldCell
            value={data.forecast_sold_1 ? data.sold - data.forecast_sold_1 : ''}
          />
          <CurrencyCell
            value={data.forecast_adr_1 ? data.adr - data.forecast_adr_1 : ''}
            metric='adr'
            style={['border-r-2', 'pr-1.5']}
          />
          <CurrencyCell
            value={
              data.forecast_revpar_1 ? data.revpar - data.forecast_revpar_1 : ''
            }
            metric='revpar'
            style={['border-r-2', 'pr-1.5']}
          />
          <CurrencyCell
            value={
              data.forecast_revenue_1
                ? data.revenue - data.forecast_revenue_1
                : ''
            }
            metric='revenue'
            style={['border-r-2', 'pr-1.5']}
          />
          <SoldCell
            value={
              data.forecast_sold_2
                ? numbro(data.sold - data.forecast_sold_2).format({
                    thousandSeparated: true,
                  })
                : ''
            }
          />
          <CurrencyCell
            value={data.forecast_adr_2 ? data.adr - data.forecast_adr_2 : ''}
            metric='adr'
          />
          <CurrencyCell
            value={
              data.forecast_revpar_2 ? data.revpar - data.forecast_revpar_2 : ''
            }
            metric='revpar'
          />
          <CurrencyCell
            value={
              data.forecast_revenue_2
                ? data.revenue - data.forecast_revenue_2
                : ''
            }
            metric='revenue'
            style={['border-r-2', 'pr-1.5']}
          />
        </>
      ) : null}
      <SoldCell value={data.budget_sold ? data.sold - data.budget_sold : ''} />
      <CurrencyCell
        value={data.budget_adr ? data.adr - data.budget_adr : ''}
        metric='adr'
      />
      <CurrencyCell
        value={data.budget_revpar ? data.revpar - data.budget_revpar : ''}
        metric='revpar'
      />
      <CurrencyCell
        value={data.budget_revenue ? data.revenue - data.budget_revenue : ''}
        metric='revenue'
      />
    </tr>
  );
}

export default function MonthlyPerformance({
  data,
  forecast,
  hotel,
  budget,
}: MonthlyPerformanceProps) {
  const { showForecast } = useShowForecast(hotel);

  const [tableData, setTableData] =
    useState<Map<string, MonthlyPerformanceData>>();

  const headers = showForecast
    ? {
        top: [
          'Actual',
          'Same Time Last Year',
          'Last Year Final',
          'Forecast #1',
          'Forecast #2',
          'Budget',
        ],
        bottom: [
          'Date',
          'Sold',
          'ADR',
          'RevPAR',
          'Revenue',
          'Sold LY',
          'ADR LY',
          'RP LY',
          'Rev LY',
          'Sold LYF',
          'ADR LYF',
          'RP LYF',
          'Rev LYF',
          'F SLD',
          'F ADR',
          'F RP',
          'F REV',
          'F2 SLD',
          'F2 ADR',
          'F2 RP',
          'F2 REV',
          'BDG SLD',
          'BDG ADR',
          'BDG RP',
          'BDG REV',
        ],
      }
    : {
        top: ['Actual', 'Same Time Last Year', 'Last Year Final', 'Budget'],
        bottom: [
          'Date',
          'Sold',
          'ADR',
          'RevPAR',
          'Revenue',
          'Sold LY',
          'ADR LY',
          'RP LY',
          'Rev LY',
          'Sold LYF',
          'ADR LYF',
          'RP LYF',
          'Rev LYF',
          'BDG SLD',
          'BDG ADR',
          'BDG RP',
          'BDG REV',
        ],
      };

  useEffect(() => {
    const tableData = calculateMonthlyData(
      data,
      forecast,
      budget,
      showForecast
    );
    setTableData(tableData);
  }, [data, forecast, budget, showForecast]);

  return (
    <table className='border-collapse'>
      <thead>
        <tr className='border-b-2 whitespace-nowrap'>
          <th></th>
          {headers.top.map((header) => (
            <th
              key={header}
              colSpan={4}
              className='px-2 py-1 font-medium text-sm text-gray-900'
            >
              {header}
            </th>
          ))}
        </tr>
        <tr className='border-b-2'>
          {headers.bottom.map((header) => (
            <th
              key={header}
              className='px-2 py-1 whitespace-nowrap font-medium text-sm text-gray-900'
            >
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableData &&
          Array.from(tableData).map(([key, value]) =>
            renderActualRow(key, value, showForecast)
          )}
        <tr className='border-y-2'>
          <td className='font-bold pt-2'>Variances</td>
        </tr>
        {tableData &&
          Array.from(tableData).map(([key, value]) =>
            renderVarianceRow(key, value, showForecast)
          )}
      </tbody>
    </table>
  );
}
