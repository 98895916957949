import { MasterColumns } from '../types/Columns';

export const masterColumns: MasterColumns = {
  adr: {
    dataSource: 'rmData',
    header: 'ADR',
    tooltip: 'ADR',
    renderer: 'prm.rate',
    columnSize: 70,
  },
  adr_compare: {
    dataSource: 'rmData',
    header: 'ADR (C)',
    tooltip: 'ADR from Comparison Year',
    renderer: 'prm.rate',
  },
  adr_variance: {
    dataSource: 'rmData',
    header: 'ADR (V)',
    tooltip: 'Variance ADR to Comparison Year',
    renderer: 'prm.rate',
  },
  adr_ly: {
    dataSource: 'rmData',
    header: 'ADR LY',
    tooltip: 'ADR Same Time Last Year',
    renderer: 'prm.rate',
  },
  adr_ly2: {
    dataSource: 'rmData',
    header: 'ADR LY2',
    tooltip: 'ADR Same Time 2 Years Ago',
    renderer: 'prm.rate',
  },
  adr_ly2_final: {
    dataSource: 'rmData',
    header: 'ADR LYF2',
    tooltip: 'ADR Final 2 Years Ago',
    renderer: 'prm.rate',
  },
  adr_ly_final: {
    dataSource: 'rmData',
    header: 'ADR LYF',
    tooltip: 'ADR Final Last Year',
    renderer: 'prm.rate',
  },
  be_adr_ly_final: {
    dataSource: 'rmData',
    header: 'B-E ADR',
    tooltip: 'B-E ADR',
    renderer: 'prm.rate',
  },
  'budgets.budget_adr': {
    dataSource: 'rmData',
    header: 'BDG-ADR',
    tooltip: 'Budget ADR',
    renderer: 'prm.rate',
    readOnly: false,
    isDragDisabled: true,
  },
  'budgets.budget_revenue': {
    dataSource: 'rmData',
    header: 'BDG-REV',
    tooltip: 'Budget Revenue',
    renderer: 'prm.money',
    readOnly: false,
    isDragDisabled: true,
  },
  'budgets.budget_sold': {
    dataSource: 'rmData',
    header: 'BDG-SLD',
    tooltip: 'Budget Sold',
    readOnly: false,
    isDragDisabled: true,
  },
  capacity: {
    dataSource: 'rmData',
    header: 'Cap eOOO',
    tooltip: 'Capacity Excludes OOO',
  },
  capacity_ly: {
    dataSource: 'rmData',
    header: 'Cap eOOO LY',
    tooltip: 'Capacity Excludes OOO Same Time Last Year',
  },
  capacity_ly_final: {
    dataSource: 'rmData',
    header: 'Cap eOOO LYF',
    tooltip: 'Capacity Excludes OOO Final Last Year',
  },
  compadr_py: {
    dataSource: 'rmData',
    header: 'STR ADR',
    tooltip: 'Competitor ADR Last Year (STR)',
    renderer: 'prm.rate',
  },
  compocc_py: {
    dataSource: 'rmData',
    header: 'STR OCC',
    tooltip: 'Competitor OCC Last Year (STR)',
    renderer: 'prm.percent',
  },
  comprevpar_py: {
    dataSource: 'rmData',
    header: 'STR RP',
    tooltip: 'Competitor RevPAR Last Year (STR)',
    renderer: 'prm.rate',
  },
  current_rate: {
    dataSource: 'currentRateTable',
    header: 'Rate',
    columnSize: 75,
    tooltip: 'Current Rate',
    renderer: 'prm.rate',
  },
  current_rate_compare: {
    dataSource: 'currentRateTable',
    header: 'Rate (C)',
    columnSize: 75,
    tooltip: 'Current Rate from Comparison Year',
    renderer: 'prm.rate',
  },
  current_rate_variance: {
    dataSource: 'currentRateTable',
    header: 'Rate (V)',
    columnSize: 75,
    tooltip: 'Variance Current Rate to Comparison Year',
    renderer: 'prm.rate',
  },
  current_rate_ly: {
    dataSource: 'currentRateTable',
    header: 'Rate LY',
    columnSize: 75,
    tooltip: 'Current Rate Same Time Last Year',
    renderer: 'prm.rate',
  },
  current_rate_ly_n: {
    dataSource: 'currentRateTable',
    header: 'Rate LYn',
    columnSize: 75,
    tooltip: 'Current Rate Same Time n Years Ago',
    renderer: 'prm.rate',
  },
  current_rate_ly2_final: {
    dataSource: 'currentRateTable',
    header: 'Rate LYF2',
    columnSize: 75,
    tooltip: 'Current Rate Final 2 Years Ago',
    renderer: 'prm.rate',
  },
  current_rate_ly_final: {
    dataSource: 'currentRateTable',
    header: 'Rate LYF',
    columnSize: 75,
    tooltip: 'Current Rate Final Last Year',
    renderer: 'prm.rate',
  },
  current_rate_ly_final_n: {
    dataSource: 'currentRateTable',
    header: 'Rate LYFn',
    columnSize: 75,
    tooltip: 'Current Rate Final n Years Ago',
    renderer: 'prm.rate',
  },
  delta_1day: {
    dataSource: 'rmData',
    header: 'Δ1D',
    tooltip: 'Δ 1 Day Delta Sold',
    columnSize: 40,
  },
  delta_3day: {
    dataSource: 'rmData',
    header: 'Δ3D',
    tooltip: 'Δ 3 Day Delta Sold',
    columnSize: 40,
  },
  delta_7day: {
    dataSource: 'rmData',
    header: 'Δ7D',
    tooltip: 'Δ 7 Day Delta Sold',
    columnSize: 40,
  },
  dow: {
    dataSource: 'rmData',
    header: 'DOW',
    tooltip: 'Day of Week',
    columnSize: 45,
    renderer: 'prm.dow',
  },
  'event.delete': {
    dataSource: 'hotelEvents',
    columnSize: 45,
    header: 'Delete',
    tooltip: 'Delete Event',
    renderer: 'event.delete',
  },
  'event.details': {
    dataSource: 'hotelEvents',
    columnSize: 45,
    header: 'Details',
    tooltip: 'Event Details',
    renderer: 'prm.truncated',
  },
  'event.end_date': {
    dataSource: 'hotelEvents',
    columnSize: 45,
    header: 'End',
    tooltip: 'End Date',
    renderer: 'prm.date',
  },
  'event.name': {
    dataSource: 'hotelEvents',
    columnSize: 45,
    header: 'Name',
    tooltip: 'Event Name',
    renderer: 'prm.text',
  },
  'event.start_date': {
    dataSource: 'hotelEvents',
    columnSize: 45,
    header: 'Start',
    tooltip: 'Start Date',
    renderer: 'prm.date',
  },
  'events.events_ty.events.details': {
    dataSource: 'dailyEvents',
    columnSize: 45,
    header: 'Details',
    tooltip: 'Event Details',
    renderer: 'prm.truncated',
  },
  'events.events_ty.events.end_date': {
    dataSource: 'dailyEvents',
    columnSize: 45,
    header: 'End',
    tooltip: 'Event End Date',
    renderer: 'prm.date',
  },
  'events.events_ty.events.name': {
    dataSource: 'dailyEvents',
    columnSize: 45,
    header: 'Name',
    tooltip: 'Event Name',
    renderer: 'prm.base',
  },
  'events.events_ty.events.start_date': {
    dataSource: 'dailyEvents',
    columnSize: 45,
    header: 'Start',
    tooltip: 'Event Start Date',
    renderer: 'prm.date',
  },
  'events_ly.count': {
    dataSource: 'dailyEvents',
    columnSize: 40,
    header: 'ELY',
    tooltip: 'Events LY',
    renderer: 'prm.event',
  },
  'events_ty.count': {
    dataSource: 'dailyEvents',
    columnSize: 40,
    header: 'ETY',
    tooltip: 'Events TY',
    renderer: 'prm.event',
  },
  final_rate_ly2: {
    dataSource: 'rmData',
    header: 'OLD - Rate LYF2',
    tooltip: 'DO NOT USE - Final Rate 2 Years Ago',
    renderer: 'prm.rate',
  },
  forecast_adr_1: {
    columnSize: 70,
    dataSource: 'combinedHotelForecast',
    header: 'F ADR',
    tooltip: 'Forecast 1 ADR',
    renderer: 'prm.rate',
    readOnly: false,
  },
  forecast_adr_2: {
    columnSize: 70,
    dataSource: 'combinedHotelForecast',
    header: 'F2 ADR',
    tooltip: 'Forecast 2 ADR',
    renderer: 'prm.rate',
    readOnly: false,
  },
  forecast_revpar_1: {
    columnSize: 70,
    dataSource: 'combinedHotelForecast',
    header: 'F RP',
    tooltip: 'Forecast 1 RevPAR',
    renderer: 'prm.rate',
    readOnly: true,
  },
  forecast_revpar_2: {
    columnSize: 70,
    dataSource: 'combinedHotelForecast',
    header: 'F2 RP',
    tooltip: 'Forecast 2 RevPAR',
    renderer: 'prm.rate',
    readOnly: true,
  },
  forecast_occ_1: {
    dataSource: 'combinedHotelForecast',
    header: 'F OCC',
    tooltip: 'Forecast 1 Occ %',
    renderer: 'prm.percent',
    readOnly: false,
  },
  forecast_occ_2: {
    dataSource: 'combinedHotelForecast',
    header: 'F2 OCC',
    tooltip: 'Forecast 2 Occ %',
    renderer: 'prm.percent',
    readOnly: false,
  },
  forecast_revenue_1: {
    columnSize: 70,
    dataSource: 'combinedHotelForecast',
    header: 'F REV',
    tooltip: 'Forecast 1 Revenue',
    renderer: 'prm.money',
    readOnly: true,
  },
  forecast_revenue_2: {
    columnSize: 70,
    dataSource: 'combinedHotelForecast',
    header: 'F2 REV',
    tooltip: 'Forecast 2 Revenue',
    renderer: 'prm.money',
    readOnly: true,
  },
  forecast_sold_1: {
    columnSize: 60,
    dataSource: 'combinedHotelForecast',
    header: 'F SLD',
    tooltip: 'Forecast 1 Sold',
    renderer: 'prm.base',
    readOnly: false,
  },
  forecast_sold_2: {
    columnSize: 60,
    dataSource: 'combinedHotelForecast',
    header: 'F2 SLD',
    tooltip: 'Forecast 2 Sold',
    renderer: 'prm.base',
    readOnly: false,
  },
  group_adr: {
    dataSource: 'rmData',
    header: 'Grp ADR',
    tooltip: 'Group ADR',
    renderer: 'prm.rate',
  },
  group_adr_ly_final: {
    dataSource: 'rmData',
    header: 'GADR LYF',
    columnSize: 75,
    tooltip: 'Group ADR Final Last Year',
    renderer: 'prm.rate',
  },
  group_adr_ly_final_n: {
    dataSource: 'rmData',
    header: 'GADR LYF N',
    columnSize: 75,
    tooltip: 'Group ADR Final N Years Ago',
    renderer: 'prm.rate',
  },
  group_blocked: {
    dataSource: 'rmData',
    header: 'Grp Rem',
    tooltip: 'Group Block Remaining',
    renderer: 'prm.group_sold',
  },
  group_delta_intraday: {
    dataSource: 'liveData',
    header: 'Δ LV Grp',
    tooltip: 'Δ Live Group Delta Intraday',
    renderer: 'prm.group_sold',
  },
  group_pu_14day: {
    dataSource: 'rmData',
    header: 'Grp P/U 14D',
    tooltip: 'Group Pickup 14 Day',
    renderer: 'prm.group_sold',
  },
  group_pu_1day: {
    dataSource: 'rmData',
    header: 'Grp P/U 1D',
    tooltip: 'Group Pickup 1 Day',
    renderer: 'prm.group_sold',
  },
  group_pu_2day: {
    dataSource: 'rmData',
    header: 'Grp P/U 2D',
    tooltip: 'Group Pickup 2 Day',
    renderer: 'prm.group_sold',
  },
  group_pu_30day: {
    dataSource: 'rmData',
    header: 'Grp P/U 30D',
    tooltip: 'Group Pickup 30 Day',
    renderer: 'prm.group_sold',
  },
  group_pu_3day: {
    dataSource: 'rmData',
    header: 'Grp P/U 3D',
    tooltip: 'Group Pickup 3 Day',
    renderer: 'prm.group_sold',
  },
  group_pu_4day: {
    dataSource: 'rmData',
    header: 'Grp P/U 4D',
    tooltip: 'Group Pickup 4 Day',
    renderer: 'prm.group_sold',
  },
  group_pu_5day: {
    dataSource: 'rmData',
    header: 'Grp P/U 5D',
    tooltip: 'Group Pickup 5 Day',
    renderer: 'prm.group_sold',
  },
  group_pu_6day: {
    dataSource: 'rmData',
    header: 'Grp P/U 6D',
    tooltip: 'Group Pickup 6 Day',
    renderer: 'prm.group_sold',
  },
  group_pu_7day: {
    dataSource: 'rmData',
    header: 'Grp P/U 7D',
    tooltip: 'Group Pickup 7 Day',
  },
  group_revenue: {
    dataSource: 'rmData',
    header: 'Grp Rev',
    columnSize: 75,
    tooltip: 'Group Revenue',
    renderer: 'prm.money',
  },
  group_revenue_ly_n: {
    dataSource: 'rmData',
    header: 'Grp Rev LY N',
    columnSize: 75,
    tooltip: 'Group Revenue N Years Ago',
    renderer: 'prm.money',
  },
  group_revenue_ly_final: {
    dataSource: 'rmData',
    header: 'Grp Rev LYF',
    columnSize: 75,
    tooltip: 'Group Revenue LYF',
    renderer: 'prm.money',
  },
  group_revenue_ly_final_n: {
    dataSource: 'rmData',
    header: 'Grp Rev LYF',
    columnSize: 75,
    tooltip: 'Group Revenue N Years Ago',
    renderer: 'prm.money',
  },
  group_revpar: {
    dataSource: 'rmData',
    header: 'Grp RevPAR',
    tooltip: 'Group RevPAR',
    renderer: 'prm.rate',
  },
  group_sold: {
    dataSource: 'rmData',
    header: 'Grp Sold',
    tooltip: 'Group Sold (P/U from block)',
    renderer: 'prm.group_sold',
  },
  group_sold_ly: {
    dataSource: 'rmData',
    header: 'GSLD LY',
    columnSize: 75,
    tooltip: 'Group Sold Same Time Last Year (P/U from block)',
    renderer: 'prm.group_sold',
  },
  group_sold_ly_n: {
    dataSource: 'rmData',
    header: 'GSLD LY N',
    columnSize: 75,
    tooltip: 'Group Sold N Years ago',
    renderer: 'prm.group_sold',
  },
  group_sold_ly2: {
    dataSource: 'rmData',
    header: 'GSLD LY2',
    columnSize: 75,
    tooltip: 'Group Sold Same Time 2 Years Ago',
    renderer: 'prm.group_sold',
  },
  group_sold_ly2_final: {
    dataSource: 'rmData',
    header: 'GSLD LYF2',
    columnSize: 75,
    tooltip: 'Group Sold Final 2 Years Ago',
    renderer: 'prm.group_sold',
  },
  group_sold_ly_final: {
    dataSource: 'rmData',
    header: 'GSLD LYF',
    columnSize: 75,
    tooltip: 'Group Sold Final Last Year (P/U from block)',
    renderer: 'prm.group_sold',
  },
  group_sold_ly_final_n: {
    dataSource: 'rmData',
    header: 'GSLD LYF N',
    columnSize: 75,
    tooltip: 'Group Sold Final N Years Ago',
    renderer: 'prm.group_sold',
  },
  last_transient_bkg_dt: {
    dataSource: 'rmData',
    header: 'Tran Bk Date',
    tooltip: 'Last Transient Book Date',
    renderer: 'prm.date',
    columnSize: 100,
  },
  lvl_a_details: {
    dataSource: 'rmData',
    header: 'A',
    tooltip: 'Status/Sold/Remaining',
    renderer: 'prm.base',
    columnSize: 110,
  },
  lvl_a_occ: {
    dataSource: 'rmData',
    header: 'LVA Occ',
    tooltip: 'Level A Occupancy %',
    renderer: 'prm.percent',
  },
  lvl_a_remaining: {
    dataSource: 'rmData',
    header: 'LVA Rem',
    tooltip: 'Level A Remaining',
  },
  lvl_a_sold: {
    dataSource: 'rmData',
    header: 'LVA Sold',
    tooltip: 'Level A Sold',
  },
  lvl_a_status: {
    dataSource: 'rmData',
    header: 'A',
    tooltip: 'Level A Status',
    renderer: 'prm.rate_level_status',
    columnSize: 50,
  },
  lvl_b_details: {
    dataSource: 'rmData',
    header: 'B',
    tooltip: 'Status/Sold/Remaining',
    renderer: 'prm.base',
    columnSize: 110,
  },
  lvl_b_occ: {
    dataSource: 'rmData',
    header: 'LVB Occ',
    tooltip: 'Level B Occupancy %',
    renderer: 'prm.percent',
  },
  lvl_b_remaining: {
    dataSource: 'rmData',
    header: 'LVB Rem',
    tooltip: 'Level B Remaining',
  },
  lvl_b_sold: {
    dataSource: 'rmData',
    header: 'LVB Sold',
    tooltip: 'Level B Sold',
  },
  lvl_b_status: {
    dataSource: 'rmData',
    header: 'B',
    tooltip: 'Level B Status',
    renderer: 'prm.rate_level_status',
    columnSize: 50,
  },
  lvl_c_details: {
    dataSource: 'rmData',
    header: 'C',
    tooltip: 'Status/Sold/Remaining',
    renderer: 'prm.base',
    columnSize: 110,
  },
  lvl_c_occ: {
    dataSource: 'rmData',
    header: 'LVC Occ',
    tooltip: 'Level C Occupancy %',
    renderer: 'prm.percent',
  },
  lvl_c_remaining: {
    dataSource: 'rmData',
    header: 'LVC Rem',
    tooltip: 'Level C Remaining',
  },
  lvl_c_sold: {
    dataSource: 'rmData',
    header: 'LVC Sold',
    tooltip: 'Level C Sold',
  },
  lvl_c_status: {
    dataSource: 'rmData',
    header: 'C',
    tooltip: 'Level C Status',
    renderer: 'prm.rate_level_status',
    columnSize: 50,
  },
  lvl_d_details: {
    dataSource: 'rmData',
    header: 'D',
    tooltip: 'Status/Sold/Remaining',
    renderer: 'prm.base',
    columnSize: 110,
  },
  lvl_d_occ: {
    dataSource: 'rmData',
    header: 'LVD Occ',
    tooltip: 'Level D Occupancy %',
    renderer: 'prm.percent',
  },
  lvl_d_remaining: {
    dataSource: 'rmData',
    header: 'LVD Rem',
    tooltip: 'Level D Remaining',
  },
  lvl_d_sold: {
    dataSource: 'rmData',
    header: 'LVD Sold',
    tooltip: 'Level D Sold',
  },
  lvl_d_status: {
    dataSource: 'rmData',
    header: 'D',
    tooltip: 'Level D Status',
    renderer: 'prm.rate_level_status',
    columnSize: 50,
  },
  lvl_e_details: {
    dataSource: 'rmData',
    header: 'E',
    tooltip: 'Status/Sold/Remaining',
    renderer: 'prm.base',
    columnSize: 110,
  },
  lvl_e_occ: {
    dataSource: 'rmData',
    header: 'LVE Occ',
    tooltip: 'Level E Occupancy %',
    renderer: 'prm.percent',
  },
  lvl_e_remaining: {
    dataSource: 'rmData',
    header: 'LVE Rem',
    tooltip: 'Level E Remaining',
  },
  lvl_e_sold: {
    dataSource: 'rmData',
    header: 'LVE Sold',
    tooltip: 'Level E Sold',
  },
  lvl_e_status: {
    dataSource: 'rmData',
    header: 'E',
    tooltip: 'Level E Status',
    renderer: 'prm.rate_level_status',
    columnSize: 50,
  },
  lvl_f_details: {
    dataSource: 'rmData',
    header: 'F',
    tooltip: 'Status/Sold/Remaining',
    renderer: 'prm.base',
    columnSize: 110,
  },
  lvl_f_occ: {
    dataSource: 'rmData',
    header: 'LVF Occ',
    tooltip: 'Level F Occupancy %',
    renderer: 'prm.percent',
  },
  lvl_f_remaining: {
    dataSource: 'rmData',
    header: 'LVF Rem',
    tooltip: 'Level F Remaining',
  },
  lvl_f_sold: {
    dataSource: 'rmData',
    header: 'LVF Sold',
    tooltip: 'Level F Sold',
  },
  lvl_f_status: {
    dataSource: 'rmData',
    header: 'F',
    tooltip: 'Level F Status',
    renderer: 'prm.rate_level_status',
    columnSize: 50,
  },
  lvl_g_details: {
    dataSource: 'rmData',
    header: 'G',
    tooltip: 'Status/Sold/Remaining',
    renderer: 'prm.base',
    columnSize: 110,
  },
  lvl_g_occ: {
    dataSource: 'rmData',
    header: 'LVG Occ',
    tooltip: 'Level G Occupancy %',
    renderer: 'prm.percent',
  },
  lvl_g_remaining: {
    dataSource: 'rmData',
    header: 'LVG Rem',
    tooltip: 'Level G Remaining',
  },
  lvl_g_sold: {
    dataSource: 'rmData',
    header: 'LVG Sold',
    tooltip: 'Level G Sold',
  },
  lvl_g_status: {
    dataSource: 'rmData',
    header: 'G',
    tooltip: 'Level G Status',
    renderer: 'prm.rate_level_status',
    columnSize: 50,
  },
  lvl_h_details: {
    dataSource: 'rmData',
    header: 'H',
    tooltip: 'Status/Sold/Remaining',
    renderer: 'prm.base',
    columnSize: 110,
  },
  lvl_h_occ: {
    dataSource: 'rmData',
    header: 'LVH Occ',
    tooltip: 'Level H Occupancy %',
    renderer: 'prm.percent',
  },
  lvl_h_remaining: {
    dataSource: 'rmData',
    header: 'LVH Rem',
    tooltip: 'Level H Remaining',
  },
  lvl_h_sold: {
    dataSource: 'rmData',
    header: 'LVH Sold',
    tooltip: 'Level H Sold',
  },
  lvl_h_status: {
    dataSource: 'rmData',
    header: 'H',
    tooltip: 'Level H Status',
    renderer: 'prm.rate_level_status',
    columnSize: 50,
  },
  ly_final_revenue_grp: {
    dataSource: 'rmData',
    header: 'Grp Rev LYF',
    columnSize: 75,
    tooltip: 'Group Revenue LYF',
    renderer: 'prm.money',
  },
  market_rate: {
    dataSource: 'bestrevData',
    header: 'MRP',
    tooltip: 'MRP',
    renderer: 'prm.rate',
  },
  'note_ly.content': {
    dataSource: 'hotelNotes',
    header: 'Notes LY',
    tooltip: 'General Notes LY',
    readOnly: false,
    renderer: 'prm.note',
  },
  'note_ty.content': {
    dataSource: 'hotelNotes',
    header: 'Notes',
    tooltip: 'General Notes',
    readOnly: false,
    renderer: 'prm.note',
  },
  occ: {
    dataSource: 'rmData',
    header: 'OCC',
    tooltip: 'Occupancy %',
    renderer: 'prm.percent',
  },
  occ_rc: {
    dataSource: 'rmData',
    header: 'OCC',
    tooltip: 'Occupancy % including Group Blocked',
    renderer: 'prm.percent',
  },
  occ_ly: {
    dataSource: 'rmData',
    header: 'OCC LY',
    tooltip: 'Occupancy % Same Time Last Year',
    renderer: 'prm.percent',
  },
  occ_ly_final: {
    dataSource: 'rmData',
    header: 'OCC LYF',
    tooltip: 'Occupancy % LYF',
    renderer: 'prm.percent',
  },
  ooo: { dataSource: 'rmData', header: 'OOO', tooltip: 'Out of Order' },
  ooo_ly: {
    dataSource: 'rmData',
    header: 'OOO LY',
    tooltip: 'Out of Order Same Time Last Year',
  },
  ooo_ly_final: {
    dataSource: 'rmData',
    header: 'OOO LYF',
    tooltip: 'Out of Order LYF',
  },
  pace_var: {
    dataSource: 'rmData',
    header: 'Pace Var',
    tooltip: 'Pace Variance',
  },
  policy_cd: {
    dataSource: 'rmData',
    header: 'GTD CXL',
    tooltip: 'Guarantee and Cancel Policy',
    columnSize: 90,
    renderer: 'prm.text',
  },
  price_index: {
    dataSource: 'rmData',
    header: 'Price Index',
    tooltip: 'Competitor Price Index',
  },
  pu_14day: {
    dataSource: 'rmData',
    header: 'P/U 14D',
    tooltip: 'Sold Pickup 14 Day',
  },
  pu_1day: {
    dataSource: 'rmData',
    header: 'P/U 1D',
    tooltip: 'Sold Pickup 1 Day',
  },
  pu_1day_ly: {
    dataSource: 'rmData',
    header: 'P/U 1D LY',
    tooltip: 'LY Sold Pickup 1 Day',
  },
  pu_2day: {
    dataSource: 'rmData',
    header: 'P/U 2D',
    tooltip: 'Sold Pickup 2 Day',
  },
  pu_30day: {
    dataSource: 'rmData',
    header: 'P/U 30D',
    tooltip: 'Sold Pickup 30 Day',
  },
  pu_3day: {
    dataSource: 'rmData',
    header: 'P/U 3D',
    tooltip: 'Sold Pickup 3 Day',
  },
  pu_3day_ly: {
    dataSource: 'rmData',
    header: 'P/U 3D LY',
    tooltip: 'LY Sold Pickup 3 Day',
  },
  pu_4day: {
    dataSource: 'rmData',
    header: 'P/U 4D',
    tooltip: 'Sold Pickup 4 Day',
  },
  pu_5day: {
    dataSource: 'rmData',
    header: 'P/U 5D',
    tooltip: 'Sold Pickup 5 Day',
  },
  pu_6day: {
    dataSource: 'rmData',
    header: 'P/U 6D',
    tooltip: 'Sold Pickup 6 Day',
  },
  pu_7day: {
    dataSource: 'rmData',
    header: 'P/U 7D',
    tooltip: 'Sold Pickup 7 Day',
  },
  pu_7day_ly: {
    dataSource: 'rmData',
    header: 'P/U 7D LY',
    tooltip: 'LY Sold Pickup 7 Day',
  },
  pu_dayofarr_ly: {
    dataSource: 'rmData',
    header: 'P/U DOA LY',
    tooltip: 'LY Sold Pickup Day of Arrival',
  },
  pu_dayofarr: {
    dataSource: 'rmData',
    header: 'P/U DOA',
    tooltip: 'Sold Pickup Day of Arrival',
  },
  pu_dayofarrival: {
    dataSource: 'rmData',
    header: 'P/U DOA',
    tooltip: 'Sold Pickup Day of Arrival',
  },
  rate: {
    dataSource: 'rmData',
    header: 'Rate',
    tooltip: 'Rate',
    renderer: 'prm.rate',
  },
  rate_change_1_day: {
    dataSource: 'rate_shop',
    header: 'Rate Δ 1 Day',
    tooltip: 'Rate Δ 1 Day',
  },
  rate_change_3_day: {
    dataSource: 'rate_shop',
    header: 'Rate Δ 3 Day',
    tooltip: 'Rate Δ 3 Day',
  },
  rate_change_7_day: {
    dataSource: 'rate_shop',
    header: 'Rate Δ 7 Day',
    tooltip: 'Rate Δ 7 Day',
  },
  recommended_rate: {
    dataSource: 'bestrevData',
    header: 'BR Rec',
    tooltip: 'BestREV Recommended Rate',
    renderer: 'prm.rate',
  },
  remaining: {
    dataSource: 'rmData',
    header: 'Rem',
    tooltip: 'Rooms Left to Sell (Capacity - Total Sold - OOO)',
  },
  remaining_ly: {
    dataSource: 'rmData',
    header: 'Rem LY',
    tooltip: 'LY Rooms Left to Sell (Capacity - Total Sold - OOO)',
  },
  remaining_ly_final: {
    dataSource: 'rmData',
    header: 'Rem LYF',
    tooltip: 'LYF Rooms Left to Sell (Capacity - Total Sold - OOO)',
  },
  resort_id: { dataSource: 'rmData', header: 'Code', tooltip: 'Brand Code' },
  revenue: {
    dataSource: 'rmData',
    header: 'Rev',
    columnSize: 75,
    tooltip: 'Revenue',
    renderer: 'prm.money',
  },
  revenue_compare: {
    dataSource: 'rmData',
    header: 'Rev (C)',
    columnSize: 75,
    tooltip: 'Revenue from Comparison Year',
    renderer: 'prm.money',
  },
  revenue_variance: {
    dataSource: 'rmData',
    header: 'Rev (V)',
    columnSize: 75,
    tooltip: 'Variance Revenue to Comparison Year',
    renderer: 'prm.money',
  },
  revenue_ly: {
    dataSource: 'rmData',
    header: 'Rev LY',
    columnSize: 75,
    tooltip: 'LY Revenue',
    renderer: 'prm.money',
  },
  revenue_ly2: {
    dataSource: 'rmData',
    header: 'REV LY2',
    columnSize: 75,
    tooltip: 'LY Revenue 2 Years Ago',
    renderer: 'prm.money',
  },
  revenue_ly2_final: {
    dataSource: 'rmData',
    header: 'REV LYF2',
    columnSize: 75,
    tooltip: 'LYF Revenue 2 Years Ago',
    renderer: 'prm.money',
  },
  revenue_ly_final: {
    dataSource: 'rmData',
    header: 'Rev LYF',
    columnSize: 75,
    tooltip: 'LYF Revenue',
    renderer: 'prm.money',
  },
  revpar: {
    dataSource: 'rmData',
    header: 'RevPAR',
    tooltip: 'RevPAR',
    renderer: 'prm.rate',
  },
  revpar_compare: {
    dataSource: 'rmData',
    header: 'RP (C)',
    tooltip: 'RevPAR from Comparison Year',
    renderer: 'prm.rate',
  },
  revpar_variance: {
    dataSource: 'rmData',
    header: 'RP (V)',
    tooltip: 'Variance RevPAR to Comparison Year',
    renderer: 'prm.rate',
  },
  revpar_ly: {
    dataSource: 'rmData',
    header: 'RevPAR LY',
    tooltip: 'RevPAR LY',
    renderer: 'prm.rate',
  },
  revpar_ly_final: {
    dataSource: 'rmData',
    header: 'RevPAR LYF',
    tooltip: 'RevPAR LYF',
    renderer: 'prm.rate',
  },
  'rm_recs.created_by': {
    dataSource: 'hotelRmRecs',
    header: 'Rev Mgr',
    tooltip: 'Revenue Manager',
    renderer: 'prm.base',
    readOnly: true,
    columnSize: 100,
  },
  'rm_recs.notes': {
    dataSource: 'hotelRmRecs',
    header: 'Other Recs',
    tooltip: 'Other Recommendations',
    readOnly: false,
    renderer: 'prm.rm_rec_notes',
    columnSize: 90,
  },
  'rm_recs.rate': {
    dataSource: 'hotelRmRecs',
    header: 'Rec Rate',
    tooltip: 'RM Recommended Rate',
    renderer: 'prm.rate',
    readOnly: false,
    columnSize: 90,
  },
  'rm_recs.status': {
    dataSource: 'hotelRmRecs',
    header: 'Status',
    tooltip: 'Status of Recommendation',
    renderer: 'prm.rm_rec_status',
    columnSize: 40,
  },
  'rm_recs.stay_date': {
    dataSource: 'hotelRmRecs',
    header: 'Date',
    tooltip: 'Stay Date',
    renderer: 'prm.date',
    readOnly: true,
    columnSize: 80,
  },
  rooms_committed: {
    dataSource: 'rmData',
    header: 'Comm',
    tooltip: 'Rooms Committed (Total Sold + Group Block Remaining)',
  },
  rt_room_allocation: {
    dataSource: 'roomData',
    header: 'RM Alloc',
    tooltip: 'Room Allocation',
  },
  rt_flex_rate: {
    dataSource: 'roomData',
    header: 'Flex Rate',
    tooltip: 'Flex Rate',
  },
  rt_occ: {
    dataSource: 'roomData',
    header: 'Occ',
    tooltip: 'Occupancy %',
  },
  rt_remaining: {
    dataSource: 'roomData',
    header: 'Rem',
    tooltip: 'Remaining Rooms',
  },
  rt_sold: {
    dataSource: 'roomData',
    header: 'Sold',
    tooltip: 'Sold Rooms',
  },
  rt_grp_rooms_committed: {
    dataSource: 'liveData',
    header: 'GR (LV)',
    tooltip: 'Live Group Rooms Committed',
  },
  rt_rooms_committed: {
    dataSource: 'liveData',
    header: 'RM (LV)',
    tooltip: 'Live Rooms Committed',
  },
  rt_transient_rooms_committed: {
    dataSource: 'liveData',
    header: 'TR (LV)',
    tooltip: 'Live Transient Rooms Committed',
  },
  snapshot_date: {
    dataSource: 'rmData',
    header: 'Snap',
    tooltip: 'Snapshot Date',
    columnSize: 80,
    renderer: 'prm.date',
  },
  snapshot_date_ly: {
    dataSource: 'rmData',
    header: 'Snap LY',
    tooltip: 'LY Snapshot Date',
    columnSize: 80,
    renderer: 'prm.date',
  },
  snapshot_date_ly2: {
    dataSource: 'rmData',
    header: 'Snapshot Date 2Y',
    tooltip: 'Snapshot Date 2 Years Ago',
    columnSize: 80,
    renderer: 'prm.date',
  },
  sold: {
    dataSource: 'rmData',
    header: 'Sold',
    tooltip: 'Total Sold',
    columnSize: 60,
  },
  sold_compare: {
    dataSource: 'rmData',
    header: 'Sold (C)',
    tooltip: 'Total Sold from Comparison Year',
  },
  sold_variance: {
    dataSource: 'rmData',
    header: 'Sold (V)',
    tooltip: 'Variance Sold to Comparison Year',
  },
  sold_ly: {
    dataSource: 'rmData',
    header: 'Sold LY',
    tooltip: 'Sold Same Time Last Year',
  },
  sold_ly2: {
    dataSource: 'rmData',
    header: 'Sold LY2',
    tooltip: 'Sold LY 2 Years Ago',
  },
  sold_ly2_final: {
    dataSource: 'rmData',
    header: 'Sold LYF2',
    tooltip: 'Sold LY Final 2 Years Ago',
  },
  sold_ly_final: {
    dataSource: 'rmData',
    header: 'Sold LYF',
    tooltip: 'LYF Sold',
  },
  stay_date: {
    dataSource: 'rmData',
    header: 'Date',
    tooltip: 'Stay Date',
    columnSize: 80,
    renderer: 'prm.date',
  },
  stay_date_ly: {
    dataSource: 'rmData',
    header: 'Stay Date LY',
    tooltip: 'Stay Date LY',
    columnSize: 80,
    renderer: 'prm.date',
  },
  stay_date_ly2: {
    dataSource: 'rmData',
    header: 'Stay Date 2Y',
    tooltip: 'Stay Date 2 Years Ago',
    columnSize: 80,
    renderer: 'prm.date',
  },
  suboptimal_rate: {
    dataSource: 'rmData',
    header: 'Sub Rate',
    tooltip: 'Suboptimal Rate',
    renderer: 'prm.rate',
  },
  total_capacity: {
    dataSource: 'rmData',
    header: 'Cap',
    tooltip: 'Total Capacity Includes OOO',
  },
  total_capacity_ly: {
    dataSource: 'rmData',
    header: 'Cap LY',
    tooltip: 'Total Capacity Includes OOO Same Time Last Year',
  },
  total_capacity_ly_final: {
    dataSource: 'rmData',
    header: 'Cap LYF',
    tooltip: 'Total Capacity Includes OOO Final Last Year',
  },
  total_potential_group: {
    dataSource: 'rmData',
    header: 'Grp Blk',
    tooltip: 'Total Group Rooms Blocked (P/U and Block Left)',
  },
  total_sold: {
    dataSource: 'rmData',
    header: 'Tot Sold',
    tooltip: 'Total Sold (Transient Sold + Group Sold)',
  },
  transient_adr: {
    dataSource: 'rmData',
    header: 'Trn ADR',
    tooltip: 'Transient ADR',
    renderer: 'prm.rate',
  },
  transient_delta_intraday: {
    dataSource: 'liveData',
    header: 'Δ LV Trn',
    tooltip: 'Δ Live Transient Delta Intraday',
  },
  transient_l7_bkg_days: {
    dataSource: 'overview',
    header: 'TrnBk L7',
    tooltip: 'Transient Booked L7',
  },
  transient_revenue: {
    dataSource: 'overview',
    header: 'Trn Rev',
    tooltip: 'Transient Revenue',
    renderer: 'prm.money',
    columnSize: 75,
  },
  transient_sold: {
    dataSource: 'overview',
    header: 'Trn Sold',
    tooltip: 'Transient Sold',
  },
  transient_sold_ly: {
    dataSource: 'overview',
    header: 'Trn Sold LY',
    columnSize: 75,
    tooltip: 'LY Transient Sold',
  },
  transient_sold_ly_n: {
    dataSource: 'overview',
    header: 'Trn Sold LY N',
    columnSize: 75,
    tooltip: 'LY Transient Sold',
  },
  transient_sold_ly_final: {
    dataSource: 'overview',
    header: 'Trn Sold LYF',
    columnSize: 75,
    tooltip: 'LYF Transient Sold',
  },
  transient_sold_ly_final_n: {
    dataSource: 'overview',
    header: 'Trn Sold LYF N',
    columnSize: 75,
    tooltip: 'LYF Transient Sold',
  },
  var_adr_ly: {
    dataSource: 'rmData',
    header: 'ΔA LY',
    tooltip: 'Variance to ADR LY',
    renderer: 'prm.rate',
  },
  var_adr_ly_n: {
    dataSource: 'rmData',
    header: 'ΔA LYn',
    tooltip: 'Variance to ADR LYn',
    renderer: 'prm.rate',
  },
  var_adr_ly2: {
    dataSource: 'rmData',
    header: 'ΔA LY2',
    tooltip: 'Variance ADR LY 2 Years Ago',
    renderer: 'prm.rate',
  },
  var_adr_ly2_final: {
    dataSource: 'rmData',
    header: 'ΔA LYF2',
    tooltip: 'Variance ADR LY Final 2 Years Ago',
    renderer: 'prm.rate',
  },
  var_adr_ly_final: {
    dataSource: 'rmData',
    header: 'ΔA LYF',
    tooltip: 'Variance to ADR LYF',
    renderer: 'prm.rate',
  },
  var_adr_ly_final_n: {
    dataSource: 'rmData',
    header: 'ΔA LYFn',
    tooltip: 'Variance to ADR LYF',
    renderer: 'prm.rate',
  },
  var_group_sold_ly2: {
    dataSource: 'rmData',
    header: 'ΔGR LY2',
    tooltip: 'Variance Group Sold LY 2 Years Ago',
  },
  var_group_sold_ly2_final: {
    dataSource: 'rmData',
    header: 'ΔGR LYF2',
    tooltip: 'Variance Group Sold LY Final 2 Years Ago',
  },
  var_occ_ly: {
    dataSource: 'rmData',
    header: 'ΔOCC LY',
    tooltip: 'Variance to Occ LY',
    renderer: 'prm.percent',
  },
  var_occ_ly_final: {
    dataSource: 'rmData',
    header: 'ΔOCC LYF',
    tooltip: 'Variance to Occ LYF',
    renderer: 'prm.percent',
  },
  var_revenue_ly: {
    dataSource: 'rmData',
    header: 'ΔREV LY',
    columnSize: 75,
    tooltip: 'Variance to Revenue LY',
    renderer: 'prm.money',
  },
  var_revenue_ly_n: {
    dataSource: 'rmData',
    header: 'ΔREV LYn',
    columnSize: 75,
    tooltip: 'Variance to Revenue LYn',
    renderer: 'prm.money',
  },
  var_revenue_ly2: {
    dataSource: 'rmData',
    header: 'ΔREV LY2',
    columnSize: 75,
    tooltip: 'Variance LY Revenue 2 Years Ago',
    renderer: 'prm.money',
  },
  var_revenue_ly2_final: {
    dataSource: 'rmData',
    header: 'ΔREV LYF2',
    columnSize: 75,
    tooltip: 'Variance LYF Revenue 2 Years Ago',
    renderer: 'prm.money',
  },
  var_revenue_ly_final: {
    dataSource: 'rmData',
    header: 'ΔREV LYF',
    columnSize: 75,
    tooltip: 'Variance to Revenue LYF',
    renderer: 'prm.money',
  },
  var_revenue_ly_final_n: {
    dataSource: 'rmData',
    header: 'ΔREV LYFn',
    columnSize: 75,
    tooltip: 'Variance to Revenue LYFn',
    renderer: 'prm.money',
  },
  var_revpar_ly: {
    dataSource: 'rmData',
    header: 'RPAR vs LY',
    tooltip: 'Variance to RevPAR LY',
    renderer: 'prm.rate',
  },
  var_revpar_ly_final: {
    dataSource: 'rmData',
    header: 'RPAR vs LYF',
    tooltip: 'Variance to RevPAR LYF',
    renderer: 'prm.rate',
  },
  var_sold_ly: {
    dataSource: 'rmData',
    header: 'Δ LY',
    tooltip: 'Variance to Sold LY',
  },
  var_sold_ly_n: {
    dataSource: 'rmData',
    header: 'Δ LYn',
    tooltip: 'Variance to Sold LYn',
  },
  var_sold_ly2: {
    dataSource: 'rmData',
    header: 'Δ LY2',
    tooltip: 'Variance Sold LY 2 Years Ago',
  },
  var_sold_ly2_final: {
    dataSource: 'rmData',
    header: 'Δ LYF2',
    tooltip: 'Variance Sold LY Final 2 Years Ago',
  },
  var_sold_ly_final: {
    dataSource: 'rmData',
    header: 'Δ LYF',
    tooltip: 'Variance to Sold LYF',
  },
  var_sold_ly_final_n: {
    dataSource: 'rmData',
    header: 'Δ LYFn',
    tooltip: 'Variance to Sold LYFn',
  },
  var_transient_sold_ly: {
    dataSource: 'rmData',
    header: 'ΔTR LY',
    tooltip: 'Variance to Transient Sold LY',
  },
  var_transient_sold_ly_final: {
    dataSource: 'rmData',
    header: 'ΔTR LYF',
    tooltip: 'Variance to Transient Sold LYF',
  },
  'weather.temperature': {
    dataSource: 'hotelWeather',
    header: '°F',
    tooltip: 'Daily Temperature °F',
    renderer: 'prm.temperature',
  },
};
