import 'react-virtualized/styles.css';

import {
  AutoSizer,
  GridCellProps,
  MultiGrid,
  ScrollParams,
} from 'react-virtualized';
import {
  CellObject,
  HeaderObject,
} from '../features/overview-table/hooks/use-table';
import _, { Many } from 'lodash';
import { defaultWidth, rowHeight, rowScroll } from './data_grid/dataGridHelper';
import { useEffect, useMemo, useRef, useState } from 'react';

import Cell from '../dashboards/tables/overview/Cell';
import { DataGridProps } from '../types/DataGrid';
import Header from '../dashboards/tables/overview/Header';
import ReactTooltip from 'react-tooltip';
import dayjs from 'dayjs';

const HEADER_HEIGHT_OFFSET = 30;

function DataGrid({
  brandCode,

  colLockKey,
  headers,
  monthOffset,
  rows,
  snapshotDate,
  sortDir,
  sortKey,
  targetDate,
  startDate,
}: DataGridProps) {
  const gridRef = useRef<MultiGrid>(null);
  const headerRef = useRef<HeaderObject[]>(headers);
  const [scrollPosition, setScrollPosition] = useState({
    scrollTop: 0,
    scrollLeft: 0,
  });

  const lockedColumnIndex = useMemo(() => {
    if (headers?.length > 0 && colLockKey) {
      const index = headers.findIndex(
        (header) => header.dataKey === colLockKey
      );
      return index >= 0 ? index : 0;
    }
    return 0;
  }, [colLockKey, headers]);

  const sortedRows = useMemo(() => {
    if (rows && sortKey && sortDir) {
      return _.orderBy(
        rows,
        function (a) {
          const findTarget = _.find(a, { dataKey: sortKey }) as CellObject;
          if (findTarget && findTarget.value) {
            return findTarget?.value;
          } else {
            return 0;
          }
        },
        sortDir as Many<boolean | 'desc' | 'asc'> | undefined
      );
    }
    return rows;
  }, [rows, sortKey, sortDir]);

  useEffect(() => {
    if (gridRef.current && snapshotDate) {
      let dateRow = 0;
      if (monthOffset === 0) {
        // Use the snapshot date as the first date
        dateRow = findDateIdx(snapshotDate);
      } else {
        dateRow = findDateIdx(
          dayjs()
            .startOf('year')
            .add(monthOffset, 'month')
            .startOf('month')
            .format('YYYY-MM-DD')
        );
      }
      setScrollPosition({ scrollLeft: 0, scrollTop: rowScroll * dateRow });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandCode, gridRef, monthOffset, snapshotDate]);

  useEffect(() => {
    if (gridRef.current && targetDate) {
      const dateRow = findDateIdx(targetDate.value) || 0;
      setScrollPosition({ scrollLeft: 0, scrollTop: rowScroll * dateRow });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetDate]);

  useEffect(() => {
    if (gridRef.current && scrollPosition.scrollTop !== 0) {
      setScrollPosition({ scrollLeft: 0, scrollTop: 0 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate]);

  useEffect(() => {
    if (gridRef.current && !_.isEqual(headers, headerRef.current)) {
      console.count('forceUpdateGrids');
      headerRef.current = headers;
      gridRef.current.recomputeGridSize();
    }
  }, [headers]);

  const findDateIdx = (targetDate: string): number => {
    return _.findIndex(sortedRows, (row) => {
      return typeof row[0] === 'object'
        ? row[0]?.value === targetDate
        : row[0] === targetDate;
    });
  };

  const handleOnScroll = ({ scrollTop, scrollLeft }: ScrollParams) => {
    setScrollPosition({ scrollLeft, scrollTop });
  };

  const rebuildTooltip = _.debounce(() => ReactTooltip.rebuild(), 200, {
    leading: false,
    trailing: true,
  });

  const cellRenderer = ({
    columnIndex,
    key,
    rowIndex,
    style,
  }: GridCellProps) => {
    const isLocked = columnIndex <= lockedColumnIndex;

    if (rowIndex === 0) {
      const headerStyle = {
        ...style,
        height: rowHeight + HEADER_HEIGHT_OFFSET,
      };
      return (
        <Header
          key={key}
          columnCount={headers.length}
          columnIndex={columnIndex}
          header={headers[columnIndex]}
          isLocked={isLocked}
          style={headerStyle}
        />
      );
    } else {
      const cell = sortedRows[rowIndex - 1][columnIndex];
      const cellStyle = {
        ...style,
      };
      return (
        <Cell
          key={key}
          cell={cell}
          columnIndex={columnIndex}
          isLocked={isLocked}
          rowIndex={rowIndex}
          style={cellStyle}
        />
      );
    }
  };

  return (
    <div className='h-full text-center text-xs'>
      <AutoSizer disableHeight>
        {({ width }) => (
          <MultiGrid
            cellRenderer={cellRenderer}
            columnCount={headers.length}
            columnWidth={({ index }) =>
              headers[index].columnSize || defaultWidth
            }
            estimatedColumnSize={defaultWidth}
            fixedColumnCount={lockedColumnIndex + 1}
            fixedRowCount={1}
            height={800}
            onSectionRendered={rebuildTooltip}
            onScroll={handleOnScroll}
            ref={gridRef}
            rowCount={rows.length}
            rowHeight={({ index }) =>
              index === 0 ? rowHeight + HEADER_HEIGHT_OFFSET : rowHeight
            }
            scrollTop={scrollPosition.scrollTop}
            scrollLeft={scrollPosition.scrollLeft}
            width={width}
          />
        )}
      </AutoSizer>
    </div>
  );
}

export default DataGrid;
